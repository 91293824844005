import React from 'react';
import { Typography } from 'antd';

import { useSessionPlayer } from 'hooks/traineeActions';
import { Loading } from 'components';
import { BUYER, SELLER, UNASSIGNED } from 'stateConstants';
import BuyerReview from './BuyerReview';
import SellerReview from './SellerReview';
import { Player, Offer } from 'types';

const { Paragraph } = Typography;

const ReviewStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
  proposal: Offer;
}> = ({ player, ownPerspective, proposal }) => {
  // const { data: player, isLoading: isPlayerLoading } = useSessionPlayer();

  if (!player || player.type === UNASSIGNED) {
    return <Paragraph>Player cannot be unassigned</Paragraph>;
  }

  return (
    <>
      {player.type === BUYER && (
        <BuyerReview offer={proposal} ownPerspective={ownPerspective} />
      )}
      {player.type === SELLER && <SellerReview />}
    </>
  );
};

export default ReviewStep;
