import { Typography, Select, Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Member,
  NegotiationChoicesStepType,
  ChoicesNegotiation,
  NegotiationChoicesAttempt,
  Player,
} from 'types';
import styled from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import {
  useRetrieveNegotiationChoices,
  useSubmitBuyerAttempt,
} from './actions';
import { useCurrentStep, useSessionId } from 'hooks';
import { useSessionMember, useSubmitCurrentStep } from 'hooks/traineeActions';
import { Loading } from 'components';

type Props = {
  attempt: NegotiationChoicesAttempt;
  ownPerspective: boolean;
  player: Player;
};

export const BuyerView: React.FC<Props> = ({
  player,
  attempt,
  ownPerspective,
}) => {
  const { data: member } = useSessionMember();
  const [selectedChoices1, setSelectedChoices1] = useState<ChoicesNegotiation>(
    choices[0]
  );
  const [selectedChoices2, setSelectedChoices2] = useState<ChoicesNegotiation>(
    choices[1]
  );
  const [selectedChoices3, setSelectedChoices3] = useState<ChoicesNegotiation>(
    choices[2]
  );
  const { mutate: submitAttempt } = useSubmitCurrentStep();

  useEffect(() => {
    if (attempt && attempt.choices) {
      const newChoices = attempt.choices.map((choice) => {
        const foundChoice = choices.find((c) => c.choice === choice.choice);
        return foundChoice || { choice: -1, name: 'UNKNOWN ERROR' };
      });

      if (newChoices[0]) setSelectedChoices1(newChoices[0]);
      if (newChoices[1]) setSelectedChoices2(newChoices[1]);
      if (newChoices[2]) setSelectedChoices3(newChoices[2]);
    }
  }, [attempt]); // Dependency array includes `attempt` to run the effect when it changes

  const session_id = useSessionId();
  const {
    data: step,
    isLoading: isStepLoading,
  } = useCurrentStep<NegotiationChoicesStepType>();

  // if(attempt)
  //     return (
  //         <Typography>
  //             Your offer was submitted and the Seller will now take some time to review all available offers
  //         </Typography>
  //     )


  if (isStepLoading || !step || (ownPerspective && member === undefined))
    return <Loading />;

  return (
    <Wrapper>
      <Typography.Title level={4}>
        The Seller has just informed their advisor they would need your team to
        show flexibility on at least three of the various components of your
        offer. On which three are you more willing to negotiate ?
      </Typography.Title>
      <Divider />
      <Typography>Make your selection below.</Typography>
      <Selects>
        <Select
          disabled={!!attempt}
          value={selectedChoices1.name}
          placeholder={selectedChoices1.name}
          onChange={(e) => {
            const choice = choices.find((ch) => ch.choice === parseInt(e));
            setSelectedChoices1(
              choice || { choice: -1, name: 'UNKNOWN ERROR' }
            );
          }}
        >
          {choices
            .filter(
              (c) =>
                c.choice !== selectedChoices1.choice &&
                c.choice !== selectedChoices2.choice &&
                c.choice !== selectedChoices3.choice
            )
            .map((c) => {
              return (
                <Select.Option key={c.choice} value={c.choice}>
                  {c.name}
                </Select.Option>
              );
            })}
        </Select>
        <Select
          disabled={!!attempt}
          value={selectedChoices2.name}
          placeholder={selectedChoices2.name}
          onChange={(e) => {
            const choice = choices.find((ch) => ch.choice === parseInt(e));
            setSelectedChoices2(
              choice || { choice: -1, name: 'UNKNOWN ERROR' }
            );
          }}
        >
          {choices
            .filter(
              (c) =>
                c.choice !== selectedChoices2.choice &&
                c.choice !== selectedChoices1.choice &&
                c.choice !== selectedChoices3.choice
            )
            .map((c) => {
              return (
                <Select.Option key={c.choice} value={c.choice}>
                  {c.name}
                </Select.Option>
              );
            })}
        </Select>
        <Select
          disabled={!!attempt}
          value={selectedChoices3.name}
          placeholder={selectedChoices3.name}
          onChange={(e) => {
            const choice = choices.find((ch) => ch.choice === parseInt(e));
            setSelectedChoices3(
              choice || { choice: -1, name: 'UNKNOWN ERROR' }
            );
          }}
        >
          {choices
            .filter(
              (c) =>
                c.choice !== selectedChoices3.choice &&
                c.choice !== selectedChoices1.choice &&
                c.choice !== selectedChoices2.choice
            )
            .map((c) => {
              return (
                <Select.Option key={c.choice} value={c.choice}>
                  {c.name}
                </Select.Option>
              );
            })}
        </Select>
      </Selects>

      {ownPerspective && (
        <Footer>
          <Button
            type="primary"
            onClick={() => {
              const data = {
                attempt: {
                  choices: [
                    { choice: selectedChoices1.choice },
                    { choice: selectedChoices2.choice },
                    { choice: selectedChoices3.choice },
                  ],
                  step: step?.id,
                  member: member!.id,
                  player: member!.player,
                },
                session_id,
              };
              submitAttempt(data);
            }}
            disabled={!!attempt}
          >
            <ArrowRightOutlined />
            Submit
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // I fixed a typo, hope the interface isn't fucked
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Footer = styled.div`
  display: flex;
  justify-content: right;
`;

const Selects = styled.div`
  margin-top: 1rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 300px;
`;

const choices = [
  { choice: 0, name: 'Cash amount' },
  { choice: 1, name: 'Stock exchange rate' },
  { choice: 2, name: 'Cash in lieu' },
  { choice: 3, name: 'Deferred cash' },
  { choice: 4, name: 'Pension liabilities' },
  { choice: 5, name: 'Target debt' },
  { choice: 6, name: 'Breakup fee' },
];
