import type { Route } from 'types';

import Simulation from './Simulation';

const routes: Route[] = [
  {
    name: 'Simulation',
    path: '/simulation',
    component: Simulation,
  },
];

export default routes;
