import React from 'react';

import { useAuthState } from 'contexts';
import { Loading } from 'components';
import { useQuizAnswers } from './actions';
import QuestionsForm from './QuestionsForm';
import QuestionsDisplay from './QuestionsDisplay';
import { Divider, Typography } from 'antd';
import { UNASSIGNED } from 'stateConstants';
import { useSessionPlayer } from 'hooks/traineeActions';

const instructions =
  'Welcome to the M&A Simulation. Please review the quiz questions below and answer as well as you can, then make sure to click on the blue <<Submit>> button. The top scoring team will be assigned the Seller role.';

const QuizStep: React.FC = () => {
  const { person } = useAuthState();
  const {
    data: quizAnswers,
    isLoading: isQuizAnswersLoading,
  } = useQuizAnswers();
  const { data: player, isLoading: isPlayerLoading } = useSessionPlayer();

  if (!person?.id || isQuizAnswersLoading) {
    return <Loading />;
  }

  return (
    <>
      <Typography.Title level={5}>
        {player?.type === UNASSIGNED ? instructions : ''}
      </Typography.Title>
      <Divider />
      {!quizAnswers?.answers ? <QuestionsForm /> : <QuestionsDisplay />}
    </>
  );
};

export default QuizStep;
