import React, { useState } from 'react';
import { Typography } from 'antd';
import Counter from 'modules/Counter';

const ReadingsInstructionsStep: React.FC = () => {
  return (
    <>
      <Counter totalTime={120} />
      <Typography.Title level={4}>
        Please inform the Participants that documents in the Readings Tab will be readily accessible throughout
        the Simulation and they can return to it whenever needed.
      </Typography.Title>
      <Typography.Title level={2}>
        PLEASE DO NOT REFRESH THE PAGE.
      </Typography.Title>
    </>
  );
};

export default ReadingsInstructionsStep;
