import { useMutation, useQueryClient } from 'react-query';

import { createPostRequest, invalidateIfIncludes } from 'services';
import { useSessionId } from 'hooks';
import { createDynamicPostRequest, createPostDetailRequest } from 'services/createRequests';

// const startSession = createPostRequest<{ id: number }, unknown>(
//   ({ id }) => `sessions/${id}/start/`,
//   'post'
// );
// export const useStartSession = () => {
//   const id = useSessionId();
//   const queryClient = useQueryClient();
//   return useMutation(() => startSession({ id }), {
//     onSettled: () =>
//       queryClient.invalidateQueries({
//         predicate: invalidateIfIncludes(['sessions']),
//       }),
//   });
// };

const startSession = createPostDetailRequest<number, unknown>(
  'sessions',
  'start'
);
export const useStartSession = () => {
  // const id = useSessionId();
  const queryClient = useQueryClient();
  return useMutation(startSession, {
    onSettled: () =>
      queryClient.invalidateQueries({
        predicate: invalidateIfIncludes(['sessions']),
      }),
  });
};

export const useResumeSession = () => {
  const queryClient = useQueryClient();
  const sessionId = useSessionId();

  const resumeSession = createDynamicPostRequest<{ id: number }, unknown>(
    (session_id) => `sessions/${session_id}/resume/`,
    'post'
  );

  return useMutation(() => resumeSession({ id: sessionId }, sessionId), {
    onSettled: () => queryClient.invalidateQueries({
      predicate: invalidateIfIncludes(['sessions']),
    }),
  });
};

export const usePauseSession = () => {
  const queryClient = useQueryClient();
  const sessionId = useSessionId();

  const pauseSession = createDynamicPostRequest<{ id: number }, unknown>(
    (session_id) => `sessions/${session_id}/pause/`,
    'post'
  );

  return useMutation(() => pauseSession({ id: sessionId }, sessionId), {
    onSettled: () => queryClient.invalidateQueries({
      predicate: invalidateIfIncludes(['sessions']),
    }),
  });
};


export const useFinishSession = () => {
  const queryClient = useQueryClient();
  const sessionId = useSessionId();

  const finishSession = createDynamicPostRequest<{ id: number }, unknown>(
    (session_id) => `sessions/${session_id}/finish/`,
    'post'
  );

  return useMutation(() => finishSession({ id: sessionId }, sessionId), {
    onSettled: () => queryClient.invalidateQueries({
      predicate: invalidateIfIncludes(['sessions']),
    }),
  });
};

export const useAdvanceSession = () => {
  const queryClient = useQueryClient();
  const sessionId = useSessionId();

  const advanceSession = createDynamicPostRequest<{ id: number }, unknown>(
    (session_id) => `sessions/${session_id}/advance/`,
    'post'
  );

  return useMutation(() => advanceSession({ id: sessionId }, sessionId), {
    onSettled: () => queryClient.invalidateQueries({
      predicate: invalidateIfIncludes(['sessions']),
    }),
  });
};

export const usePreviousStep = () => {
  const queryClient = useQueryClient();
  const sessionId = useSessionId();

  const previousStep = createDynamicPostRequest<{ id: number }, unknown>(
    (session_id) => `sessions/${session_id}/previous_step/`,
    'post'
  );

  return useMutation(() => previousStep({ id: sessionId }, sessionId), {
    onSettled: () => queryClient.invalidateQueries({
      predicate: invalidateIfIncludes(['sessions']),
    }),
  });
};
