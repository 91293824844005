import React from 'react';

import { Button, Popconfirm } from 'antd';
import type { ButtonProps } from 'antd';

type Props = Omit<ButtonProps, 'onClick' | 'href'> & {
  name: string;
  warning: React.ReactNode;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  disabled?: boolean;
};

const ConfirmButton: React.FC<Props> = ({
  name,
  warning,
  onConfirm,
  okText = 'Yes',
  cancelText = 'No',
  children,
  disabled,
  ...props
}) => {
  return (
    <Popconfirm
      title={warning}
      onConfirm={onConfirm}
      okText={okText}
      cancelText={cancelText}
      disabled={disabled}
    >
      <Button disabled={disabled} {...props}>
        {name}
      </Button>
    </Popconfirm>
  );
};

export default ConfirmButton;
