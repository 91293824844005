import { useMutation, useQueryClient } from 'react-query';
import { createPostRequest } from 'services';
import { Synergy, SynergySubmitPayload } from 'types';
import { useAuthQuery } from 'hooks';

const submitSynergies = createPostRequest<SynergySubmitPayload, unknown>(
  `synergies/submit/`,
  'post'
);

export const useSubmitSynergies = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, SynergySubmitPayload>(
    (data) => submitSynergies(data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['synergies']);
      },
    }
  );
};

export const useListSynergies = (memberId: number) => {
  return useAuthQuery<Synergy[]>(
    ['synergies'],
    { enabled: !!memberId },
    { member: memberId }
  );
};
