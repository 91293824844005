import React, { useEffect, useState } from 'react';

import { Typography, message } from 'antd';

import { Loading } from 'components';
import { useCurrentStage, useCurrentStep, useSessionId } from 'hooks';
import * as Steps from './Steps';
import { Redirect } from 'react-router-dom';
import { useSessionPlayer } from 'hooks/traineeActions';
import { DCFFProvider } from 'contexts';
import { useRetrieveCurrentStep } from 'hooks/trainerActions';
import {
  Offer,
  Player,
  SellerEliminationOffers,
  Step,
  StepDataMap,
  TemplateDocument,
} from 'types';

const { Paragraph, Title } = Typography;

const CurrentStep: React.FC<{ player: Player; step: Step }> = ({
  player,
  step,
}) => {
  const { data: stage, isLoading: stageIsLoading } = useCurrentStage();
  const {
    data: loggedInPlayer,
    isLoading: isLoggedInPlayerLoading,
  } = useSessionPlayer();
  const {
    data: stepData,
    isLoading: stepDataIsLoading,
    refetch,
  } = useRetrieveCurrentStep(player.id, step.resourcetype as keyof StepDataMap);

  const [hasMessageBeenDisplayed, setHasMessageBeenDisplayed] = useState(false);
  const [ownPerspective, setOwnPerspective] = useState(
    player.id === loggedInPlayer?.id
  );
  useEffect(() => {
    setOwnPerspective(player.id === loggedInPlayer?.id);
  }, [player.id, loggedInPlayer, refetch, stepData]);

  // console.log('Trainee current step:', { stepData }, { player });

  if (!player?.is_in_game) {
    return (
      <div>
        <Title level={4}>
          Unfortunately, your Team has been removed from the Transaction by the
          Seller for having an insufficiently appealing offer.
        </Title>
        <Title level={4}>
          This judgment may be superficial, but it is nonetheless final.
        </Title>
        <Title level={4}>
          All M&A transactions processes that involve multiple bidders have such
          an outcome for all but Buyer per transaction.
        </Title>
        <Title level={4}>
          We thank you for your participation, and we wish you good luck in your
          career!
        </Title>
      </div>
    );
  }

  if (stageIsLoading || !player || stepDataIsLoading || isLoggedInPlayerLoading)
    return <Loading />;

  if (step.type === 1 && !hasMessageBeenDisplayed) {
    message.info('You should be in the Readings tab at this step!');
    setHasMessageBeenDisplayed(true);
  }

  switch (step.resourcetype) {
    case 'QuizStep':
      return <Steps.QuizStep />;
    case 'BilateralDocsStep':
      return (
        <Steps.BilateralDocsStep
          player={player}
          ownPerspective={ownPerspective}
          doc={stepData as TemplateDocument}
        />
      );
    case 'DocAcquisitionStep':
      return (
        <Steps.DocAcquisitionStep
          attempt={stepData}
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    case 'DCFStep':
      return (
        <DCFFProvider player={player}>
          <Steps.DCFStep
            player={player}
            ownPerspective={ownPerspective}
            attempt={stepData}
          />
        </DCFFProvider>
      );
    case 'TradingComparablesStep':
      return (
        <Steps.TradingComparablesStep
          attempt={stepData}
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    case 'TransactionComparablesStep':
      return (
        <Steps.TransactionComparablesStep
          player={player}
          ownPerspective={ownPerspective}
          attempt={stepData}
        />
      );
    case 'ProposalStep':
      return (
        <Steps.ProposalStep
          proposal={stepData}
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    case 'SynergiesStep':
      return (
        <Steps.SynergiesStep
          synergies={stepData}
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    case 'ReviewStep':
      return (
        <Steps.ReviewStep
          proposal={stepData}
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    case 'NegotiationChoicesStep':
      return (
        <Steps.NegotiationChoicesStep
          player={player}
          ownPerspective={ownPerspective}
          data={stepData}
        />
      );
    case 'ReadingsInstructionsStep':
      return (
        <Steps.ReadingsInstructionsStep
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    case 'EliminationStep':
      return (
        <Steps.EliminationStep
          player={player}
          ownPerspective={ownPerspective}
          data={stepData as Offer | SellerEliminationOffers}
          offersAreLoading={stepDataIsLoading}
        />
      );
    case 'FinalOfferStep':
      return (
        <Steps.FinalOfferStep
          data={stepData}
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    case 'ReadingsBilateralDocsStep':
      return (
        <Steps.ReadingValuationStep
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    case 'ReadingsPreparationStep':
      return (
        <Steps.ReadingValuationStep
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    case 'PreEliminationStep':
      return (
        <Steps.PreEliminationStep
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    case 'ReadingsValuationInstructionsStep':
      return (
        <Steps.ReadingsValuationInstructionsStep
          player={player}
          ownPerspective={ownPerspective}
        />
      );
    default:
      return <Paragraph>Unknown step type</Paragraph>;
  }
};

export default CurrentStep;
