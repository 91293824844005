import React from 'react';
import { Typography, Divider } from 'antd';

const { Title } = Typography;

const SynergiesStep: React.FC = () => {

    return (
        <Title level={4}>
            After reviewing the Risk Factors from the AMD-Xilinx Joint Statement and Prospectus, participants
             are now encouraged to select some risks from a list of potential risks and to assign a financial
              value and probability to each such risk. Some of the answers are directly suggested in the statements being displayed.
               Same concept for Synergies, except that here, all answers are suggested in the statements being displayed.
        </Title>
    )
};

export default SynergiesStep;