import React from 'react';

import { Divider, InputNumber, Typography } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

import { CapitalInputs } from 'types';
import { formatters as fmt } from 'services';
import { Space } from 'components';
import { useDCFFProvider } from 'contexts';
import Item from './Item';
import GlossaryWrappedText from 'modules/TraineeSession/Glossary/GlossaryWrappedText';

const { Text } = Typography;

type InputProps = InputNumberProps & {
  field: keyof CapitalInputs;
  percent?: boolean;
};

const FieldInputNumber: React.FC<InputProps> = (props) => {
  const { capitalInputs, setCapitalInputs } = useDCFFProvider();
  const { field, percent } = props;

  const onChange = (value?: number | string) => {
    const newValue = percent && typeof value === 'number' ? value / 100 : value;
    setCapitalInputs({
      ...capitalInputs,
      [field]: newValue,
    });
  };

  const value = capitalInputs[field];

  return <InputNumber min={0} value={value} onChange={onChange} {...props} />;
};

const CapitalStructureTable: React.FC<{ submitted: boolean }> = ({
  submitted,
}) => {
  const { capitalData } = useDCFFProvider();

  return (
    <>
      <Divider>Capital Data</Divider>
      <Space direction="vertical">
        <Item>
          <Text>Interest Bearing debt</Text>
          <FieldInputNumber
            disabled={submitted}
            controls={false}
            field="interest_bearing_dept"
          />
        </Item>
        <Item>
          <Text>Excess cash</Text>
          <FieldInputNumber
            disabled={submitted}
            controls={false}
            field="excess_cash"
          />
        </Item>
      </Space>

      <Divider>Cost of Debt</Divider>
      <Space direction="vertical">
        <Item>
          <GlossaryWrappedText text="Cost of debt" WrapperComponent={Text} />
          <FieldInputNumber
            disabled={submitted}
            field="cost_of_dept"
            percent
            formatter={fmt.percent2Digits}
            controls={false}
          />
        </Item>
        <Item>
          <GlossaryWrappedText
            text="After tax cost of debt"
            WrapperComponent={Text}
          />
          <Text>{fmt.percent2Digits(capitalData.after_tax_cost_of_dept)}</Text>
        </Item>
        <Item>
          <GlossaryWrappedText
            text="Debt / Total Capital ratio"
            WrapperComponent={Text}
          />
          <FieldInputNumber
            disabled={submitted}
            field="dept_per_total_capital_ratio"
            percent
            formatter={fmt.percent2Digits}
            controls={false}
          />
        </Item>
        <Item>
          <GlossaryWrappedText
            text="Weighted cost of debt"
            WrapperComponent={Text}
          />
          <Text>{fmt.percent2Digits(capitalData.weighted_cost_of_dept)}</Text>
        </Item>
      </Space>

      <Divider>Cost of Equity</Divider>
      <Space direction="vertical">
        <Item>
          <GlossaryWrappedText text="Risk-free Rate" WrapperComponent={Text} />
          <FieldInputNumber
            disabled={submitted}
            field="risk_free_rate"
            percent
            formatter={fmt.percent2Digits}
            controls={false}
          />
        </Item>
        <Item>
          <GlossaryWrappedText text="Company Beta" WrapperComponent={Text} />
          <FieldInputNumber
            disabled={submitted}
            controls={false}
            field="company_beta"
          />
        </Item>
        <Item>
          <GlossaryWrappedText
            text="Expected Market Return"
            WrapperComponent={Text}
          />
          <FieldInputNumber
            disabled={submitted}
            field="expected_market_return"
            percent
            formatter={fmt.percent2Digits}
            controls={false}
          />
        </Item>
        <Item>
          <GlossaryWrappedText
            text="Market Risk Premium"
            WrapperComponent={Text}
          />
          <Text>{fmt.percent2Digits(capitalData.market_risk_premium)}</Text>
        </Item>
        <Item>
          <GlossaryWrappedText text="Cost of Equity" WrapperComponent={Text} />
          <Text>{fmt.percent2Digits(capitalData.cost_of_equity)}</Text>
        </Item>
        <Item>
          <GlossaryWrappedText
            text="Equity / Total Capital Ratio"
            WrapperComponent={Text}
          />
          <Text>
            {fmt.percent2Digits(capitalData.equity_per_total_capital_ratio)}
          </Text>
        </Item>
        <Item>
          <GlossaryWrappedText
            text="Weighted cost of equity"
            WrapperComponent={Text}
          />
          <Text>{fmt.percent2Digits(capitalData.weighted_cost_of_equity)}</Text>
        </Item>
        <Item>
          <GlossaryWrappedText
            text="Weighted cost of capital"
            WrapperComponent={Text}
          />
          <Text>
            {fmt.percent2Digits(capitalData.weighted_cost_of_capital)}
          </Text>
        </Item>

        <Divider />
        <Item>
          <GlossaryWrappedText
            text="Perpetuity Growth Rate"
            WrapperComponent={Text}
          />
          <FieldInputNumber
            disabled={submitted}
            field="perpetuity_growth_rate"
            percent
            formatter={fmt.percent2Digits}
            controls={false}
          />
        </Item>
      </Space>
    </>
  );
};

export default CapitalStructureTable;
