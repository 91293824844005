import useAuthQuery from 'hooks/useAuthQuery';
import { IndexedObject } from 'services/indexedObject';
import {
  KlassAdminView,
  TraineeAdminView,
  TrainerAdminView,
} from 'modules/AdminDashboard/types';
import {
  createDeleteRequest,
  createPostRequest,
} from 'services/createRequests';
import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';

export const useTrainers = () => {
  const { data: trainers } = useAuthQuery<IndexedObject<TrainerAdminView>>(
    'users/get_trainers_admin_view'
  );
  return trainers;
};

export const useKlasses = () => {
  const { data: klasses } = useAuthQuery<IndexedObject<KlassAdminView>>(
    'klasses/get_klasses_admin_view'
  );
  return klasses;
};

export const useTrainees = () =>
  useAuthQuery<IndexedObject<TraineeAdminView>>(
    'users/get_trainees_admin_view'
  );

export const useTraineesOfKlass = (klassId: number) => {
  const { data: trainees } = useAuthQuery<IndexedObject<TraineeAdminView>>(
    `users/${klassId}/get_trainees_of_klass`
  );
  return trainees;
};

const deleteTrainee2 = createDeleteRequest<number, unknown>(
  `users`,
  'delete_trainee'
);

export const useDeleteTrainee = (klassId: number) => {
  const queryClient = useQueryClient();

  return useMutation(deleteTrainee2, {
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data || 'An unexpected error occurred';
      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(`users/${klassId}/get_trainees_of_klass`);
    },
  });
};

const createTrainee = createPostRequest<
  { username: string; password: string; klass_id: number },
  unknown
>(`users/create_trainee/`, 'post');

export const useCreateTrainee = (klassId: number) => {
  const queryClient = useQueryClient();

  return useMutation(createTrainee, {
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data || 'An unexpected error occurred';
      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(`users/${klassId}/get_trainees_of_klass`);
    },
  });
};

const deleteKlass = createDeleteRequest<number, unknown>(
  `klasses`,
  'delete_klass'
);

export const useDeleteKlass = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteKlass, {
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data || 'An unexpected error occurred';
      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(`klasses/get_klasses_admin_view`);
      queryClient.invalidateQueries(`users/get_trainers_admin_view`);
    },
  });
};

const createKlass = createPostRequest<
  { name: string; trainer_id: number | undefined },
  unknown
>(`klasses/create_klass/`, 'post');

export const useCreateKlass = () => {
  const queryClient = useQueryClient();

  return useMutation(createKlass, {
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data || 'An unexpected error occurred';
      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(`klasses/get_klasses_admin_view`);
      queryClient.invalidateQueries(`users/get_trainers_admin_view`);
    },
  });
};

const createTrainer = createPostRequest<
  { username: string; password: string },
  unknown
>(`users/create_trainer/`, 'post');

export const useCreateTrainer = () => {
  const queryClient = useQueryClient();

  return useMutation(createTrainer, {
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data || 'An unexpected error occurred';
      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(`klasses/get_klasses_admin_view`);
      queryClient.invalidateQueries(`users/get_trainers_admin_view`);
    },
  });
};
