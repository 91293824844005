import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

type Props = {
  totalTime: number;
};

const Counter: React.FC<Props> = ({ totalTime }) => {
  const formatTime = function (timeStamp: number) {
    return `0${Math.floor(timeStamp / 60)}:${timeStamp % 60 < 10 ? '0' : ''}${timeStamp % 60
      }`;
  };
  const [time, setTime] = useState<number>(totalTime);

  // const formatTime:string = (timeStamp: number) => { return `${timeStamp % 360}:${timeStamp % 60}`}
  useEffect(() => {
    const tick = setInterval(() => {
      if (time > 0) setTime(time - 1);
      else setTime(0);
    }, 1000);
    return () => clearInterval(tick);
  }, [time]);

  return (
    <Timer>
      <Typography>{formatTime(time)}</Typography>
    </Timer>
  );
};

const Timer = styled(Typography.Title)`
  text-align: center;
  border: 1px solid black;
  margin: none;
  padding: none;
  border-radius: 0.5rem;
`;

export default Counter;
