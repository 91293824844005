import React from 'react';

import { Typography, Tag } from 'antd';

import { useSessionPlayer } from 'hooks/traineeActions';
import { UNASSIGNED, PLAYER_TYPES, BUYER, SELLER } from 'stateConstants';

const TeamType: React.FC = () => {
  const { data: player } = useSessionPlayer();
  const index = (player?.type || UNASSIGNED) as keyof typeof PLAYER_TYPES;

  const colors = {
    [UNASSIGNED]: 'grey',
    [BUYER]: 'red',
    [SELLER]: 'blue',
  };

  return (
    <div>
      <Typography.Text type="secondary">{'Team type: '}</Typography.Text>
      <Tag color={colors[index]}>{PLAYER_TYPES[index]}</Tag>
    </div>
  );
};

export default TeamType;
