import useSessionId from './useSessionId';
import useAuthQuery from './useAuthQuery';
import type { Stage } from 'types';

const useCurrentStage = () => {
  const id = useSessionId();

  return useAuthQuery<Stage>(['sessions', id, 'current-stage']);
};

export default useCurrentStage;
