/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Divider, Table } from 'antd';
import { useOfferPlayers } from '../ReviewStep/actions';
import { usePlayersCurrentStep } from 'hooks/trainerActions';
import { Loading } from 'components';

const { Title } = Typography;

const colors: { [key: number]: string } = {
  0: 'black',
  1: 'red',
  2: 'green',
};

const EliminationStep: React.FC = () => {
  const {
    data: offerPlayers,
    isLoading: isOfferPlayersLoading,
  } = usePlayersCurrentStep();

  if (isOfferPlayersLoading) return <Loading />;
  return (
    <>
      <Title level={4}>
        Each Buyer Team is now expected to update and Resubmit its offer. The
        last Buyer team negotiating with the Seller shall have 3 minutes to
        submit its offer. Confirm with the Seller that he has recieved all the
        updated offers and made his selection, and then click Next.
      </Title>
      <Table
        title={() => 'Buyer Offers'}
        pagination={false}
        rowKey="username"
        columns={[
          {
            title: 'Team',
            dataIndex: 'username',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_elimination],
                }}
              >
                {record.username}
              </div>
            ),
          },
          {
            title: 'Cash Amount',
            dataIndex: 'cash_amount',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_elimination],
                }}
              >
                {record.cash_amount}
              </div>
            ),
          },
          {
            title: 'Stock Exchange Rate',
            dataIndex: 'stock_exchange_rate',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_elimination],
                }}
              >
                {record.stock_exchange_rate}
              </div>
            ),
          },
          {
            title: 'Cash In Lieu',
            dataIndex: 'cash_in_lieu',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_elimination],
                }}
              >
                {record.cash_in_lieu}
              </div>
            ),
          },
          {
            title: 'Deferred Cash',
            dataIndex: 'deferred_cash',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_elimination],
                }}
              >
                {record.deferred_cash}
              </div>
            ),
          },
          {
            title: 'Pension Liabilities',
            dataIndex: 'pension_liabilities',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_elimination],
                }}
              >
                {record.pension_liabilities}
              </div>
            ),
          },
          {
            title: 'Target Debt',
            dataIndex: 'target_dept',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_elimination],
                }}
              >
                {record.target_dept}
              </div>
            ),
          },
          {
            title: 'Breakup Fee',
            dataIndex: 'breakup_fee',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_elimination],
                }}
              >
                {record.breakup_fee}
              </div>
            ),
          },
          {
            title: 'Seller Breakup Fee',
            dataIndex: 'seller_breakup_fee',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_elimination],
                }}
              >
                {record.seller_breakup_fee}
              </div>
            ),
          },
        ]}
        dataSource={offerPlayers
          ?.filter((p: any) => p.offer)
          .map((p: any) => ({
            username: p.username,
            ...p.offer,
          }))}
      />
      <Divider />
    </>
  );
};

export default EliminationStep;
