import React, { useState } from 'react';
import { Loading } from 'components/index';
import { Button, List, Table, Typography } from 'antd';
import { useKlasses, useTrainers } from 'modules/AdminDashboard/actions';
import EditClassModal from 'modules/AdminDashboard/Dashboard/EditClassModal';
import CreateClassModal from 'modules/AdminDashboard/Dashboard/CreateClassModal';
import CreateTrainerModal from 'modules/AdminDashboard/Dashboard/CreateTrainerModal';

const Dashboard: React.FC = () => {
  const trainers = useTrainers();
  const klasses = useKlasses();

  const [showEditClassModal, setShowEditClassModal] = useState(false);
  const [showCreateClassModal, setShowCreateClassModal] = useState(false);
  const [showCreateTrainerModal, setShowCreateTrainerModal] = useState(false);

  const [klassId, setKlassId] = useState<number>(0);

  if (trainers === undefined || klasses === undefined) return <Loading />;

  return (
    <div>
      <br />
      <div>
        <Button type="primary" onClick={() => setShowCreateClassModal(true)}>
          Create Class +
        </Button>
        <Button
          style={{ marginLeft: 15 }}
          type="primary"
          onClick={() => setShowCreateTrainerModal(true)}
        >
          Create Trainer
        </Button>
      </div>
      <br />
      <Table
        rowKey="id"
        dataSource={Object.values(trainers)}
        columns={[
          { title: 'ID', dataIndex: 'id' },
          { title: 'Trainer', dataIndex: 'username' },
          { title: 'Pass', dataIndex: 'clear_pass' },
          {
            title: 'Classes',
            dataIndex: 'trainer_klasses',
            render(text, record) {
              return (
                <List>
                  {record.trainer_klasses.map((klass_id) => {
                    return (
                      <List.Item
                        key={klass_id}
                        style={{ padding: 2 }}
                        onClick={() => {
                          setKlassId(klass_id);
                          setShowEditClassModal(true);
                        }}
                      >
                        <Typography.Link>
                          {klasses[klass_id]?.name}
                        </Typography.Link>
                      </List.Item>
                    );
                  })}
                  {!record.trainer_klasses.length && (
                    <Typography.Text type="secondary">
                      No classes
                    </Typography.Text>
                  )}
                </List>
              );
            },
          },
        ]}
        pagination={false}
        bordered
      />
      {showEditClassModal && (
        <EditClassModal
          klassId={klassId}
          visible={showEditClassModal}
          hide={() => setShowEditClassModal(false)}
        />
      )}
      {showCreateClassModal && (
        <CreateClassModal
          visible={showCreateClassModal}
          hide={() => setShowCreateClassModal(false)}
        />
      )}
      {showCreateTrainerModal && (
        <CreateTrainerModal
          visible={showCreateTrainerModal}
          hide={() => setShowCreateTrainerModal(false)}
        />
      )}
    </div>
  );
};

export default Dashboard;
