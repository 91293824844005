import React from 'react';

import { Row, Col, Space, Divider, Typography } from 'antd';
import styled from 'styled-components';

import { Loading } from 'components';
import { useCurrentStep } from 'hooks';
import QuestionCard from './QuestionCard';
import { useQuizAnswers } from './actions';

import { QuizStepType } from 'types';
import { useSessionPlayer } from 'hooks/traineeActions';
import { SELLER, UNASSIGNED } from 'stateConstants';

const QuestionsDisplay: React.FC = () => {
  const { data: currentStep, isLoading } = useCurrentStep();

  const { data: player, isLoading: isPlayerLoading } = useSessionPlayer();

  const { questions } = currentStep as QuizStepType;
  const {
    data: quizAnswers,
    isLoading: isQuizAnswersLoading,
  } = useQuizAnswers();

  if (!questions || isLoading || isQuizAnswersLoading) {
    return <Loading />;
  }

  const indexedAnswers = (quizAnswers?.answers || []).reduce((acc, value) => {
    return { ...acc, [value.question]: value.answer };
  }, {} as { [question: number]: number });

  if (player?.type === UNASSIGNED)
    return (
      <Space direction="vertical" size={32}>
        <Row gutter={[32, 32]}>
          {questions.map((question) => {
            return (
              <Col span={12} key={question.id}>
                <QuestionCard
                  question={question}
                  onSelectAnswer={() => null}
                  selectedAnswerId={indexedAnswers[question.id]}
                  showFeedback
                />
              </Col>
            );
          })}
        </Row>
        <Divider />
        <Result level={3}>Your score is {quizAnswers?.score} points.</Result>
        <Divider />
      </Space>
    );
  return (
    <Space direction="vertical" size={32}>
      <Result level={3}>
        Congratulations! You are now part of the{' '}
        {player?.type === SELLER ? 'Seller' : 'Buyer'} Team
      </Result>
      <Result level={3}>Your score is {quizAnswers?.score} points.</Result>
    </Space>
  );
};

const Result = styled(Typography.Title)`
  text-align: center;
`;

export default QuestionsDisplay;
