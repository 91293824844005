import { useAuthQuery, useSessionId } from 'hooks';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { createPostRequest } from 'services';
import { Offer, OfferFields, SelectTeamsPayload } from 'types';

export const useRetrieveOffer = (memberId: number) => {
  return useAuthQuery<Offer>(
    ['offers/retrieve_offer/'],
    { enabled: !!memberId },
    { member: memberId }
  );
};

export const useOfferElimination = () => {
  const [offer, setOffer] = useState<OfferFields>({} as OfferFields);

  const getProps = (field: keyof OfferFields) => {
    const onChange = (value: number) => {
      setOffer((prevState) => ({ ...prevState, [field]: value }));
    };

    return {
      value: offer[field],
      onChange,
    };
  };

  const updateOffer = (savedOffer: Offer) => {
    setOffer(savedOffer);
  };

  return { offer, getProps, updateOffer };
};

// const createEliminationOffer = createPostRequest<Offer, unknown>((payload) => {
//   return `offers/${payload.id}/`;
// }, 'put');

// export const useCreateEliminationOffer = () => {
//   const queryClient = useQueryClient();

//   return useMutation<unknown, unknown, Offer>(
//     (data) => createEliminationOffer(data),
//     {
//       onSettled: () => {
//         queryClient.invalidateQueries(['offers/retrieve_offer/']);
//       },
//     }
//   );
// };

const selectTeams = createPostRequest<SelectTeamsPayload, unknown>(
  (payload) => {
    return 'players/select_players';
  },
  'post'
);

export const useSelectTeams = () => {
  const queryClient = useQueryClient();
  const sessionId = useSessionId();

  return useMutation<unknown, unknown, SelectTeamsPayload>(
    (data) => selectTeams(data),
    {
      onSettled: () => {
        queryClient.invalidateQueries([`sessions/${sessionId}/player`]);
      },
    }
  );
};
