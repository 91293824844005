import React from 'react';

import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Table,
  Typography,
} from 'antd';

import Loading from 'components/Loading';
import { useKlassById2 } from 'hooks/utils';
import {
  useCreateTrainee,
  useDeleteKlass,
  useDeleteTrainee,
  useTraineesOfKlass,
} from 'modules/AdminDashboard/actions';
import { TraineeAdminView } from 'modules/AdminDashboard/types';
import { generatePassword } from 'services/passwordGenerator';

type Props = {
  klassId: number;
  visible: boolean;
  hide: () => void;
};

const EditClassModal: React.FC<Props> = ({ klassId, visible, hide }) => {
  const [form] = Form.useForm();

  const { mutate: deleteTrainee } = useDeleteTrainee(klassId);
  const { mutate: deleteKlass } = useDeleteKlass();
  const { mutate: createTrainee } = useCreateTrainee(klassId);
  const trainees = useTraineesOfKlass(klassId);
  const klass = useKlassById2(klassId);

  const handleGeneratePassword = () => {
    const random_pass = generatePassword();
    form.setFieldsValue({ password: random_pass });
  };
  // const klass2 = useKlassById(klassId);
  // console.log(klass);
  // console.log(klass2);
  if (klass === undefined || trainees === undefined) {
    return <Loading />;
  }

  const columns: any[] = [
    {
      title: 'Teams',
      key: 'username',
      dataIndex: 'username',
    },
    {
      title: 'Pass',
      key: 'clear_pass',
      dataIndex: 'clear_pass',
    },
    {
      title: 'Action',
      width: 80,
      align: 'center',
      render: function Action(record: TraineeAdminView) {
        return (
          <div>
            <Button
              type="link"
              onClick={() => {
                deleteTrainee(record.id);
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  const noData = (
    <Empty
      description={
        <Typography.Text type="secondary">
          No trainees in this class.
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  return (
    <Modal
      title={`Class: ${klass.name}`}
      visible={visible}
      onCancel={hide}
      footer={null}
      width="800px"
    >
      <Row gutter={50}>
        <Col span={12}>
          <Table
            columns={columns}
            dataSource={Object.values(trainees)}
            bordered
            locale={noDataBehaviour}
          />

          <Popover content="Class will be deleted only if it does not have playing, paused or finished sessions.">
            <Button
              danger
              onClick={() => {
                deleteKlass(klassId);
                hide();
              }}
              style={{ marginTop: 16 }}
            >
              Delete class
            </Button>
          </Popover>
        </Col>
        <Col style={{ borderLeft: '2px solid #eeeeee' }} span={12}>
          <Card title="Add a new user to the class">
            <Form
              form={form}
              labelCol={{ span: 6 }}
              // initialValues={{ delete_prev_data: false }}
            >
              <Form.Item label="Username" name="username">
                <Input />
              </Form.Item>
              <Form.Item label="Password" name="password">
                <Input />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              key="key"
              onClick={() => {
                createTrainee({
                  username: form.getFieldValue('username'),
                  password: form.getFieldValue('password'),
                  klass_id: klassId,
                });
              }}
            >
              Create user
            </Button>
            <Button
              onClick={() => handleGeneratePassword()}
              style={{ marginLeft: 15 }}
            >
              Random password
            </Button>
          </Card>
          <Typography.Text type="secondary">
            (If user is created after a session is started, new user won't have
            access to said session, only to new sessions)
          </Typography.Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditClassModal;
