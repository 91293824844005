import { useMutation, useQueryClient } from "react-query";
import { createPostRequest } from "services";
import { GlossaryEntry, GlossaryForm } from "types";


export const addGlossaryEntry = createPostRequest<GlossaryForm, GlossaryEntry>(
    'glossary/',
    'post'
);

export const updateGlossaryEntry = (id: number) =>
    createPostRequest<GlossaryEntry, GlossaryEntry>(
        `glossary/${id}/`,
        'put'
    );
export const deleteGlossaryEntry = (id: number) =>
    createPostRequest<GlossaryEntry, GlossaryEntry>(
        `glossary/${id}/delete/`,
        'post'
    );

export const useAddGlossaryEntry = () => {
    const queryClient = useQueryClient();

    return useMutation<GlossaryEntry, Error, GlossaryForm>(
        (newEntry) => addGlossaryEntry(newEntry),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['glossary']);
            },
        }
    );
};

export const useUpdateGlossaryEntry = () => {
    const queryClient = useQueryClient();

    return useMutation<GlossaryEntry, Error, GlossaryEntry>(
        (updatedEntry) => updateGlossaryEntry(updatedEntry.id)(updatedEntry),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['glossary']);
            },
        }
    );
};
export const useDeleteGlossaryEntry = () => {
    const queryClient = useQueryClient();

    return useMutation<GlossaryEntry, Error, GlossaryEntry>(
        (updatedEntry) => deleteGlossaryEntry(updatedEntry.id)(updatedEntry),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['glossary']);
            },
        }
    );
};