import React, { useMemo } from 'react';

import { Table, Typography, Divider } from 'antd';
import styled from 'styled-components';

import { useSessionMember } from 'hooks/traineeActions';
import { Loading } from 'components';
import {
  Offer,
  OfferFields,
  Player,
  PlayerWithOffer,
  SellerEliminationOffers,
} from 'types';
import { useRetrieveOffers } from '../ReviewStep/actions';

const { Title } = Typography;

type Field = {
  text: string;
  field: keyof Offer;
};

const SellerView: React.FC<{
  playerOffers: PlayerWithOffer[];
  ownPerspective: boolean;
}> = ({ playerOffers, ownPerspective }) => {
  const dataSource: Field[] = useMemo(
    () => [
      {
        text: 'Cash in the amount of',
        field: 'cash_amount',
      },
      {
        text: 'Stock -for-stock exchange in the amount of',
        field: 'stock_exchange_rate',
      },
      {
        text: 'Deferred cash consideration in the amount of',
        field: 'deferred_cash',
      },
      {
        text: 'Assumption of Pension liabilities in the amount of',
        field: 'pension_liabilities',
      },
      {
        text: 'Assumption of existing Target debt in the amount of',
        field: 'target_dept',
      },
      {
        text: 'Break - Up fee in the amount of',
        field: 'breakup_fee',
      },
      // {
      //     text: 'Probability-adjusted realizable synergies valued at',
      //     field: 'synergies_total',
      // },
      // {
      //     text: 'Probability-adjusted potential risks valued at',
      //     field: 'risks_total',
      // },
    ],
    []
  );

  const createColumn = (offer: Offer & { username: string }, index: number) => {
    return {
      title: offer.username,
      dataIndex: offer.id.toString(), // Use a unique identifier for dataIndex
      render: (value: any, record: Field) => offer[record.field], // Access the field value from the offer directly
    };
  };

  const columns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'text',
        key: 'text',
      },
      ...playerOffers
        .filter((playerOffer) => playerOffer.offer)
        .map((playerOffer) => ({
          title: playerOffer.username,
          dataIndex: playerOffer.offer.id.toString(),
          render: (value: any, record: Field) => (
            <div>{playerOffer.offer[record.field]}</div>
          ),
        })),
    ],
    [playerOffers]
  );

  return (
    <Wrapper>
      <Title level={4}>
        The Buyer teams have submitted their Preliminary Offers. Please review
        them and make a note of the most appealing ones. As they will come to
        try and negotiate with you.
      </Title>
      <Divider />
      <Table
        rowKey="field"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

export default SellerView;
