import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createPostRequest } from 'services';
import { useSessionId } from 'hooks';
import { useAuthState } from 'contexts';
import { QuizAnswers } from 'types';

type SubmitPayload = {
  session_id: number;
  person: number;
  quiz: number;
  answers: { question: number; answer: number }[];
};

export const submitQuiz = createPostRequest<SubmitPayload, unknown>(
  'quizzes/quiz-attempt/',
  'post'
);

export const useSubmitQuiz = (data: SubmitPayload) => {
  const queryClient = useQueryClient();
  return useMutation(() => submitQuiz(data), {
    onSettled: () => queryClient.invalidateQueries(['quizzes', 'quiz-answers']),
  });
};

export const getQuizAnswers = createPostRequest<
  { session_id: number; person_id: number },
  QuizAnswers
>('quizzes/quiz-answers/', 'post');

export const useQuizAnswers = () => {
  const { person } = useAuthState();
  const sessionId = useSessionId();

  return useQuery<QuizAnswers>(
    ['quizzes', 'quiz-answers'],
    () => getQuizAnswers({ person_id: person?.id, session_id: sessionId }),
    { enabled: !!person?.id && !!sessionId }
  );
};
