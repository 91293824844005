import { QueryFunctionContext, useQuery } from 'react-query';
import useAuthQuery from './useAuthQuery';
import useSessionId from './useSessionId';

import type { Player, StepDataMap } from 'types';
import { useMemo } from 'react';
import useHeaders from './useHeaders';
import axios from 'axios';
import settings from 'settings';

export const useRetrieveCurrentStep = <T extends keyof StepDataMap>(
  playerId: number,
  stepType: T
) => {
  const sessionId = useSessionId();
  const headers = useHeaders(); // Call useHeaders here at the top level

  const queryKey = useMemo(() => ['sessions', sessionId, 'current-step', 'retrieve', playerId.toString()], [sessionId, playerId]);

  const fetchStepData = async (context: QueryFunctionContext) => {
    const qk = context.queryKey; // No destructuring, directly access the queryKey from the context
    const currentplayerId = qk[qk.length - 1] as string; // Cast the playerId to string if it is not already
    const response = await axios.get(`${settings.API_URL}/${qk.slice(0, -1).join('/')}`, {
      headers,
      params: { player: currentplayerId },
    });
    return response.data;
  };

  // Use the useQuery hook from react-query
  const { data, refetch, isLoading } = useQuery(queryKey, fetchStepData, {
    // ... other options here
  });

  return { data, refetch, isLoading };
};


export const usePlayersCurrentStep = () => {
  const id = useSessionId();
  return useAuthQuery<any>(['sessions', id, 'current-step', 'players'], {});
};



// old hooks
export const usePlayers = () => {
  const id = useSessionId();
  return useAuthQuery<Player[]>(['sessions', id, 'players']);
};

