import React, { useEffect, useMemo, useState } from 'react';

import { Table, Typography, Checkbox, Button, Divider } from 'antd';
import styled from 'styled-components';

import { useSessionMember, useSubmitCurrentStep } from 'hooks/traineeActions';
import { Loading } from 'components';
import { Offer, Player, SellerEliminationOffers } from 'types';
import { useRetrieveOffers } from '../ReviewStep/actions';
import { useSelectTeams } from './actions';
import { usePlayers, useRetrieveCurrentStep } from 'hooks/trainerActions';
import { isOfferUpToDate } from 'hooks/useOfferUpToDate';
import { useCurrentStage, useCurrentStep } from 'hooks';

const { Title, Text } = Typography;

type Field = {
  text: string;
  field: keyof Offer;
};

const SellerElimination: React.FC<{
  player: Player;
  ownPerspective: boolean;
  apiOffers: SellerEliminationOffers;
}> = ({ player, apiOffers, ownPerspective }) => {
  const { data: member, isLoading: isMemberLoading } = useSessionMember();
  const { data: step } = useCurrentStep();
  const { data: stage } = useCurrentStage();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedTeams, setSelectedTeams] = useState<
    { id: number; selected: boolean }[]
  >([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { mutate: selectTeams } = useSubmitCurrentStep();

  useEffect(() => {
    if (apiOffers) {
      setSelectedTeams(
        apiOffers.map((o) => {
          return { id: o.id, selected: o.selected as boolean };
        })
      );
      setSubmitted(apiOffers.some((offer) => offer.passed_elimination));
    }
  }, [apiOffers]);

  const dataSource: Field[] = useMemo(
    () => [
      {
        text: 'Offer updated',
        field: 'tier',
      },
      {
        text: 'Cash in the amount of',
        field: 'cash_amount',
      },
      {
        text: 'Stock -for-stock exchange in the amount of',
        field: 'stock_exchange_rate',
      },
      {
        text: 'Deferred cash consideration in the amount of',
        field: 'deferred_cash',
      },
      {
        text: 'Assumption of Pension liabilities in the amount of',
        field: 'pension_liabilities',
      },
      {
        text: 'Assumption of existing Target debt in the amount of',
        field: 'target_dept',
      },
      {
        text: 'Break-Up fee in the amount of',
        field: 'breakup_fee',
      },
      {
        text: 'Seller Break-Up fee in the amount of',
        field: 'seller_breakup_fee',
      },
      // {
      //   text: 'Probability-adjusted realizable synergies valued at',
      //   field: 'synergies_total',
      // },
      // {
      //   text: 'Probability-adjusted potential risks valued at',
      //   field: 'risks_total',
      // },
      {
        text: 'Selected',
        field: 'selected',
      },
    ],
    []
  );

  if (isMemberLoading || !apiOffers) {
    return <Loading />;
  }

  if (
    Array.isArray(apiOffers) &&
    apiOffers.filter((p) => p.passed_elimination !== 0).length !== 0
  )
    return (
      <>
        <Title level={4}>
          Dear Xilinx Team,
          <br />
        </Title>
        <Title level={4}>
          The number of potential buyers has now been reduced to only 2. There
          will be one last negotiation with each of the 2 teams, for which you
          will recieve updated guidance from the Board.
        </Title>
      </>
    );

  const createColumn = (offer: any, index: number) => {
    return {
      title: offer.username,
      render: (text: any, record: any) => {
        switch (record.field) {
          case 'tier':
            const upToDate = isOfferUpToDate(
              (apiOffers as any)[index],
              step,
              stage
            );
            return upToDate ? (
              <p style={{ color: 'green' }}>Yes</p>
            ) : (
              <p style={{ color: 'red' }}>No</p>
            );
          case 'selected':
            return (
              <Checkbox
                checked={selectedTeams[index]?.selected || false}
                disabled={submitted}
                onChange={(e) => {
                  if (
                    selectedTeams.filter((s) => s.selected).length >= 2 &&
                    e.target.checked
                  ) {
                    setErrorMessage('Only 2 teams can be selected');
                  } else {
                    setSelectedTeams(
                      selectedTeams.map((st, idx) => {
                        if (idx === index) {
                          return { ...st, selected: e.target.checked };
                        }
                        return st;
                      })
                    );
                  }
                }}
              >
                Select
              </Checkbox>
            );
          default:
            return (apiOffers as any)[index][record.field];
        }
      },
    };
  };

  const columns = [
    {
      title: '',
      dataIndex: 'text',
    },
  ];

  apiOffers.forEach((offer, index) =>
    columns.push(createColumn(offer, index) as any)
  );

  return (
    <Wrapper>
      <Title level={4}>
        Dear Seller Team, Good job thus far. It’s been tough, but great. It may
        take another 2-3 more minutes until all Teams resubmit their Offers.
        Once the updated Offers appear on your screen, you will have 1 minute to
        decide which two teams are the finalists in this Transaction process.
      </Title>
      <Title level={4}>
        YOU CAN ONLY SELECT TWO TEAMS TO PROCEED TO THE NEXT STEP.
      </Title>
      <Divider />
      <Table
        rowKey="field"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      {ownPerspective && (
        <Container>
          <Button
            type="primary"
            onClick={() => {

              selectTeams({
                players: selectedTeams,
              });
            }}
            disabled={
              submitted || selectedTeams.filter((s) => s.selected).length !== 2
            }
          >
            Submit
          </Button>
          <Text>{errorMessage}</Text>
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  place-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export default SellerElimination;
