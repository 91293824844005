import React from 'react';

import { Typography, List, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { ListItem, Loading } from 'components';
import { useCurrentStage, useCurrentStep, useModal } from 'hooks';
import {
  useSessionMember,
  useSessionPlayer,
  useSubmitCurrentStep,
} from 'hooks/traineeActions';
import { useRetrieveBuyerProposal } from '../ProposalStep/actions';
import {
  useRetrieveOffer,
  useRetrieveSynergiesTotals,
  // useSubmitOffer,
} from './actions';
import ProposalModal from './ProposalModal';
import SynergiesModal from './SynergiesModal';
import { Offer } from 'types';
import useOfferUpToDate from 'hooks/useOfferUpToDate';

const { Title, Paragraph } = Typography;

const BuyerReview: React.FC<{
  offer: Offer;
  ownPerspective: boolean;
}> = ({ offer, ownPerspective }) => {
  const { data: member, isLoading: isMemberLoading } = useSessionMember();
  const offerUpToDate = useOfferUpToDate(offer);

  // const {
  //   data: synergyTotals,
  //   isLoading: areTotalsLoading,
  // } = useRetrieveSynergiesTotals(memberId);

  const [PModal, showProposalModal] = useModal(ProposalModal);
  // const [SModal, showSynergiesModal] = useModal(SynergiesModal);
  const { mutate: createOffer } = useSubmitCurrentStep();
  // const { data: player } = useSessionPlayer();

  // const { data: offer, isLoading: isOfferLoading } = useRetrieveOffer(memberId);

  if (isMemberLoading) {
    return <Loading />;
  }

  if (!offer) {
    return <Paragraph>Offer not found</Paragraph>;
  }

  // if (offer.tier === 1) {
  //   return (
  //     <Paragraph>
  //       Your offer has been successfully sent by {offer.last_updated_by?.name}
  //     </Paragraph>
  //   );
  // }

  // const risksTotal = synergyTotals?.risks_total ?? 0;
  // const synergiesTotal = synergyTotals?.synergies_total ?? 0;
  // const total = synergiesTotal - risksTotal;

  return (
    <Wrapper>
      <Title level={4}>Review your offer</Title>
      {offer && (
        <Title level={2}>
          Offer submitted by {offer.last_updated_by?.name}:
        </Title>
      )}
      <List>
        The offer you are about to submit to the Seller includes the following:
        {offer.cash_amount && (
          <ListItem>
            Cash in the amount of {offer.cash_amount} million USD
          </ListItem>
        )}
        {offer.stock_exchange_rate && offer.cash_in_lieu && (
          <ListItem>
            Stock-for-stock at an exchange rate of {offer.stock_exchange_rate}{' '}
            totalling a total stock value in the amount of {offer.cash_in_lieu}{' '}
            million USD
          </ListItem>
        )}
        {offer.deferred_cash && (
          <ListItem>
            Deferred cash consideration in the amount of {offer.deferred_cash}{' '}
            million USD
          </ListItem>
        )}
        {offer.pension_liabilities && (
          <ListItem>
            Assumption of Pension Liabilities in the amount of{' '}
            {offer.pension_liabilities} million USD
          </ListItem>
        )}
        {offer.target_dept && (
          <ListItem>
            Assumption of Financial Debt in the amount of {offer.target_dept}{' '}
            million USD
          </ListItem>
        )}
        {offer.breakup_fee && (
          <ListItem>
            A reverse break-up fee in the amount of {offer.breakup_fee} million
            USD
          </ListItem>
        )}
      </List>

      {/* <List>
        In addition, your evaluation of Risks and Synergies suggests the
        following adjustments to the Total Consideration highlighted above:
        <ListItem>
          increasing consideration paid by up to a total amount of{' '}
          {formatters.commasNoDigits(synergiesTotal)} million USD, in light of
          potential synergies
        </ListItem>
        <ListItem>
          decreasing consideration paid by up to a total amount of{' '}
          {formatters.commasNoDigits(risksTotal)} million USD, taking into
          account the potential risks identified{' '}
        </ListItem>
        Therefore, the implied total adjustment to Total Consideration shall be
        the {total >= 0 ? 'addition' : 'subtraction'} of an amount of up to{' '}
        {formatters.commasNoDigits(total)} million USD
      </List> */}
      <Footer>
        <EditButtons>
          <Button
            disabled={offerUpToDate}
            onClick={() => showProposalModal({})}
          >
            I want to change my offer
          </Button>
          {/* <Button onClick={() => showSynergiesModal({})}>
            I want to change the adjustments
          </Button> */}
        </EditButtons>
        <Button
          onClick={() => {
            createOffer({
              offer: {
                ...offer,
                // risks_total: risksTotal,
                // synergies_total: synergiesTotal,
                tier: 1,
              },
              player: member?.player,
              member: member?.id,
            });
            // alert('not implemented');
          }}
          disabled={offerUpToDate}
          type="primary"
        >
          <ArrowRightOutlined />
          I'm ready to submit my offer
        </Button>
      </Footer>

      {PModal}
      {/* {SModal} */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  & > * {
    margin-bottom: 30px !important;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EditButtons = styled.div`
  display: flex;
  gap: 20px;
`;

export default BuyerReview;
