import { useEffect, useState } from 'react';

import { OfferFields } from 'types';
import { useSessionMember, useSessionPlayer } from 'hooks/traineeActions';
import { useRetrieveBuyerProposal } from '../ProposalStep/actions';
import { useRetrieveCurrentStep } from 'hooks/trainerActions';

const useReviewProposal = () => {
  const [proposal, setProposal] = useState({} as OfferFields);
  const { data: player } = useSessionPlayer();
  const { data: queryProposal } = useRetrieveCurrentStep(player?.id || 0, "ReviewStepType");

  const resetFromQuery = () => {
    if (queryProposal) {
      setProposal(queryProposal);
    }
  };

  useEffect(() => {
    resetFromQuery();
  }, [queryProposal]);

  const getProps = (field: keyof Omit<OfferFields, 'last_updated_by'>) => {
    const onChange = (value: number) => {
      setProposal((prevState) => ({ ...prevState, [field]: value }));
    };

    return {
      value: proposal[field],
      onChange,
    };
  };

  return { proposal, getProps, resetFromQuery };
};

export default useReviewProposal;
