import React from 'react';

import { Typography } from 'antd';

import { useCurrentStep } from 'hooks';
import { useSessionMember, useSessionPlayer } from 'hooks/traineeActions';
import { Loading } from 'components';
import { BUYER, SELLER, UNASSIGNED } from 'stateConstants';
import {
  NegotiationChoicesAttempt,
  NegotiationChoicesStepType,
  Offer,
  Player,
  PlayerWithOffer,
  SellerEliminationOffers,
} from 'types';
import SellerView from './SellerView';
import { BuyerView } from './BuyerView';

const { Paragraph } = Typography;

const NegotiationChoicesStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
  data: NegotiationChoicesAttempt | PlayerWithOffer[];
}> = ({ player, ownPerspective, data }) => {
  const { data: member, isLoading: isMemberLoading } = useSessionMember();

  if (isMemberLoading) {
    return <Loading />;
  }

  if (!player || player.type === UNASSIGNED) {
    return <Paragraph>Player type cannot be unassigned</Paragraph>;
  }

  return (
    <>
      {player.type === BUYER && (
        <BuyerView
          attempt={data as NegotiationChoicesAttempt}
          ownPerspective={ownPerspective}
          player={player}
        />
      )}
      {player.type === SELLER &&
        (data ? (
          <SellerView
            playerOffers={data as PlayerWithOffer[]}
            ownPerspective={ownPerspective}
          />
        ) : (
          <Loading />
        ))}
    </>
  );
};

export default NegotiationChoicesStep;
