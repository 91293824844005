import React from 'react';

import { RelativeSwitch } from 'components/Routing';

import PageLayout from 'components/PageLayout';

import routes from 'modules/AdminDashboard/routes';

const AdminDashboard: React.FC = () => {
  return (
    <PageLayout routes={routes}>
      <RelativeSwitch routes={routes} />
    </PageLayout>
  );
};

export default AdminDashboard;
