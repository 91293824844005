import React, { useEffect, useState } from 'react';

import {
  Table,
  Button,
  InputNumber,
  Input,
  Switch,
  Typography,
  Divider,
} from 'antd';
import { DeleteOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { Space } from 'components';
import * as fn from './functions';
import { formatters } from 'services';

import type {
  TransactionComparableAttempt,
  TransactionComparableData,
  TransactionComparableFields,
  TransactionComparableInputs,
} from 'types';
import { useCurrentStep, useSessionId } from 'hooks';
import { useSessionMember, useSubmitCurrentStep } from 'hooks/traineeActions';
import { useSubmitTransactionComps } from './actions';
import GlossaryWrappedText from 'modules/TraineeSession/Glossary/GlossaryWrappedText';

type StateComparable<T> = Partial<T> & { key: number; ignore: boolean };

const ComparablesTable: React.FC<{
  attempt: TransactionComparableFields | undefined;
  ownPerspective: boolean;
}> = ({ attempt, ownPerspective }) => {
  const { data: step } = useCurrentStep();
  const { data: member } = useSessionMember();
  const { mutate: submitComparables } = useSubmitCurrentStep();

  const [comparableInputs, setComparableInputs] = useState<
    StateComparable<TransactionComparableInputs>[]
  >([]);
  const [comparableData, setComparableData] = useState<
    StateComparable<TransactionComparableData>[]
  >([]);
  const [lastKey, setLastKey] = useState(10000000);

  const initialState: StateComparable<TransactionComparableInputs>[] = [
    {
      target_name: 'Maxim Integrated Products, Inc.',
      target_country: 'USA',
      announce_year: 2020,
      deal_stake: 100,
      control: true,
      equity_value: 16588,
      net_debt: -464,
      ebitda: 645,
      key: 0,
      ignore: false,
    },
    {
      target_name: 'Cypress Semiconductor Corporation',
      target_country: 'USA',
      announce_year: 2020,
      deal_stake: 100,
      control: true,
      equity_value: 6901,
      net_debt: 454,
      ebitda: 351,
      key: 1,
      ignore: false,
    },
    {
      target_name: 'Integrated Device Technology, Inc.',
      target_country: 'USA',
      announce_year: 2018,
      deal_stake: 100,
      control: true,
      equity_value: 5111,
      net_debt: 267,
      ebitda: 132,
      key: 2,
      ignore: false,
    },
    {
      target_name: 'Microsemi Corporation',
      target_country: 'USA',
      announce_year: 2018,
      deal_stake: 100,
      control: true,
      equity_value: 5884,
      net_debt: 1268,
      ebitda: 384,
      key: 3,
      ignore: false,
    },
    {
      target_name: 'Linear Technology Corporation',
      target_country: 'USA',
      announce_year: 2017,
      deal_stake: 100,
      control: true,
      equity_value: 10944,
      net_debt: -1039,
      ebitda: 516,
      key: 4,
      ignore: false,
    },
    {
      target_name: 'SVF Holdco (UK) Limited',
      target_country: 'UK',
      announce_year: 2016,
      deal_stake: 98.55,
      control: true,
      equity_value: 23925,
      net_debt: -805,
      ebitda: 448,
      key: 5,
      ignore: false,
    },
  ];

  useEffect(() => {
    setComparableInputs(initialState);
  }, []);

  const addComparable = () => {
    setComparableInputs([...comparableInputs, { key: lastKey, ignore: false }]);
    setLastKey(lastKey + 1);
  };
  const sessionId = useSessionId();

  const onSubmit = () => {
    const comparables = comparableData
      .filter((c) => !c.ignore)
      .map(({ key, control, deal_stake, ...comparable }) => {
        return {
          ...comparable,
          control: control ?? false,
          deal_stake: deal_stake || 0, //  / 100,
        };
      }) as TransactionComparableData[];

    submitComparables({
      transaction_comparables: comparables,
      member: member?.id || 0,
      player: member?.player || 0,
      step: step?.id || 0,
      session_id: sessionId,
    });
  };

  useEffect(() => {
    const data: typeof comparableData = comparableInputs.map((input) => {
      const { equity_value, net_debt, ebitda } = input;

      const implied_multiples = fn.getImpliedMultiples(
        equity_value,
        net_debt,
        ebitda
      );

      return {
        ...input,
        implied_multiples,
      };
    });

    setComparableData(data);
  }, [comparableInputs]);

  return (
    <Wrapper>
      <GlossaryWrappedText
        WrapperComponent={Typography.Title}
        wrapperProps={{ level: 4 }}
        text={`Based on the information that has already been provided to you, you
        should be able to enter the data in the table below. Not all
        transactions that have been provided to you are true comparables. Use
        your judgment in selecting the ones you think make sense, based on size,
        geography, Announcement Year and Target’s business model.If you require
        additional rows, please add them as needed. Where no deal stake has been
        listed, assume it is 100%. This Step SHOULD NOT TAKE YOU MORE THAN 15
        MINUTES.`}
      />
      <Typography.Title level={4}>
        ONLY ONE PERSON PER TEAM SHOULD ENTER THE DATA.
      </Typography.Title>
      <Divider />
      <Space direction="vertical">
        <Table
          rowKey="key"
          dataSource={
            attempt
              ? attempt.transaction_comparables.map((tc) => ({
                  ...tc,
                  key: tc.id,
                  ignore: false,
                }))
              : comparableData
          }
          columns={[
            {
              title: 'Target Name',
              dataIndex: 'target_name',
              render(value, record, index) {
                return (
                  <Input
                    disabled={!!attempt}
                    type="text"
                    value={value}
                    onChange={(e) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].target_name = e.target.value;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
              width: '175px',
            },
            {
              title: 'Target Country',
              dataIndex: 'target_country',
              render(value, record, index) {
                return (
                  <Input
                    disabled={!!attempt}
                    type="text"
                    value={value}
                    onChange={(e) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].target_country = e.target.value;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: 'Announce year',
              dataIndex: 'announce_year',
              render(value, record, index) {
                return (
                  <InputNumber
                    disabled={!!attempt}
                    controls={false}
                    value={value}
                    onChange={(val) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].announce_year = val;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
              width: '90px',
            },
            // {
            //   title: 'Bidder Name',
            //   dataIndex: 'bidder_name',
            //   render(value, record, index) {
            //     return (
            //       <Input
            // disabled={!!attempt}
            //         type="text"
            //         value={value}
            //         onChange={(e) => {
            //           const newInputs = [...comparableInputs];
            //           newInputs[index].bidder_name = e.target.value;
            //           setComparableInputs(newInputs);
            //         }}
            //       />
            //     );
            //   },
            // },
            {
              title: 'Deal Stake %',
              dataIndex: 'deal_stake',
              render(value, record, index) {
                return (
                  <InputNumber
                    disabled={!!attempt}
                    controls={false}
                    value={value}
                    min={0}
                    max={100}
                    onChange={(val) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].deal_stake = val;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: 'Control',
              dataIndex: 'control',
              render(value, record, index) {
                return (
                  <Switch
                    disabled={!!attempt}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={value}
                    onChange={(val) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].control = val;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: 'Enterprise Value $M',
              dataIndex: 'equity_value',
              render(value, record, index) {
                return (
                  <InputNumber
                    disabled={!!attempt}
                    controls={false}
                    value={value}
                    onChange={(val) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].equity_value = val;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: 'Net Debt $M',
              dataIndex: 'net_debt',
              render(value, record, index) {
                return (
                  <InputNumber
                    disabled={!!attempt}
                    controls={false}
                    value={value}
                    onChange={(val) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].net_debt = val;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: 'EBITDA $M',
              dataIndex: 'ebitda',
              render(value, record, index) {
                return (
                  <InputNumber
                    disabled={!!attempt}
                    controls={false}
                    value={value}
                    onChange={(val) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].ebitda = val !== 0 ? val : undefined;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: 'Implied Equity Value $M',
              dataIndex: 'implied_multiples',
              render: formatters.commas2Digits,
            },
            // {
            //   title: 'Consideration received',
            //   children: [
            //     {
            //       title: 'Cash value',
            //       dataIndex: 'cash_value',
            //       render(value, record, index) {
            //         return (
            //           <Input
            // disabled={!!attempt}Number
            //             value={value}
            //             onChange={(val) => {
            //               const newInputs = [...comparableInputs];
            //               newInputs[index].cash_value = val;
            //               setComparableInputs(newInputs);
            //             }}
            //           />
            //         );
            //       },
            //     },
            //     {
            //       title: 'Shares value',
            //       dataIndex: 'shares_value',
            //       render(value, record, index) {
            //         return (
            //           <Input
            // disabled={!!attempt}Number
            //             value={value}
            //             onChange={(val) => {
            //               const newInputs = [...comparableInputs];
            //               newInputs[index].shares_value = val;
            //               setComparableInputs(newInputs);
            //             }}
            //           />
            //         );
            //       },
            //     },
            //   ],
            // },
            // {
            //   title: 'Ignore',
            //   dataIndex: 'ignore',
            //   render(value, record, index) {
            //     return (
            //       <RedSwitch
            //         checkedChildren="Yes"
            //         unCheckedChildren="No"
            //         checked={value}
            //         onChange={(checked) => {
            //           const newInputs = [...comparableInputs];
            //           newInputs[index].ignore = checked;
            //           setComparableInputs(newInputs);
            //         }}
            //       />
            //     );
            //   },
            // },
            {
              title: 'Actions',
              render(value, record, index) {
                return (
                  <DeleteOutlined
                    style={{
                      fontSize: 20,
                      color: attempt ? 'gray' : '#ff4d4f',
                    }}
                    disabled={!!attempt}
                    onClick={() => {
                      if (attempt) return;
                      const newInputs = [...comparableInputs];
                      newInputs.splice(index, 1);
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
          ]}
          bordered
          pagination={false}
        />

        {ownPerspective && (
          <JustifySpaceBetween>
            <Button type="dashed" disabled={!!attempt} onClick={addComparable}>
              <PlusOutlined /> Add
            </Button>

            <Button type="primary" disabled={!!attempt} onClick={onSubmit}>
              <SendOutlined /> Submit
            </Button>
          </JustifySpaceBetween>
        )}
      </Space>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .ant-table-cell {
    padding: 10px 5px !important;
    margin: 0;
  }

  & .ant-input-number {
    width: auto;
  }

  & tr .ant-table-cell {
    padding: 10px;
    text-align: center;
  }
`;

const JustifySpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RedSwitch = styled(Switch)`
  &.ant-switch-checked {
    background: #ff4d4f;
  }
  & .ant-click-animating-node {
    box-shadow: none !important;
  }
`;

export default ComparablesTable;
