import React, { useState } from 'react';

import { Button, Input, Modal, Radio, Space } from 'antd';

import {
  useCreatePerson,
  usePersons,
} from '../modules/TraineeDashboard/actions';
import { useAuthState, useAuthActions } from 'contexts';
import { Loading } from 'components/index';
import { Person } from 'types/index';

const PersonSelector: React.FC = () => {
  const { person, userId } = useAuthState();
  const { setPersonAndPersist, logout, setShowPersonModal } = useAuthActions();
  const {showPersonModal} = useAuthState();
  const { data: persons, isLoading } = usePersons();
  const [selectedPerson, setSelectedPerson] = useState<Person>(person);
  const [newPerson, setNewPerson] = useState('');

  const { mutate: addPerson } = useCreatePerson();

  if (persons === undefined || isLoading) return <Loading />;

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <Modal
      title="Choose your username or add yourself to the team!"
      visible={!person || showPersonModal}
      closable={false}
      footer={[
        <Button onClick={logout}>Logout</Button>,
        <Button disabled={!selectedPerson} onClick={() => {setPersonAndPersist(selectedPerson); setShowPersonModal(false)}}>Ok</Button>,
      ]}
    >
      <Radio.Group
        value={selectedPerson}
        onChange={(e) => {
          console.log(e.target.value);
          
          setSelectedPerson(e.target.value);
        }}
      >
        {persons.map((pers) => (
          <Radio style={radioStyle} value={pers} key={pers.id}>
            {pers.name}
          </Radio>
        ))}
      </Radio.Group>
      <br />
      <Space style={{ marginTop: 20 }}>
        <Input
          placeholder="John Doe"
          value={newPerson}
          onChange={(e) => setNewPerson(e.target.value)}
        />
        <Button
          type="primary"
          onClick={() => {
            // dispatch(createPerson({ name: newPerson }));
            addPerson({ name: newPerson, user_id: userId });
            setNewPerson('');
          }}
        >
          Add new person
        </Button>
      </Space>
      {/* <List */}
      {/*   dataSource={persons} */}
      {/*   split={false} */}
      {/*   size="small" */}
      {/*   loading={isLoading} */}
      {/*   renderItem={(item) => ( */}
      {/*     <List.Item> */}
      {/*       <PersonButton */}
      {/*         onClick={() => { */}
      {/*           console.log(item); */}
      {/*           // setPerson(item); */}
      {/*         }} */}
      {/*       > */}
      {/*         {item.name} */}
      {/*       </PersonButton> */}
      {/*     </List.Item> */}
      {/*   )} */}
      {/* /> */}
    </Modal>
  );
};

// const PersonButton = styled(Button)`
//   width: 100%;
// `;

export default PersonSelector;
