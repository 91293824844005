import React from 'react';
import { List, Typography } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

const { Text } = Typography;

const ListItem: React.FC = ({ children }) => {
  return (
    <List.Item>
      <Text>
        <CaretRightOutlined /> {children}
      </Text>
    </List.Item>
  );
};

export default ListItem;
