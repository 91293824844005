import React from 'react';

import { useCurrentStep } from 'hooks';
import { Loading } from 'components';
import * as Steps from './Steps';
import { Typography } from 'antd';
import { useArePlayersAssigned } from './Steps/QuizStep/actions';
import AssignRolesTable from './AssignRolesTable';
import { Step } from 'types';

const { Title } = Typography;

const CurrentStep: React.FC<{ step: Step }> = ({ step }) => {
  const arePlayersAssigned = useArePlayersAssigned();
  // console.log(step, arePlayersAssigned);

  if (!step || arePlayersAssigned === undefined) return <Loading />;

  switch (step?.resourcetype) {
    case 'QuizStep':
      return <Steps.QuizStep />;
    case 'ReadingsInstructionsStep':
      return <Steps.ReadingsInstructionsStep />;
    case 'ReadingsValuationInstructionsStep':
      return <Steps.ReadingsValuationInstructionsStep />;
    case 'BilateralDocsStep':
      return <Steps.BilateralDocsStep />;
    case 'DocAcquisitionStep':
      return <Steps.DocAcquisitionStep />;
    case 'DCFStep':
      return <Steps.DCFStep />;
    case 'TradingComparablesStep':
      return <Steps.TradingComparablesStep />;
    case 'TransactionComparablesStep':
      return <Steps.TransactionComparablesStep />;
    case 'NegotiationChoicesStep':
      return <Steps.NegotiationChoicesStep />;
    case 'ProposalStep':
      return <Steps.ProposalStep />;
    case 'ReadingsBilateralDocsStep':
      return <Steps.ReadingsBilateralDocsStep />;
    case 'FinalOfferStep':
      return <Steps.FinalOfferStep />;
    case 'PreEliminationStep':
      return <Steps.PreEliminationStep />;
    case 'SynergiesStep':
      return <Steps.SynergiesStep />;
    case 'ReviewStep':
      return <Steps.ReviewStep />;
    case 'EliminationStep':
      return <Steps.EliminationStep />;
    default:
      return (
        <div>
          {arePlayersAssigned ? (
            <Title level={4}>
              Dear Trainer, once participants have logged in, ask them to click
              on the Simulation Tab at the top of their screen and then you can
              click on the START button.
            </Title>
          ) : (
            <Title level={4}>
              Dear Trainer, please assign roles in order to proceed.
            </Title>
          )}

          <br />
          <AssignRolesTable />
        </div>
      );
  }
};

export default CurrentStep;
