import axios from 'axios';

import useHeaders from './useHeaders';
import settings from 'settings';

import {
  QueryKey,
  QueryFunctionContext,
  useQuery,
  UseQueryOptions,
} from 'react-query';

function useAuthQuery<ResponseData>(
  query: QueryKey,
  options?: UseQueryOptions<ResponseData>,
  params: unknown = {}
) {
  const headers = useHeaders();

  const queryFn = async ({ queryKey }: QueryFunctionContext) => {
    const path = queryKey.join('/');
    const url = `${settings.API_URL}/${path}`;

    const { data } = await axios.get(url, {
      headers,
      params,
    });
    return data;
  };

  return useQuery<ResponseData>(query, queryFn, options);
}

export default useAuthQuery;
