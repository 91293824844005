import React from 'react';
import styled from 'styled-components';

import { Spin, Row, Col } from 'antd';

type Props = {
  fullscreen?: boolean;
  className?: string;
};

const Loading: React.FC<Props> = ({ className }) => {
  return (
    <Row justify="center" align="middle" className={className}>
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  );
};

export default styled(Loading)`
  width: ${(props) => (props.fullscreen ? '100vw' : '100%')};
  height: ${(props) => (props.fullscreen ? '100vh' : '100%')};
`;
