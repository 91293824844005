import React from 'react';

import { Divider, Table, Typography } from 'antd';

import { PLAYER_TYPES } from 'stateConstants';
import { Loading } from 'components';
import { useCurrentStep } from 'hooks';
import { useBilateralDocsPlayers } from './actions';

const BilateralDocsStep: React.FC = () => {
  const {
    data: currentStep,
    isLoading: isCurrentStepLoading,
  } = useCurrentStep();
  const {
    data: players,
    isLoading: isPlayersLoading,
  } = useBilateralDocsPlayers(currentStep?.id || 0);

  if (isPlayersLoading || isCurrentStepLoading) {
    return <Loading />;
  }

  const dataSource = (players || []).sort((a, b) =>
    a.username.localeCompare(b.username)
  );

  return (
    <div>
      <Typography.Title level={4}>
        Participants are currently selecting some of the key terms appearing in
        their bilateral docs (Seller selects terms for Process Letter and Buyers
        select terms for NDAs).
      </Typography.Title>
      <Typography.Title level={4}>
        Confirm verbally that participants have selected their 3 criteria and then you can click the Next button.
        At least one participant from each team must enter his/her choices for the
        simulation to proceed.
      </Typography.Title>
      <Divider/>
      <Table
        pagination={false}
        rowKey="id"
        columns={[
          { title: 'Team', dataIndex: 'username' },
          { title: 'Member count', dataIndex: 'person_count' },
          {
            title: 'Type',
            dataIndex: 'type',
            render(value: keyof typeof PLAYER_TYPES) {
              return PLAYER_TYPES[value];
            },
          },
          {
            title: 'Document Attempts',
            dataIndex: 'bilateral_docs_attempts_count',
          },
        ]}
        dataSource={dataSource}
      />
    </div>
  );
};

export default BilateralDocsStep;
