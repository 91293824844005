import { Query } from 'react-query';

const invalidateIfIncludes = (queries: string[]) => {
  return (query: Query) => {
    const { queryKey } = query;

    return queries.reduce((invalidate, lookup) => {
      return invalidate || queryKey.includes(lookup);
    }, false);
  };
};

export default invalidateIfIncludes;
