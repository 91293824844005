import React, { useMemo, useState } from 'react';

import { Button, Image, Typography } from 'antd';
import Seller_Reading3 from 'assets/Seller_Reading3.png';
import Seller_Reading2 from 'assets/Seller_Reading2.png';
import Seller_Reading1 from 'assets/Seller_Reading1.png';

const { Title } = Typography;

const SellerReview: React.FC = () => {
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <Title level={4}>
        Below you will find the actual results of the combined entity for two
        quarters following the closing of the Xilinx acquisition (which occurred
        on Feb. 14, 2022) as well as a marketing piece published on the Closing
        Date. This shows how relevant the Transaction has been for both AMD and
        Xilinx.
      </Title>
      <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
        <Image src={Seller_Reading3} />
        <Image src={Seller_Reading2} />
        <Image src={Seller_Reading1} />
      </div>
      <Button
        type="primary"
        style={{ marginTop: '2rem' }}
        onClick={() => setClicked(true)}
        disabled={clicked}
      >
        I UNDERSTAND THIS TRANSACTION NEEDS TO GET SIGNED
      </Button>
    </>
  );
};

export default SellerReview;
