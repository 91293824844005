export const data = [
  {
    title: 'Capital Structure',
    text:
      'The capital structure is the particular combination of debt and equity used by a company to finance its overall operations and growth. Debt comes in the form of bond issues or loans, while equity may come in the form of common stock, preferred stock, or retained earnings. Short-term debt such as working capital requirements is also considered to be part of the capital structure.',
  },
  {
    title: 'Equity',
    text:
      "Equity is typically referred to as shareholder equity which represents the amount of money that would be returned to a company’s shareholders if all of the assets were liquidated and all of the company's debt was paid off.Equity is found on a company's balance sheet and is one of the most common financial metrics employed by analysts to assess the financial health of a company. Shareholder equity can also represent the book value of a company.",
  },
  {
    title: 'Debt',
    text:
      'Debt is an amount of money borrowed by one party from another. Debt is used by many corporations and individuals as a method of making large purchases that they could not afford under normal circumstances. A debt arrangement gives the borrowing party permission to borrow money under the condition that it is to be paid back at a later date, usually with interest.',
  },
  {
    title: 'Enterprise Value',
    text:
      "Enterprise value (EV) is a measure of a company's total value, often used as a more comprehensive alternative to equity market capitalization. EV includes in its calculation the market capitalization of a company but also short-term and long-term debt as well as any cash on the company's balance sheet. Enterprise value is a popular metric used to value a company for a potential takeover.",
  },
  {
    title: 'Equity value',
    text:
      'Equity value is the value of a company available to owners or shareholders. It is the enterprise value plus all cash and cash equivalents, short and long-term investments, and less all short-term debt, long-term debt and minority interests.',
  },
  {
    title: 'Market capitalization',
    text:
      "Market capitalization, commonly called market cap, is the market value of a publicly traded company's outstanding shares. Market capitalization is equal to the share price multiplied by the number of shares outstanding.\nImportant: Equity value and market capitalization are often considered similar and even used interchangeably, but there is a key difference: market capitalization only considers the value of the company's common shares.",
  },
  {
    title: 'Net debt',
    text:
      'Net debt is a liquidity metric used to determine how well a company can pay all of its debts if they were due immediately. Net debt shows much debt a company has on its balance sheet compared to its liquid assets. Net debt shows how much cash would remain if all debts were paid off and if a company has enough liquidity to meet its debt obligations.',
  },
  {
    title: 'P&L',
    text:
      "The profit and loss (P&L) statement is a financial statement that summarizes the revenues, costs, and expenses incurred during a specified period, usually a fiscal quarter or year. The P&L statement is synonymous with the income statement. These records provide information about a company's ability or inability to generate profit by increasing revenue, reducing costs, or both. Some refer to the P&L statement as a statement of profit and loss, income statement, statement of operations, statement of financial results or income, earnings statement or expense statement.",
  },
  {
    title: 'Net profit',
    text:
      "Net profit represents the number of sales dollars remaining after all operating expenses, interest, taxes and preferred stock dividends (but not common stock dividends) have been deducted from a company's total revenue.",
  },
  {
    title: 'Earnings',
    text:
      "Earnings typically refer to after-tax net income, sometimes known as the bottom line or a company's profits. Earnings are the main determinant of a company's share price, because earnings and the circumstances relating to them can indicate whether the business will be profitable and successful in the long run. Earnings are perhaps the single most important and most studied number in a company's financial statements. It shows profitability compared to analyst estimates, the company's own historical performance, and relative to its competitors and industry peers.",
  },
  {
    title: 'EBITDA',
    text:
      "A company's earnings before interest, taxes, depreciation, and amortization is an accounting measure calculated using a company's earnings, before interest expenses, taxes, depreciation, and amortization are subtracted, as a proxy for a company's current operating profitability. The earnings, tax, and interest figures are found on the income statement, while the depreciation and amortization figures are normally found in the notes to operating profit or on the cash flow statement.",
  },
  {
    title: 'Precedent transactions',
    text:
      'Precedent transaction analysis is a valuation method in which the price paid for similar companies in the past is considered an indicator of a company’s value. Precedent transaction analysis creates an estimate of what a share of stock would be worth in the case of an acquisition. It is also known as "M&A comps."',
  },
  {
    title: 'Comparable companies',
    text:
      'A comparable company analysis (CCA) is a process used to evaluate the value of a company using the metrics of other businesses of similar size in the same industry. Comparable company analysis operates under the assumption that similar companies will have similar valuation multiples such as EV/EBITDA. Analysts compile a list of the available statistics for the companies being reviewed and calculate the valuation multiples in order to compare them.',
  },
  {
    title: 'Trading',
    text:
      "Trading implies active participation in the financial markets as opposed to investing, which suggests a buy-and-hold strategy. Trading success depends on a trader's ability to be profitable over time. A trader is an individual who engages in the buying and selling of financial assets in any financial market, either for himself or on behalf of another person or institution. The main difference between a trader and an investor is the duration for which the person holds the asset. Investors tend to have a longer-term time horizon, while traders tend to hold assets for shorter periods of time to capitalize on short-term trends.",
  },
  {
    title: 'Price/Earning multiple',
    text:
      "The price earnings multiple compares the earnings per share reported by a company to the market price of its common stock. This multiple is used by investors to judge how expensive a share of the company's stock is.",
  },
  {
    title: 'DCF',
    text:
      'Discounted cash flow (DCF) is a valuation method used to estimate the value of an investment based on its future cash flows. DCF analysis attempts to figure out the value of a company today, based on projections of how much money it will generate in the future. DCF analysis finds the present value of expected future cash flows using a discount rate. A present value estimate is then used to evaluate a potential investment. If the value calculated through DCF is higher than the current cost of the investment, the opportunity should be considered.',
  },
  {
    title: 'IPO',
    text:
      'An initial public offering (IPO) refers to the process of offering shares of a private corporation to the public in a new stock issuance. Public share issuance allows a company to raise capital from public investors. The transition from a private to a public company can be an important time for private investors to fully realize gains from their investment as it typically includes share premiums for current private investors. Meanwhile, it also allows public investors to participate in the offering. A company planning an IPO will typically select an underwriter or underwriters. They will also choose an exchange in which the shares will be issued and subsequently traded publicly.',
  },
  {
    title: 'Secondary offering',
    text:
      'A secondary offering is the sale of new or closely held shares by a company that has already made an initial public offering (IPO). There are two types of secondary offerings. A non-dilutive secondary offering is a sale of securities in which one or more major stockholders in a company sell all or a large portion of their holdings. The proceeds from this sale are paid to the stockholders that sell their shares. Meanwhile, a dilutive secondary offering involves creating new shares and offering them for public sale. Secondary offerings are sometimes referred to as follow-on offerings or follow-on public offers (FPOs).',
  },
  {
    title: 'NDA',
    text:
      'A non-disclosure agreement is a legally binding contract that establishes a confidential relationship. The party or parties signing the agreement agree that sensitive information they may obtain will not be made available to any others. Non-disclosure agreements are common for businesses entering into negotiations with other businesses. They allow the parties to share sensitive information without fear that it will end up in the hands of competitors. In this case, it may be called a mutual non-disclosure agreement.',
  },
  {
    title: 'Term sheet',
    text:
      'A term sheet is a nonbinding agreement setting forth the basic terms and conditions under which an investment will be made. It serves as a template to develop more detailed legally binding documents. Once the parties involved reach an agreement on the details laid out in the term sheet, a binding agreement or contract that conforms to the term sheet details is then drawn up. Valuation of the company, amount of investment, the percentage stake sought, voting rights, liquidation preference, anti-dilutive provisions, and investor commitment are some of the key terms that should be spelled out in the term sheet.',
  },
  {
    title: 'DD',
    text:
      'Due diligence (DD)  is an investigation or audit of a potential investment or product to confirm all facts, that might include the review of financial records. Due diligence refers to the research done before entering into an agreement or a financial transaction with another party. Investors perform due diligence before buying a security from a company. Due diligence can also refer to the investigation a seller performs on a buyer that might include whether the buyer has adequate resources to complete the purchase.\n',
  },
  {
    title: 'SPA',
    text:
      'A sales and purchase agreement (SPA) is a binding and legal contract between two parties that obligates a transaction between the two parties: the buyer and the seller. SPAs are typically used for real estate transactions, but they are found in all areas of business. The agreement finalizes the terms and conditions of the sale, and it is the culmination of negotiation between the buyer and the seller.',
  },
  {
    title: 'VDR',
    text:
      'A virtual data room (VDR), also known as a deal room, is a secure online repository for document storage and distribution. It is typically utilized during the due diligence process preceding a merger or acquisition to review, share, and disclose company documentation.',
  },
  {
    title: 'VDD',
    text:
      'Vendor due diligence is due diligence carried out at the expense of, and at the behest of, the seller, by independent third parties. This contrasts with due diligence carried out by the buyer. The biggest advantage of vendor due diligence is that is speeds up the sale process, and saves costs. It eliminates the duplication of due diligence work by multiple buyers, who want answers to many of the same questions. It also provides greater credibility to facts and Numbers that the seller presents to potential buyers, because they have been verified by impartial accountants and lawyers.',
  },
  {
    title: 'Closing Structure',
    text:
      "Closing structure and post-closing obligations in M&A transactions should be considered early on in the deal negotiation process, as they will heavily influence the provisions of the operative transaction agreement.Typical closing deliveries in an M&A transaction include: stock purchase agreement or the merger agreement, board and stockholder consents authorizing the transaction, legal opinions, consideration (e.g., stock or cash), regulatory approvals. The parties' obligations will often not end at closing. The seller is normally required to enter into a number of covenants restricting its conduct for a defined period of time after closing. These may include covenants not to compete with the target company or to hire the company's employees. Depending on the specifics of the transaction, the buyer may also be subject to post-closing covenants, such as a requirement to provide similar employee benefits for a period of time or to provide director and officer insurance and indemnification for outgoing directors and officers of the target company.",
  },
  {
    title: 'Regulatory approval',
    text:
      'Regulatory Approval means any and all approvals, licenses, registrations or authorizations of any national, supra-national (e.g., the European Commission or the Council of the European Union), regional, state or local regulatory agency, department, bureau, commission, council or other governmental entity, that are necessary for the completion of an M&A transaction. In many cases, amiable merger offers usually move somewhat quickly through the corporate communication phase but may be slowed for months or years in the regulatory approval phase. Generally, the amount of time required for regulatory approval will depend on the scope and size of a company’s operations. Companies that operate in multiple geographies must obtain regulatory approval from each nation’s government. The more countries of operation, the longer and more tedious this process can be.',
  },
  {
    title: 'Fairness opinion',
    text:
      'A fairness opinion is a report that evaluates the facts of a merger, acquisition, carve out, spin-off, buyback or another type of purchase and provides an opinion as to whether or not the proposed stock price is fair to the selling or target company. Fairness opinions are provided to the key decision makers in the target/selling company and are written by qualified analysts or advisors, usually of an investment bank. The analysts examine the specifics of the deal, including any possible business synergies that benefit the target/seller if applicable, the terms of the agreement, and the price offered for the stock of the target/seller. Fairness opinions are not always required in transactions involving public companies, but can be helpful in reducing the risk associated with a merger, acquisition, carve out, spin-off, buyback, or another type of purchase, including the risk of litigation. Private transactions may require a fairness opinion.',
  },
  {
    title: 'Football field',
    text:
      'A football field graph is a graph showing the valuation of a company according to different methodologies. Some of the methodologies used are: DCF, Public Comparables, Precedent Transactions. The graph will show the different mean valuations and multiples for the different methodologies and allow the person who is conducting the valuation (or most likely their MD) to decide which method to use primarily to achieve the best possible valuation. Companies are valued using a combination of multiples and future cash flows, and each of these can be taken in a best, worst and median case.',
  },
  {
    title: 'Excess cash',
    text:
      'Excess cash is the amount of cash beyond what the company needs to perform its daily operations. Excess cash is generated when total current non-cash assets fully cover total current liabilities.',
  },
];
