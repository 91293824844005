import React from 'react';
import styled from 'styled-components';

import { Form, Input, Button, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useAuthActions, useAuthState } from 'contexts';

const LoginForm: React.FC = () => {
  const [form] = Form.useForm();

  const { login, resetLoginError } = useAuthActions();
  const { isLoading, loginError } = useAuthState();

  return (
    <StyledForm
      form={form}
      onFinish={() => {
        const data = form.getFieldsValue();
        resetLoginError();
        login(data.username, data.password);
      }}
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item>
        <LoginButton type="primary" htmlType="submit" loading={isLoading}>
          Log in
        </LoginButton>
      </Form.Item>
      <Typography.Text style={{ color: 'red' }}>{loginError}</Typography.Text>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  width: 500px;
`;

const LoginButton = styled(Button)`
  width: 100%;
`;

export default LoginForm;
