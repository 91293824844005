import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { Loading } from 'components';
import { useAuthState } from 'contexts';
import { useSessionId, useCurrentStep } from 'hooks';
import {
  TemplateFieldsAttempt,
  useBilateralDoc,
  useSubmitTemplateFields,
} from './actions';

import type { Player, SelectFieldType, TemplateDocument } from 'types';
import { useSessionMember, useSubmitCurrentStep } from 'hooks/traineeActions';
import { useRetrieveCurrentStep } from 'hooks/trainerActions';

const BilateralDocsStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
  doc: TemplateDocument;
}> = ({ player, ownPerspective, doc }) => {
  const sessionId = useSessionId();
  const { data: member } = useSessionMember();
  const { data: currentStep, isLoading: isStepLoading } = useCurrentStep();

  const { mutate: submitTemplateFields } = useSubmitCurrentStep();

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [templateHtmlString, setTemplateHtmlString] = useState<string>('');

  useEffect(() => {
    setTemplateHtmlString(templateHTML());
  }, [doc, doc?.last_updated_by]);

  // Get select element id from generated html
  const fieldId = (selectField: SelectFieldType) =>
    `template-${selectField.token.replaceAll('%', '')}-${selectField.id}`;

  const getAnswerForSelectField = (selectFieldId: number) => {

    if (!doc || !doc?.last_updated_by || !doc?.answers) return null;

    const answer = doc.answers.find(
      (ans: any) => ans.select_field === selectFieldId
    );
    return answer ? answer.option_field : null;
  };

  // Generates select dropdown
  const generateDropdown: (selectField: SelectFieldType) => string = (
    selectField
  ) => {
    const selectedValue = getAnswerForSelectField(selectField.id);

    const options = selectField.options
      .map((option) => {
        const isSelected = option.id === selectedValue ? 'selected' : '';
        return `<option value="${option.id}" ${isSelected}>${option.value}</option>`;
      })
      .join('');

    return `<select ${doc?.last_updated_by ? 'disabled' : ''} name="${
      selectField.name
    }" class="template-select" id="${fieldId(selectField)}">
      ${options}
    </select>`;
  };

  const generateInput: (selectField: SelectFieldType) => string = (
    selectField
  ) => {
    const inputValue = getAnswerForSelectField(selectField.id) || '';

    return `
      <input ${
        doc?.last_updated_by ? 'disabled' : ''
      } class="template-select short" value="${inputValue}" id="${fieldId(
      selectField
    )}"> %
    `;
  };

  // Generates placeholder for duplicate tokens found
  const generatePlaceholder: (selectField: SelectFieldType) => string = (
    selectField
  ) => {
    return `<span class="template-placeholder">${selectField.name}</span>`;
  };

  const templateHTML = () => {
    if (!doc) {
      return '';
    }

    let result = doc.simulation_template;
    // Replace first occurrence with dropdown
    doc.select_fields.forEach((selectField: any) => {
      if (selectField.token === '%percentage%')
        result = result.replace(selectField.token, generateInput(selectField));
      else
        result = result.replace(
          selectField.token,
          generateDropdown(selectField)
        );
    });
    // Replace other occurrences with field name
    doc.select_fields.forEach((selectField: any) => {
      result = result.replaceAll(
        selectField.token,
        generatePlaceholder(selectField)
      );
    });

    return result;
  };

  // Checks if document has rendered correctly
  const dropdownsAreGenerated = () => {
    if (!doc) {
      return false;
    }

    doc.select_fields.forEach((selectField: any) => {
      const id = fieldId(selectField);
      const element = document.getElementById(id) as HTMLSelectElement;
      if (!element) {
        return false;
      }
    });

    return true;
  };

  const submitForm = () => {
    if (!doc || !currentStep) return;
    const answers = [] as Array<TemplateFieldsAttempt>;
    doc.select_fields.forEach((selectField: any) => {
      const id = fieldId(selectField);
      const element = document.getElementById(id) as HTMLSelectElement;
      const value = parseInt(element.value);

      answers.push({
        select_field: selectField.id,
        option_field: value,
      });
    });

    if (answers.length) {
      submitTemplateFields({
        answers,
        member: member?.id || 0,
        session_id: sessionId,
        template: doc.id,
      });
    }
  };

  if (isStepLoading) {
    return <Loading />;
  }

  return (
    <div>
      <BilateralDoc
        dangerouslySetInnerHTML={{
          __html: templateHtmlString,
        }}
      />
      {/* {
        doc?.last_updated_by && 
        (
          <>
          <Divider />

          <Divider />
          </>
        )
      } */}

      {ownPerspective && (
        <AlignRight>
          <Button
            type="primary"
            disabled={!currentStep || isStepLoading || !!doc?.last_updated_by}
            onClick={() => {
              setSubmitted(true);
              submitForm();
            }}
          >
            <SendOutlined />
            Submit
          </Button>
        </AlignRight>
      )}
    </div>
  );
};

const BilateralDoc = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  .template-select {
    border: 2px solid #1890ff;
    border-radius: 5px;
    padding: 5px 7px;
    margin: 0 5px;
  }

  .template-placeholder {
    display: inline-block;
    border: 2px solid #fadb14;
    border-radius: 5px;
    padding: 0 5px;
  }

  .short {
    max-width: 50px;
  }
`;

const AlignRight = styled.div`
  text-align: right;
`;

export default BilateralDocsStep;
