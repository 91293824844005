import { useAuthQuery } from 'hooks';
import type { Glossary } from 'types';

const useGlossary = () => {
    // If the glossary is not dependent on the session id, you can omit it
    return useAuthQuery<Glossary>(['glossary'], {
        // You can pass options here if necessary, like staleTime, cacheTime, etc.
    });
};

export default useGlossary;