import React from 'react';
import { Divider, Typography } from 'antd';


const PreEliminationStep: React.FC = () => {
    return (
        <>
            <Typography.Title level={4}>
                Each Team is now Reading a set of instructions just received from its own Board. 
                The negotiating Parties are still far from one another but they nonetheless should still 
                aim to inch closer to Signing.
            </Typography.Title>
            <Divider/>
            <Typography.Title level={4}>
                Before face - to - face negotiation, the teams are expected to take notes regarding the details of their Board 
                instructions. 
            </Typography.Title>
            <Divider/>
            <Typography.Title level={4}>
                Please ask the Buyers to sign up for negotiations and allow them to proceed, one by one for
                meetings with the Seller team. For this round there will be just one meeting between the Seller 
                team and each of the Buyer teams, each meeting limited to 4 minutes.
                When they complete the negotiation, Buyer teams  are expected to return to their laptops and readjust their Offers.
            </Typography.Title>
            <Divider/>
            <Typography.Title level={4}>
                For them to enter their updated offers you will need to click the next Button.
            </Typography.Title>
        </>


    )
}

export default PreEliminationStep;