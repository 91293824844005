import React, { useState } from 'react';
import { Typography, Divider, List, Button } from 'antd';
import { ListItem } from 'components';
import NegotationPromt from './NegotiationPrompt';

const { Text, Title } = Typography;

const SellerView: React.FC = () => {
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Title level={4}>
        YOUR TEAM SHOULD TAKE NOTES: The Board has advised you to accept nothing
        less than the following terms for an Offer (make sure you retain this
        info confidentially).
        <ul>
          <li>
            A share-exchange ratio of 1.8 AMD shares for every XILINX share.
          </li>

          <li>
            Confirmation of at least $300 million achieved in synergies in 18
            months
          </li>

          <li>
            A break-up fee of $2bn payable by AMD if its Board decides to
            walk-away
          </li>

          <li>
            A break-up fee of $2 bn payable by AMD if there is no regulatory
            approval in due time
          </li>

          <li>
            A break-up fee of $1 bn payable by Xilinx if its Board decides to
            walk-away
          </li>

          <li>
            Victor Peng becomes Head of Adaptive and Embedded Computing Group
            reporting directly to Dr. Su
          </li>
        </ul>
      </Title>
      <Divider />
      <Title level={4}>
        If the Buyer is oferring any monetary value other than your two key
        criteria (share ratio and break-up fee) try to get them to convert into
        one or both of those two key criteria you are seeking. You don’t have
        pension liabilities, you don’t need to pay any part of your debt so
        there is nothing you can do with their extra offers.
      </Title>
      <Divider />
      <Title level={4}>
        PLEASE MAKE SURE YOU RETAIN THE BOARD INSTUCTIONS DOWN ON A PIECE OF
        PAPER FOR FUTURE REFERENCE
      </Title>

      <Button
        type="primary"
        style={{ marginTop: '2rem', float: 'right' }}
        onClick={() => setClicked(true)}
        disabled={clicked}
      >
        Click to flag readiness for face-to-face Negotiations
      </Button>
      {/* <NegotationPromt /> */}
    </>
  );
};

export default SellerView;
