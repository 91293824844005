import { useCurrentStep, useSessionId } from 'hooks';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Player } from 'types';

const ReadingsInstructionsStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
}> = ({ player, ownPerspective }) => {
  const { data, isLoading } = useCurrentStep();
  const id = useSessionId();
  const history = useHistory();

  // if (data?.type === 1) {
  //   history.push(`sessions/${id}/readings`);
  // }

  return <></>;
};

export default ReadingsInstructionsStep;
