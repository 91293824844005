import React from 'react';

import { Space } from 'antd';
import TeamType from './TeamType';

const SessionControls: React.FC = () => {
  return (
    <Space>
      <TeamType />
    </Space>
  );
};

export default SessionControls;
