/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { UserOutlined } from '@ant-design/icons/lib';
import { Layout, Menu, Row, Col } from 'antd';

import logo from 'assets/logo.svg';
// import { useModal } from 'hooks';
// import ChangePasswordModal from './ChangePasswordModal';
import type { Route } from 'types';
import { useAuthActions, useAuthState } from 'contexts';
import { TRAINEE } from 'stateConstants';

type Props = {
  routes: Route[];
};

const Navigation: React.FC<Props> = ({ routes }) => {
  const { logout, setShowPersonModal } = useAuthActions();
  const { username, person, userType } = useAuthState();

  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  // const [UpdateModal, showUpdateModal] = useModal(ChangePasswordModal);

  const MenuItem = (route: Route) => (
    <Menu.Item key={route.path}>
      <Link to={`${url}${route.path}`}>{route.name}</Link>
    </Menu.Item>
  );

  const Profile = (
    <>
      <ProfileSubMenu
        title={
          <>
            {userType === TRAINEE ? (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                onClick={() => {
                  setShowPersonModal(true);
                }}
              >
                <UserOutlined /> {person?.name || username}, team: {username}
              </div>
            ) : (
              <div>
                <UserOutlined /> {person?.name || username}
              </div>
            )}
            {/* <UserOutlined /> {person?.name || username} */}
          </>
        }
      >
        {/* <Menu.Item onClick={showUpdateModal}>Change password</Menu.Item> */}
        {/* <Menu.Item onClick={() => setPerson(undefined)}>Change User</Menu.Item> */}
        <Menu.Item onClick={logout}>Logout</Menu.Item>
      </ProfileSubMenu>
    </>
  );

  return (
    <Layout.Header>
      <Row>
        <Col>
          <Logo src={logo} alt="Traderion Logo" />
        </Col>
        <Col flex={1}>
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={pathname.split('/').map((s) => '/' + s)}
          >
            {routes.map(MenuItem)}
            <RightSide>{Profile}</RightSide>
          </Menu>
        </Col>
      </Row>
      {/* {UpdateModal} */}
    </Layout.Header>
  );
};

const ProfileSubMenu = styled(Menu.SubMenu)`
  float: right;
`;

const RightMenu = styled(Menu.Item)`
  float: right;
`;

const RightSide = styled.div`
  margin-left: auto;
  float: right;
`;

const Logo = styled.img`
  padding: 0 20px;
`;

export default Navigation;
