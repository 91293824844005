import { useMutation, useQueryClient } from 'react-query';

import { useAuthQuery, useSessionId } from 'hooks';
import { createPostRequest } from 'services';

import type { QuizAnswers, QuizPlayer } from 'types';
import { notification } from 'antd';
import { AssingTablePlayer } from 'types';

export const useQuizPlayers = () => {
  const id = useSessionId();
  return useAuthQuery<QuizPlayer[]>(['sessions', id, 'quiz-players']);
};

export const usePlayersAssignTable = () => {
  const id = useSessionId();
  return useAuthQuery<AssingTablePlayer[]>(['sessions', id, 'assign-players']);
};

export const useArePlayersAssigned = () => {
  const id = useSessionId();
  return useAuthQuery<boolean>(['sessions', id, 'are-players-assigned']).data;
};

const assignPlayersWithoutQuiz = createPostRequest<
  { session_id: number; seller_player_id: number },
  unknown
>(`sessions/assign_players_without_quiz/`, 'post');

export const useAssignPlayersWithoutQuiz = (sessionId: number) => {
  const queryClient = useQueryClient();

  return useMutation(assignPlayersWithoutQuiz, {
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data || 'An unexpected error occurred';
      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['sessions', sessionId, 'assign-players']);
      queryClient.invalidateQueries([
        'sessions',
        sessionId,
        'are-players-assigned',
      ]);
    },
  });
};

export const assignPlayers = createPostRequest<
  { session_id: number },
  QuizAnswers
>('quizzes/assign-players/', 'post');
export const useAssignPlayers = () => {
  const id = useSessionId();
  const queryClient = useQueryClient();
  return useMutation(() => assignPlayers({ session_id: id }), {
    onSettled: () => {
      queryClient.invalidateQueries(['sessions', id, 'players']);
      queryClient.invalidateQueries(['sessions', id, 'quiz-players']);
    },
  });
};
