import React from 'react';

// import { Typography } from 'antd';

import { useSession } from 'hooks';
import StatusTag from '../StatusTag';

const SessionState: React.FC = () => {
  const { data } = useSession();

  if (!data) return null;

  return (
    <div>
      {/* <Typography.Text type="secondary">{'Status: '}</Typography.Text> */}
      <StatusTag status={data.status} />
    </div>
  );
};

export default SessionState;
