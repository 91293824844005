import React from 'react';

import { Button, InputNumber, List, Modal, Typography } from 'antd';
import {
  DeleteOutlined,
  SaveOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import { ListItem } from 'components';
import useReviewProposal from './useReviewProposal';
import { useUpdateBuyerProposal } from './actions';
import { useEditCurrentStep } from 'hooks/traineeActions';

const { Paragraph } = Typography;

type Props = {
  hide: () => void;
};

const ProposalModal: React.FC<Props> = ({ hide }) => {
  const { proposal, getProps, resetFromQuery } = useReviewProposal();
  const { mutate: updateProposal } = useEditCurrentStep();

  return (
    <Modal
      title="Edit Proposal"
      width={700}
      visible
      onCancel={hide}
      footer={
        <Footer>
          <Button
            type="primary"
            onClick={() => {
              updateProposal(proposal);
              hide();
            }}
          >
            <SaveOutlined />
            Save
          </Button>
          <Button type="dashed" danger onClick={resetFromQuery}>
            <DeleteOutlined />
            Revert all values
          </Button>
          {/* <Button type="primary" danger onClick={hide}>
            <CloseSquareOutlined />
            Close
          </Button> */}
        </Footer>
      }
    >
      <Paragraph>
        Any changes can be made by adjusting the values below:
      </Paragraph>
      <List>
        <ListItem>
          Cash in the amount of <InputNumber {...getProps('cash_amount')} />{' '}
          million USD
        </ListItem>
        <ListItem>
          Stock-for-stock exchange at a rate of{' '}
          <InputNumber {...getProps('stock_exchange_rate')} /> shares of the
          Buyer for every outstanding share of the Target upon closing and cash
          in lieu of fractional shares for a total stock consideration in the
          amount of <InputNumber {...getProps('cash_in_lieu')} /> million USD
        </ListItem>
        <ListItem>
          Deferred cash consideration in the amount{' '}
          <InputNumber {...getProps('deferred_cash')} /> of million USD
        </ListItem>
        <ListItem>
          Assumption of Pension liabilities in the amount of{' '}
          <InputNumber {...getProps('pension_liabilities')} /> million USD
        </ListItem>
        <ListItem>
          Assumption of existing Target debt in the amount of{' '}
          <InputNumber {...getProps('target_dept')} /> million USD
        </ListItem>
        <ListItem>
          Also, the Buyer offers a definitive amount of{' '}
          <InputNumber {...getProps('breakup_fee')} /> million USD as a Break-Up
          fee if proposed transaction fails
        </ListItem>
      </List>
    </Modal>
  );
};

const Footer = styled.div`
  display: flex;
  justify-content: right;
`;

export default ProposalModal;
