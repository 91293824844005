const computeFunction = (fn: (...args: number[]) => number) => {
  function checkNumbers(...numbers: unknown[]) {
    return Array.from(numbers).reduce(
      (acc, val) => acc && typeof val === 'number',
      true
    );
  }

  return function (...numbers: unknown[]) {
    const args = Array.from(numbers);
    if (!checkNumbers(...args)) {
      return;
    }

    return fn(...(args as number[]));
  };
};

export default computeFunction;
