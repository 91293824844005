import React, { useState } from 'react';

import { Table, Button, Divider, Typography } from 'antd';

import { PLAYER_TYPES, UNASSIGNED } from 'stateConstants';
import { Loading, Space } from 'components';
import { useAssignPlayers, useQuizPlayers } from './actions';
import Counter from 'modules/Counter';

const ampersant = '&';

const QuizStep: React.FC = () => {
  const { data: players, isLoading } = useQuizPlayers();
  const { mutate: assignPlayers } = useAssignPlayers();
  const [playersAssigned, setPlayersAssigned] = useState(false);

  if (isLoading) {
    return <Loading />;
  }

  const dataSource = (players || []).sort((a, b) =>
    a.username.localeCompare(b.username)
  );

  return (
    <>
      <Typography.Title level={4}>
        {players && players[0].type === UNASSIGNED
          ?
          'All participants are now taking a quiz. The table below shows the number of members on each team versus their quiz submissions. ' +
          'If those numbers are equal, all members of the team have completed the quiz. The team with the highest score will become the Seller Team. ' +
          'This should take about 4 minutes and once the countdown reaches 00:00, you can ask Participants to rush their submissions, but having all submissions is not a requirement. ' +
          'To end the quizz, you can click the blue button called <<End quiz & Assign Teams>> at the bottom of this screen. '
          : 'Teams have now been assigned Buyer / Seller roles. It might make sense to ask people on the same team to sit closer together. When ready, click the Next button above to proceed to the next step in the Simulation'}
      </Typography.Title>
      <Counter totalTime={240} />
      <Divider />
      <Space direction="vertical" size={32}>
        <Table
          pagination={false}
          rowKey="id"
          columns={[
            { title: 'Team', dataIndex: 'username' },
            { title: 'Member count', dataIndex: 'person_count' },
            {
              title: 'Quiz Submissions',
              dataIndex: 'quiz_attempt_count',
            },
            { title: 'Total Team Score', dataIndex: 'quiz_score' },
            {
              title: 'Type',
              dataIndex: 'type',
              render(value: keyof typeof PLAYER_TYPES) {
                return PLAYER_TYPES[value];
              },
            },
          ]}
          dataSource={dataSource}
        />
        <Button
          type="primary"
          disabled={
            playersAssigned || (players && players[0].type !== UNASSIGNED)
          }
          onClick={() => {
            assignPlayers();
            setPlayersAssigned(true);
          }}
        >
          End quiz {ampersant} Assign Teams
        </Button>
      </Space>
    </>
  );
};

export default QuizStep;
