import React from 'react';

import { RelativeSwitch } from 'components/Routing';

import { useAuthState } from 'contexts';
import PageLayout from 'components/PageLayout';
import PersonSelector from 'components/PersonSelector';

import routes from './routes';

const TraineeDashboard: React.FC = () => {
  const { person } = useAuthState();

  return (
    <PageLayout routes={routes}>
      <PersonSelector />
      {person && <RelativeSwitch routes={routes} />}
    </PageLayout>
  );
};

export default TraineeDashboard;
