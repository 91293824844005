import React, { useEffect } from 'react';
import { Button, InputNumber, List, Typography, Divider, Space } from 'antd';
import styled from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';

import { ListItem, Loading } from 'components';
import {
  useSessionMember,
  useSessionPlayer,
  useSubmitCurrentStep,
} from 'hooks/traineeActions';
import {
  // useCreateEliminationOffer,
  useOfferElimination,
  useRetrieveOffer,
} from './actions';
import { useAuthState } from 'contexts';
import { Offer, Player } from 'types';
import useOfferFields from 'hooks/useOfferFields';
import { useSessionId } from 'hooks';
import GlossaryWrappedText from 'modules/TraineeSession/Glossary/GlossaryWrappedText';
import useOfferUpToDate from 'hooks/useOfferUpToDate';

const { Title } = Typography;

const offerStatusMessage = (offer: Offer, player: Player) => {
  if (player.passed_elimination === 0) {
    return (
      <div>
        <Title level={4}>
          The Seller is now assessing your Offer and will reply swiftly.
        </Title>
        <Title level={4}>Please stand-by</Title>
      </div>
    );
  }

  if (player.passed_elimination === 1) {
    return (
      <div style={{ color: 'red' }}>
        <Title level={4}>
          Unfortunately, your Team has been removed from the Transaction by the
          Seller for having an insufficiently appealing offer.
        </Title>
        <Title level={4}>
          This judgment may be superficial, but it is nonetheless final.
        </Title>
        <Title level={4}>
          All M&A transactions processes that involve multiple bidders have such
          an outcome for all but Buyer per transaction.
        </Title>
        <Title level={4}>
          We thank you for your participation, and we wish you good luck in your
          career!
        </Title>
      </div>
    );
  }
  if (player.passed_elimination === 2) {
    return (
      <div>
        <Title level={4}>
          Congratulations! Your offer was accepted by the Seller. The next FTF
          negotiation step will begin momentarly.
        </Title>
      </div>
    );
  }
};

const BuyerElimination: React.FC<{
  player: Player;
  offer: Offer;
  ownPerspective: boolean;
}> = ({ player, offer, ownPerspective }) => {
  const {
    getProps,
    offerFields,
    updateOffer: updateOfferFields,
  } = useOfferFields();
  const { mutate: putOffer } = useSubmitCurrentStep();
  const offerUpToDate = useOfferUpToDate(offer);

  useEffect(() => {
    if (offer) {
      updateOfferFields(offer);
    }
  }, [offer]);

  if (!player) {
    return <Loading />;
  }

  if (!offer) {
    return <Space>You did not submit any proposal.</Space>;
  }

  if (player.passed_elimination !== 0)
    return (
      <>
        <Divider />
        {offerStatusMessage(offer, player)}
        <Divider />
      </>
    );

  return (
    <Wrapper>
      <GlossaryWrappedText
        text={`
      Following your discussion with the Seller, you can now modify and update
      the terms of your offer, which should be in line with your Board’s
      instructions. You shall complete this step in up to 3 minutes. Once
      ready, resubmit your Offer.
      `}
        WrapperComponent={Title}
        wrapperProps={{ level: 4 }}
      />
      <Title level={4}>
        ONLY ONE PERSON PER TEAM SHOULD ENTER YOUR OFFER’s DATA.
      </Title>
      {offer && offerUpToDate && (
        <Title level={2}>
          Offer submitted by {offer.last_updated_by?.name}:
        </Title>
      )}
      <Divider />
      <List>
        <ListItem>
          Cash in the amount of <InputNumber {...getProps('cash_amount')} />{' '}
          million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Stock-for-stock exchange at a rate of
            "
          />
          <InputNumber {...getProps('stock_exchange_rate')} />
          <GlossaryWrappedText
            text="
            shares of the
          Buyer for every outstanding share of the Target upon closing and cash
          in lieu of fractional shares for a total stock consideration in the
          amount of 
            "
          />
          <InputNumber {...getProps('cash_in_lieu')} /> million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Deferred cash consideration in the amount
            "
          />
          <InputNumber {...getProps('deferred_cash')} /> of million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Assumption of Pension liabilities in the amount of{' '}
            "
          />
          <InputNumber {...getProps('pension_liabilities')} /> million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Assumption of existing Target debt in the amount of{' '}
            "
          />
          <InputNumber {...getProps('target_dept')} /> million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText text="Also, the Buyer offers a definitive amount of" />
          (" ")
          <InputNumber {...getProps('breakup_fee')} />
          <GlossaryWrappedText
            text="
          million USD as a Break-Up fee if proposed transaction fails
          "
          />
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="At this point, you may also require the Seller to commit to a Break-Up
          Fee in the event of its Board deciding to walk awav from the
          Transaction between Signing and Closing. If you decide to do so, the
          amount you desire to receive as a Break-Up fee is"
          />
          <InputNumber {...getProps('seller_breakup_fee')} /> million USD
        </ListItem>
      </List>

      {ownPerspective && (
        <Footer>
          <Button
            type="primary"
            onClick={() => {
              putOffer({
                ...offerFields,
                tier: 2,
              });
              // alert('not implemented');
            }}
            disabled={offerUpToDate}
          >
            <ArrowRightOutlined />
            Submit
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: right;
`;

export default BuyerElimination;
