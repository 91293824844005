import React from 'react';

import styled from 'styled-components';
import { TagOutlined } from '@ant-design/icons';
import { Card, Checkbox, Typography, Row, Col } from 'antd';

import type { ValuationDocument } from 'types';

type Props = {
  doc: ValuationDocument;
  selected: boolean;
};

const DocumentCard: React.FC<Props> = ({ doc, selected }) => {
  const CardTitle = () => {
    return (
      <Row>
        <Col flex="1">
          <TagOutlined /> {doc.price}
        </Col>
        <Col>
          <Checkbox checked={selected} />
        </Col>
      </Row>
    );
  };
  return (
    <CustomCard title={<CardTitle />}>
      <div>{doc.name}</div>
      <div>
        {doc.is_mandatory && (
          <RedText>
            <sup>*</sup>Required
          </RedText>
        )}
      </div>
    </CustomCard>
  );
};

const CustomCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .ant-card-body {
    flex: 1;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const RedText = styled(Typography.Paragraph)`
  color: #f5222d;
`;

export default DocumentCard;
