import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Table, TableProps } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons/lib';
import StatusTag from './StatusTag';

import { Session } from '../types';

const GenericSessionTable: React.FC<TableProps<Session>> = (props) => {
  return (
    <Table
      rowKey="id"
      pagination={false}
      columns={[
        { title: 'Id', dataIndex: 'id' },
        { title: 'Class', dataIndex: 'klass' },
        { title: 'Scenario', dataIndex: 'scenario' },
        {
          title: 'Date created',
          dataIndex: 'created',
          render: (_, record) => {
            return <div>{new Date(record.created).toLocaleString()}</div>;
          },
        },
        {
          title: 'Status',
          render: (_, record) => <StatusTag status={record.status} />,
        },
        {
          title: 'Actions',
          render: (record) => (
            <Link to={`/sessions/${record.id}`}>
              <Button type="primary">
                <PlayCircleOutlined /> Join
              </Button>
            </Link>
          ),
        },
      ]}
      {...props}
    />
  );
};

export default GenericSessionTable;
