import React from 'react';

import { InputNumber, Table, Typography } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

import { formatters } from 'services';
import { useDCFFProvider } from 'contexts';
import type { YearlyStructure, YearlyStructureDef } from 'types';

type DataRow = {
  name: string;
  field: keyof YearlyStructureDef;
  is_negative?: boolean;
};

const FieldInputNumber: React.FC<
  InputNumberProps & {
    col: keyof YearlyStructure;
    field: keyof YearlyStructureDef;
    is_percent?: boolean;
    is_negative?: boolean;
  }
> = (props) => {
  const { col, field, is_percent, is_negative } = props;
  const { yearlyInputs, setYearlyInputs } = useDCFFProvider();

  const setYearlyTableField = (value?: number | string) => {
    const newValue =
      is_percent && typeof value === 'number' ? value / 100 : value;
    setYearlyInputs({
      ...yearlyInputs,
      [col]: { ...yearlyInputs[col], [field]: newValue },
    });
  };

  const value = (yearlyInputs[col] as any)[field];

  return (
    <InputNumber
      controls={false}
      min={is_negative ? undefined : 0}
      value={value}
      onChange={setYearlyTableField}
      {...props}
    />
  );
};

const YearlyTable: React.FC<{ submitted: boolean }> = ({ submitted }) => {
  const { yearlyData } = useDCFFProvider();

  const renderColumn = (col: keyof YearlyStructure, row: DataRow) => {
    const { field, is_negative } = row;

    const inputProps = { col, field, is_negative };

    switch (field) {
      case 'mid_range_revenues':
      case 'ebitda': {
        if (col === 'tv') {
          return;
        }
        return <FieldInputNumber disabled={submitted} {...inputProps} />;
      }

      case 'ebitda_margin': {
        if (col === 'tv') {
          return null;
        }
        return formatters.percent2Digits(yearlyData[col][field]);
      }

      case 'proforma_ebitda': {
        if (col !== 'tv') {
          return formatters.commas2Digits(yearlyData[col][field]);
        }
        return <FieldInputNumber disabled={submitted} {...inputProps} />;
      }

      case 'depreciation':
      case 'amortization':
        return <FieldInputNumber disabled={submitted} {...inputProps} />;

      case 'effective_tax_rate':
        return (
          <FieldInputNumber
            disabled={submitted}
            {...inputProps}
            is_percent
            formatter={formatters.percent2Digits}
          />
        );

      case 'noplat':
        return formatters.commas2Digits(yearlyData[col][field]);

      case 'net_wc_investment':
      case 'net_capex_investment':
      case 'net_gw_ai':
        return <FieldInputNumber disabled={submitted} {...inputProps} />;

      case 'unlevered_fcff':
        return formatters.commas2Digits(yearlyData[col][field]);

      case 'discount_period':
        if (col === 'year1') {
          return <FieldInputNumber disabled={submitted} {...inputProps} />;
        }
        return yearlyData[col][field];

      case 'discount_factor':
        return formatters.commas2Digits(yearlyData[col][field]);

      case 'dcff':
        return formatters.commas2Digits(yearlyData[col][field]);
    }
  };

  const dataSource: DataRow[] = [
    { name: 'Mid-range Revenues', field: 'mid_range_revenues' },
    { name: 'EBITDA', field: 'ebitda' },
    { name: 'EBITDA Margin', field: 'ebitda_margin' },
    { name: 'Proforma EBITDA', field: 'proforma_ebitda' },
    { name: 'Depreciation', field: 'depreciation' },
    { name: 'Amortization', field: 'amortization' },
    { name: 'Effective tax rate', field: 'effective_tax_rate' },
    { name: 'NOPLAT', field: 'noplat' },
    {
      name: 'Net WC investment',
      field: 'net_wc_investment',
      is_negative: true,
    },
    {
      name: 'Net CapEx investment',
      field: 'net_capex_investment',
      is_negative: true,
    },
    {
      name: 'Net Goodwill and Acquired Intangibles',
      field: 'net_gw_ai',
      is_negative: true,
    },
    { name: 'Unlevered FCFF', field: 'unlevered_fcff' },
    { name: 'Discount period', field: 'discount_period' },
    { name: 'Discount factor', field: 'discount_factor' },
    { name: 'DCFF', field: 'dcff' },
  ];

  return (
    <Table
      rowKey="field"
      dataSource={dataSource}
      columns={[
        { title: 'Name', dataIndex: 'name' },
        {
          title: '',
          dataIndex: 'is_negative',
          render: (value) =>
            value ? (
              <Typography.Text style={{ color: '#f5222d' }}>
                Can be negative
              </Typography.Text>
            ) : null,
        },
        {
          title: '2021 (est.)',
          render: (value, record) => renderColumn('year1', record as DataRow),
        },
        {
          title: '2022 (est.)',
          render: (value, record) => renderColumn('year2', record as DataRow),
        },
        {
          title: '2023 (est.)',
          render: (value, record) => renderColumn('year3', record as DataRow),
        },
        {
          title: 'TV',
          render: (value, record) => renderColumn('tv', record as DataRow),
        },
      ]}
      pagination={false}
    />
  );
};

// const SlimTable = styled(Table)`
//   & .ant-table-cell {
//     padding: 10px;
//   }
// `;

export default YearlyTable;
