import type { Route } from 'types';

import Simulation from './Simulation';
import Glossary from './Glossary';
import Readings from './Readings';

const routes: Route[] = [
  { name: 'Simulation', path: '/simulation', component: Simulation },
  { name: 'Readings', path: '/readings', component: Readings },
  { name: 'Glossary', path: '/glossary', component: Glossary },
];

export default routes;
