import React, { useState } from 'react';
import { Typography, Divider, Button } from 'antd';
import NegotationPromt from './NegotiationPrompt';
import { useAuthState } from 'contexts';

const { Paragraph, Title } = Typography;

const offerCannotExceed: { [key: string]: number } = {
  Blue: 1.7,
  Red: 1.701,
  Green: 1.702,
  Yellow: 1.703,
  Orange: 1.704,
  Violet: 1.705,
};

const break_up_limit: { [key: string]: number } = {
  Blue: 1.25,
  Red: 1.2,
  Green: 1.15,
  Yellow: 1.1,
  Orange: 1.05,
  Violet: 1,
};

const pension_limit: { [key: string]: number } = {
  Blue: 50,
  Red: 100,
  Green: 150,
  Yellow: 200,
  Orange: 250,
  Violet: 300,
};

const Prompt: React.FC<{ username: string }> = ({ username }) => {
  return (
    <>
      <Title level={4}>
        The offer you had recently submitted has been reviewed by your client’s
        Board and it has been returned with the following recommendations, which
        you are obligated to follow for the upcoming face - to - face
        negotiation round:
      </Title>
      <Title level={4}>
        <ul>
          <li>
            Bear in mind that you are AMD and Xilinx does not have a better
            suitor. Intel cannot take them in and neither can Nvidia. As a
            matter of fact, AMD is probably the only potential Buyer to whom
            Xilinx is talking right now.
          </li>
          <li>
            Therefore, share exchange ratio offered cannot exceed{' '}
            {offerCannotExceed[username]}, but should aim to offer as little as
            possible
          </li>
          <li>
            You cannot offer to pay a break-up of more than{' '}
            {break_up_limit[username]}bn for any scenario
          </li>
          <li>
            You can offer either assuming pension liabilities or covering any
            debt that might need to be retired in the amount of up to $
            {pension_limit[username]} million
          </li>
        </ul>
      </Title>
      <Divider />
      <Title level={4}>
        That “up to ${pension_limit[username]} million” amount cannot exceed the
        amount of expected synergies(based on the independent assessment by the
        firm issuing a fairness opinion letter)
      </Title>
      <Title level={4}>
        PLEASE MAKE SURE YOU RETAIN THE BOARD INSTRUCTIONS DOWN ON A PIECE OF
        PAPER FOR FUTURE REFERENCE.
      </Title>
      <Title level={4}>
        The purpose of the Negotiation is to assess how you can optimize your
        offer and offer as little as possible, yet try to satisfy the Seller.
        That may or may not be ultimately possible.
      </Title>
    </>
  );
};

const BuyerView: React.FC = () => {
  const { username } = useAuthState();
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <Prompt username={username} />

      <Button
        type="primary"
        style={{ marginTop: '2rem', float: 'right' }}
        onClick={() => setClicked(true)}
        disabled={clicked}
      >
        Click to flag readiness for face-to-face Negotiations
      </Button>
    </>
  );
};

export default BuyerView;
