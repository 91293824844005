import { computeFunction } from 'services';

/* CAPITAL STRUCTURE */

export const afterTaxCostOfDept = computeFunction(
  (deptCost, taxRateY1, taxRateY2, taxRateY3) =>
    deptCost * (1 - (taxRateY1 + taxRateY2 + taxRateY3) / 3)
);

export const weightedCostOfDept = computeFunction(
  (debtPerTotal, afterTaxCOD) => debtPerTotal * afterTaxCOD
);

export const marketRiskPremium = computeFunction(
  (expectedMR, riskFreeRate) => expectedMR - riskFreeRate
);

export const costOfEquity = computeFunction(
  (riskFreeRate, cBeta, MRPremium) => riskFreeRate + cBeta * MRPremium
);

export const equityPerTotalCapitalRatio = computeFunction(
  (deptPerTotal) => 1 - deptPerTotal
);

export const weightedCostOfEquity = computeFunction(
  (equityPerTCR, costOfEq) => equityPerTCR * costOfEq
);

export const weightedCostOfCapital = computeFunction(
  (wCostOfEq, wCostOfDept) => wCostOfDept + wCostOfEq
);

/* YEARLY TABLE */

export const ebitdaMargin = computeFunction(
  (ebitda, midRRevenues) => ebitda / midRRevenues
);

export const proformaEbitda = computeFunction((ebitda) => ebitda);

export const noplat = computeFunction(
  (pEbitda, depreciation, eTaxRate) => (pEbitda - depreciation) * (1 - eTaxRate)
);

export const unleveredFcff = computeFunction(
  (nplat, depreciation, netCapexInvestment, netWcInvestment, netGwAi) =>
    nplat + depreciation - netCapexInvestment - netWcInvestment - netGwAi
);

export const discountPeriodYear2 = computeFunction((discount) => discount + 1);
export const discountPeriodYear3 = computeFunction((discount) => discount + 2);
export const discountPeriodTv = computeFunction((discount) => discount + 2);

export const discountFactor = computeFunction(
  (weightedCOC, discPeriod) => (weightedCOC + 1) ** -discPeriod
);

export const dcff = computeFunction(
  (unlFcff, discFactor) => unlFcff * discFactor
);

export const dcffTv = computeFunction(
  (unlFcff, pGrowthRate, weightedCOC, discFactor) =>
    ((unlFcff * (1 + pGrowthRate)) / (weightedCOC - pGrowthRate)) * discFactor
);
