import React, { useState } from 'react';

import { TV } from 'stateConstants';
import * as fn from './functions';
import { useRetrieveCurrentStep } from 'hooks/trainerActions';
import { useSessionPlayer } from 'hooks/traineeActions';
import { DCFFAttempt, TradingComparableAttempt, TransactionComparableFields } from 'types';

const filterNumbers = <T = unknown>(values: T[]) => {
  return values.filter((value) => typeof value === 'number');
};

const areNumbers = (values: unknown[]) => {
  return filterNumbers(values).length === values.length;
};

type ReturnValue = {
  wacc: number;
  setWacc: React.Dispatch<React.SetStateAction<number>>;
  dcff: (number | undefined)[];
  trading: (number | undefined)[];
  wk52: (number | undefined)[];
  transactions: (number | undefined)[];
};

const useParseAttempts = (transactionAttempt: TransactionComparableFields,
  tradingAttempt: TradingComparableAttempt,
  dcffAttempt: DCFFAttempt) => {
  const { data: player } = useSessionPlayer();
  const [wacc, setWacc] = useState(0.5);

  const result: ReturnValue = {
    wacc,
    setWacc,
    dcff: [],
    trading: [],
    wk52: [],
    transactions: [],
  };

  if (dcffAttempt && Object.keys(dcffAttempt).length > 0) {
    const {
      capital_structure: {
        weighted_cost_of_capital,
        equity_per_total_capital_ratio,
        perpetuity_growth_rate,
      },
      yearly_structures,
    } = dcffAttempt;

    const waccLow = weighted_cost_of_capital - wacc;
    const waccHigh = weighted_cost_of_capital + wacc;

    const dcffValues = [waccLow, waccHigh].map((waccValue) => {
      const values = yearly_structures.map((structure: any) => {
        const { discount_period, unlevered_fcff } = structure;

        if (structure.type === TV) {
          return fn.getYearlyValueTV(
            equity_per_total_capital_ratio,
            perpetuity_growth_rate,
            waccValue,
            weighted_cost_of_capital
          );
        }
        return fn.getYearlyValue(waccValue, discount_period, unlevered_fcff);
      });
      // Check that array has only numbers
      if (!areNumbers(values)) {
        return undefined;
      }
      return (values as number[]).reduce((acc, value) => acc + value, 0);
    });

    result.dcff = dcffValues.sort((a, b) =>
      typeof a === 'number' && typeof b === 'number' ? a - b : 0
    );
  }

  if (tradingAttempt && Object.keys(tradingAttempt).length > 0) {
    const { trading_comparables } = tradingAttempt;
    const ltm_multiples = filterNumbers(
      trading_comparables.map((c: any) => c.ltm_multiples)
    );

    const min = Math.min(...ltm_multiples as any);
    const max = Math.max(...ltm_multiples as any);

    result.trading = [min, max];

    // const wk52_low = filterNumbers(
    //   trading_comparables.map((c) => c.wk52_low)
    // ).reduce((s, v) => s + v, 0);
    // const wk52_high = filterNumbers(
    //   trading_comparables.map((c) => c.wk52_high)
    // ).reduce((s, v) => s + v, 0);
    //
    // result.wk52 = [wk52_low, wk52_high].sort((a, b) => a - b);
    result.wk52 = [68.12, 123.85];
  }

  if (transactionAttempt && Object.keys(transactionAttempt).length > 0) {
    const { transaction_comparables } = transactionAttempt;
    const implied_multiples = filterNumbers(
      transaction_comparables.map((c: any) => c.implied_multiples)
    );

    const min = Math.min(...implied_multiples as any);
    const max = Math.max(...implied_multiples as any);

    result.transactions = [min, max];
  }
  return result;
};

export default useParseAttempts;
