import React, { useEffect } from 'react';

import styled from 'styled-components';

import { Space } from 'components';
import { usePageFocus, useSessionId, useTraineeSessionSocket } from 'hooks';
import { useAuthActions, useAuthState } from 'contexts';
import { RelativeSwitch } from 'components/Routing';
import PageLayout from 'components/PageLayout';
import Overview from 'components/Overview';
import PersonSelector from 'components/PersonSelector';
import SessionControls from './SessionControls';
import routes from './routes';
import axios from 'axios';
import settings from '../../settings';
import AuthHeader from '../../services/AuthHeader';
import http from 'http';

const TraineeSession: React.FC = () => {
  useTraineeSessionSocket();
  const { person } = useAuthState();
  const sessionId = useSessionId();
  const isFocused = usePageFocus();
  const { setPersonAndPersist, logout } = useAuthActions();

  useEffect(() => {
    if (isFocused) {
      if (person) {
        axios.post(
          `${settings.API_URL}/sessions/${sessionId}/member_online/`,
          { person_id: person.id },
          { headers: AuthHeader() }
        );
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (person) {
        axios.post(
          `${settings.API_URL}/sessions/${sessionId}/member_offline/`,
          { person_id: person.id },
          { headers: AuthHeader() }
        );
      }
    }
    // return () => {
    //   if (person) {
    //     axios.post(
    //       `${settings.API_URL}/sessions/${sessionId}/member_offline/`,
    //       { person_id: person.id },
    //       { headers: AuthHeader() }
    //     );
    //   }
    // };
  }, [person, isFocused]);

  const sendPing = () => {
    if (person) {
      axios.post(
        `${settings.API_URL}/sessions/${sessionId}/ping/`,
        { person_id: person.id },
        { headers: AuthHeader() }
      );
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isFocused) {
      sendPing(); // Send an immediate ping
      intervalId = setInterval(sendPing, 10000); // Send a ping every 60 seconds
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isFocused, person]);

  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      const httpAgent = new http.Agent({ keepAlive: true });
      const instance = axios.create({
        httpAgent,
      });
      instance.post(
        `${settings.API_URL}/users/person_logged_out/`,
        { person_id: person.id },
        { headers: AuthHeader() }
      );
      // logout();
      // event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <PageLayout
      routes={routes}
      subHeader={<Overview right={<SessionControls />} />}
    >
      <PersonSelector />
      <CustomSpacer direction="vertical">
        {person && <RelativeSwitch routes={routes} />}
      </CustomSpacer>
    </PageLayout>
  );
};

const CustomSpacer = styled(Space)`
  height: 100%;

  .ant-space-item:last-child {
    flex: 1;
  }
`;

export default TraineeSession;
