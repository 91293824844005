import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import type { Route as RouteType } from 'types';

type Props = {
  routes: RouteType[];
};

const RelativeSwitch: React.FC<Props> = ({ routes }) => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      {routes.map((route) => (
        <Route {...route} path={`${path}${route.path}`} key={route.path} />
      ))}
      <Redirect to={`${url}${routes[0]?.path}`} />
    </Switch>
  );
};

export default RelativeSwitch;
