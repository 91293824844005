import React from 'react';

import { Tag } from 'antd';

import {
  SESSION_STATUSES,
  SESSION_STATUSES_COLORS,
  SESSION_STATUSES_INDEX,
} from '../stateConstants';

type Props = {
  status: SESSION_STATUSES_INDEX;
};

const StatusTag: React.FC<Props> = ({ status }) => {
  return (
    <Tag color={SESSION_STATUSES_COLORS[status]}>
      {SESSION_STATUSES[status]}
    </Tag>
  );
};

export default StatusTag;
