export const SESSION_CREATED = 0;
export const SESSION_PLAYING = 1;
export const SESSION_PAUSED = 2;
export const SESSION_FINISHED = 3;

export const SESSION_STATUSES = {
  [SESSION_CREATED]: 'Created',
  [SESSION_PLAYING]: 'Playing',
  [SESSION_PAUSED]: 'Paused',
  [SESSION_FINISHED]: 'Finished',
};

export type SESSION_STATUSES_INDEX = keyof typeof SESSION_STATUSES;

export const SESSION_STATUSES_COLORS = {
  [SESSION_CREATED]: 'blue',
  [SESSION_PLAYING]: 'green',
  [SESSION_PAUSED]: 'orange',
  [SESSION_FINISHED]: 'red',
};

export const HIRED = 0;
export const RETIRED = 1;

export const USER_STATUSES = {
  [HIRED]: 'Hired',
  [RETIRED]: 'Retired',
};

export type USER_STATUSES_INDEX = keyof typeof USER_STATUSES;

export const TRAINEE = 0;
export const TRAINER = 1;
export const ADMIN = 2;

export const USER_TYPES = {
  [TRAINEE]: 'Trainee',
  [TRAINER]: 'Trainer',
  [ADMIN]: 'Admin',
};

export const UNASSIGNED = 0;
export const BUYER = 1;
export const SELLER = 2;

export const PLAYER_TYPES = {
  [UNASSIGNED]: 'Unassigned',
  [BUYER]: 'Buyer',
  [SELLER]: 'Seller',
};

export const [GAME_INSTRUCTIONS, BILATERAL_DOCS, VALUATION_DOCS, DATAROOM] = [
  0,
  1,
  2,
  3,
];
export const DOCUMENT_CATEGORIES = {
  [GAME_INSTRUCTIONS]: 'Game Instructions',
  [BILATERAL_DOCS]: 'Bilateral Docs',
  [VALUATION_DOCS]: 'Valuation Docs',
  [DATAROOM]: 'Data Room',
};

export const YEAR1 = 0;
export const YEAR2 = 1;
export const YEAR3 = 2;
export const TV = 3;

export const RISK = 0;
export const SYNERGY = 1;
