import React from 'react';

import { Space as AntSpace } from 'antd';
import type { SpaceProps } from 'antd';

const Space: React.FC<SpaceProps> = (props) => {
  return <AntSpace style={{ display: 'flex' }} {...props} />;
};

export default Space;
