import React, { useState } from 'react';

import { useSessionId } from 'hooks';
import { Loading } from 'components';
import { Badge, Button, List, Space, Table, Typography } from 'antd';
import {
  useArePlayersAssigned,
  useAssignPlayersWithoutQuiz,
  usePlayersAssignTable,
} from './Steps/QuizStep/actions';
import { PLAYER_TYPES } from 'stateConstants/index';
import { CheckOutlined } from '@ant-design/icons';

const AssignRolesTable: React.FC = () => {
  const sessionId = useSessionId();
  const { data: players } = usePlayersAssignTable();
  const arePlayersAssigned = useArePlayersAssigned();
  const { mutate: assignPlayers } = useAssignPlayersWithoutQuiz(sessionId);
  const [selectedRow, setSelectedRow] = useState<number | null>(0);

  if (players === undefined || arePlayersAssigned === undefined)
    return <Loading />;

  const tableTitle = arePlayersAssigned ? (
    'Assigned roles:'
  ) : (
    <div style={{ display: 'flex', gap: '.25rem' }}>
      Please assign roles by selecting the
      <div style={{ fontWeight: 800 }}>SELLER</div>{' '}
    </div>
  );

  const handleAssignRoles = () => {
    assignPlayers({
      session_id: sessionId,
      seller_player_id: Number(selectedRow),
    });
  };

  return (
    <Space direction="vertical" style={{ display: 'flex', width: '100%' }}>
      {tableTitle}
      <Table
        pagination={false}
        rowKey="id"
        columns={[
          {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
              if (selectedRow === record.id) {
                return (
                  <div style={{ color: 'green', fontWeight: 500 }}>
                    Selected as SELLER team <CheckOutlined />
                  </div>
                );
              }
              return (
                <Button type="ghost" onClick={() => setSelectedRow(record.id)}>
                  Assign as SELLER team
                </Button>
              );
            },
          },
          { title: 'Team', dataIndex: 'username' },
          {
            title: 'Type',
            dataIndex: 'type',
            render(value: keyof typeof PLAYER_TYPES) {
              return PLAYER_TYPES[value];
            },
          },
          {
            title: 'Online status',
            dataIndex: 'status',
            render(text, record) {
              const members = record.members.sort((a, b) => a.id - b.id);
              return (
                <List>
                  {members.map((member) => {
                    return (
                      <List.Item key={member.id} style={{ padding: 2 }}>
                        <Space>
                          {member.is_online ? (
                            <Badge status="success" />
                          ) : (
                            <Badge status="error" />
                          )}
                          <Typography>{member.username}</Typography>
                        </Space>
                      </List.Item>
                    );
                  })}
                </List>
              );
            },
          },
        ]}
        dataSource={players.sort((a, b) => a.id - b.id)}
      />
      <br />
      <Button type="primary" onClick={() => handleAssignRoles()}>
        Assign roles
      </Button>
    </Space>
  );
};

export default AssignRolesTable;
