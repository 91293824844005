import React from 'react';

import { Card } from 'antd';

import { Loading } from 'components';
import { useCurrentStage, useCurrentStep } from 'hooks';
import CurrentStep from './CurrentStep';
import { useSessionPlayer } from 'hooks/traineeActions';

const CurrentStage: React.FC = () => {
  const { data, isLoading } = useCurrentStage();
  const { data: player, isLoading: isPlayerLoading } = useSessionPlayer();
  const { data: step, isLoading: isStepLoading } = useCurrentStep();

  if (isLoading || isStepLoading || isPlayerLoading || !player || !step)
    return <Loading />;

  return (
    <Card>
      <CurrentStep player={player} step={step} />
    </Card>
  );
};

export default CurrentStage;
