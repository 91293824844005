import React, { useEffect } from 'react';

import styled from 'styled-components';
import { InputNumber, Typography, List, Button, Divider } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { ListItem } from 'components';
import { Member, Offer, ProposalStepType } from 'types';
import { useCreateBuyerProposal, useRetrieveBuyerProposal } from './actions';
import { usePersons } from 'modules/TraineeDashboard/actions';
import { useSessionMember, useSubmitCurrentStep } from 'hooks/traineeActions';
import useOfferFields from 'hooks/useOfferFields';
import GlossaryWrappedText from 'modules/TraineeSession/Glossary/GlossaryWrappedText';
import useOfferUpToDate from 'hooks/useOfferUpToDate';

const { Paragraph, Title } = Typography;

type Props = {
  member: Member;
  step: ProposalStepType;
  proposal: Offer;
  ownPerspective: boolean;
};

const BuyerProposal: React.FC<Props> = ({
  step,
  proposal,
  member,
  ownPerspective,
}) => {
  const { mutate: createProposal } = useSubmitCurrentStep();
  const { getProps, offerFields, updateOffer } = useOfferFields();
  const offerUpToDate = useOfferUpToDate(proposal);

  useEffect(() => {
    if (proposal) {
      updateOffer(proposal);
    }
  }, [proposal, updateOffer]);

  return (
    <Wrapper>
      <GlossaryWrappedText
        WrapperComponent={Title}
        wrapperProps={{ level: 4 }}
        text={`You now have enough preparation to make an informed PRELIMINARY ffer.
        You are in a competitive environment, with at least one other team
        making an offer for the Target. This offer is not currently expected to
        include any impact from synergies or risks related to a transaction
        between the Buyer and the Target, as these have not yet been discussed.
        This Step SHOULD NOT TAKE YOU MORE THAN 10 MINUTES.`}
      />

      <BlueTitle level={4}>
        ONLY ONE PERSON PER TEAM SHOULD ENTER THE DATA.
      </BlueTitle>
      {proposal && (
        <Title level={2}>
          Offer submitted by {proposal.last_updated_by?.name}:
        </Title>
      )}

      <Divider />
      <Paragraph>Consideration for the Target shall include:</Paragraph>
      <List>
        <ListItem>
          <GlossaryWrappedText
            text="
          Cash in the amount of
            "
          />
          <InputNumber {...getProps('cash_amount')} /> million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Stock-for-stock exchange at a rate of
            "
          />
          <InputNumber {...getProps('stock_exchange_rate')} />
          <GlossaryWrappedText
            text="
          shares of the
          Buyer for every outstanding share of the Target upon closing and cash
          in lieu of fractional shares for a total stock consideration in the
          amount of
            "
          />
          <InputNumber {...getProps('cash_in_lieu')} /> million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Deferred cash consideration in the amount
            "
          />
          <InputNumber {...getProps('deferred_cash')} /> of million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Assumption of Pension liabilities in the amount of
            "
          />
          <InputNumber {...getProps('pension_liabilities')} /> million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Assumption of existing Target debt in the amount of
            "
          />
          <InputNumber {...getProps('target_dept')} /> million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Also, you are prepared to offer a definitive amount of
            "
          />
          <InputNumber {...getProps('breakup_fee')} />
          <GlossaryWrappedText
            text="
           million USD as a Break-Up
          fee if proposed transaction fails due to a fault of your own
            "
          />
        </ListItem>
      </List>

      {ownPerspective && (
        <Footer>
          <Button
            type="primary"
            onClick={() => {
              createProposal({
                offer: {
                  ...offerFields,
                  step: step.id,
                  member: member.id,
                  player: member.player,
                },
              });
            }}
            disabled={offerUpToDate}
          >
            <ArrowRightOutlined />
            Save offer details
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Footer = styled.div`
  display: flex;
  justify-content: right;
`;

const BlueTitle = styled(Title)`
  &.ant-typography {
    color: #0d3fe0;
  }
`;

export default BuyerProposal;
