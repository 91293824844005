import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Col, Row, Typography, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { useCurrentStep } from 'hooks';
import { useSessionMember } from 'hooks/traineeActions';
import { RISK, SYNERGY } from 'stateConstants';
import { Loading } from 'components';
import { Player, SynergiesStepType, Synergy } from 'types';
import { useListSynergies, useSubmitSynergies } from './actions';
import SynergyTable from './SynergyTable';
import GlossaryWrappedText from 'modules/TraineeSession/Glossary/GlossaryWrappedText';

const { Paragraph } = Typography;

const SynergiesStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
  synergies: Synergy[];
}> = ({ player, ownPerspective, synergies: savedSynergies }) => {
  const {
    data: step,
    isLoading: isStepLoading,
  } = useCurrentStep<SynergiesStepType>();
  const { data: member, isLoading: isMemberLoading } = useSessionMember();
  const [synergies, setSynergies] = useState(
    {} as { [field: number]: Synergy }
  );
  const { mutate: submitSynergies } = useSubmitSynergies();

  useEffect(() => {
    if (savedSynergies?.length) {
      savedSynergies.forEach(({ synergy_field }) => {
        delete synergies[synergy_field];
      });
    }
  }, [savedSynergies]);

  const updateSynergy = (id: number, field: 'probability' | 'value') => {
    return (value: number) => {
      setSynergies({
        ...synergies,
        [id]: { ...synergies[id], [field]: value },
      });
    };
  };

  if (isStepLoading || isMemberLoading) {
    return <Loading />;
  }

  if (!step || !member) {
    return <Paragraph>Oops... Something's wrong?!</Paragraph>;
  }

  const { synergy_fields } = step;

  const riskFields = synergy_fields.filter((field) => field.type === RISK);
  const synergyFields = synergy_fields.filter(
    (field) => field.type === SYNERGY
  );

  const onSubmit = () => {
    const parsedSynergies = Object.entries(synergies).map(
      ([id, { probability, value }]) => {
        return {
          probability: (probability || 0) / 100,
          value,
          synergy_field: parseInt(id),
          member: member.id,
        };
      }
    );
    submitSynergies({ synergies: parsedSynergies });
  };

  return (
    <div>
      <Wrapper>
        <GlossaryWrappedText
          WrapperComponent={Paragraph}
          text={`
          Based on the information reviewed in the Risk Factors Report and other
          info you have acquired thus far, you are expected to identify at least
          some, but not all, of the critical Risks and Synergies that a buyer
          would have to take into account when making a final decision on the
          transaction.`}
        />
        <GlossaryWrappedText
          WrapperComponent={Paragraph}
          text={`Risks and Synergies impact not only the overall dynamic of this
          transaction, but in particular the price that the Buyer is willing to
          pay in comparison to other potential buyers. Pay much attention to the
          statements in each of the available options below before spending time
          on anything else. Synergies that matter in this transaction are those
          materializing in 18 months.`}
        />

        <Paragraph>
          To each of the Risks and Synergies you select below, also assign:
          <ol>
            <li>
              a probability of it materializing after the SPA is signed by both
              parties and
            </li>
            <li>
              a monetary value of the impact of that Risk or Synergy to the
              BUYER or the COMBINED ENTITY.
            </li>
          </ol>
        </Paragraph>
      </Wrapper>

      <Row style={{ marginTop: 35 }}>
        <Col span={12}>
          <SynergyTable
            title="Risks"
            dataSource={riskFields}
            state={synergies}
            updateState={updateSynergy}
            allowDisabled
          />
        </Col>
        <Col span={12}>
          <SynergyTable
            title="Synergies"
            dataSource={synergyFields}
            state={synergies}
            updateState={updateSynergy}
            allowDisabled
          />
        </Col>
      </Row>

      <Footer>
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={savedSynergies?.length === synergy_fields.length}
        >
          <ArrowRightOutlined />
          Submit
        </Button>
      </Footer>
    </div>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Footer = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: right;
`;

export default SynergiesStep;
