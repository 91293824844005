import React from 'react';
import { useSessionMember, useSessionPlayer } from 'hooks/traineeActions';
import { Loading } from 'components';
import { UNASSIGNED, BUYER, SELLER } from 'stateConstants';
import { Typography } from 'antd';
import SellerView from './SellerView';
import BuyerView from './BuyerView';
import { Player } from 'types';

const { Paragraph, Title } = Typography;

const PreEliminationStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
}> = ({ player, ownPerspective }) => {
  // const { data: player, isLoading: isPlayerLoading } = useSessionPlayer();

  const { data: member, isLoading: isMemberLoading } = useSessionMember();

  if (isMemberLoading) {
    return <Loading />;
  }

  if (!player || player.type === UNASSIGNED) {
    return <Paragraph>Player type cannot be unassigned</Paragraph>;
  }
  return (
    <>
      {player.type === BUYER && <BuyerView />}
      {player.type === SELLER && <SellerView />}
    </>
  );
};

export default PreEliminationStep;
