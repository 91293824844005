import React, { useState } from 'react';

import { SendOutlined } from '@ant-design/icons';
import { Button, Row, Col, Space } from 'antd';

import { Loading } from 'components';
import { useAuthState } from 'contexts';
import { useSessionId, useCurrentStep } from 'hooks';
import QuestionCard from './QuestionCard';
import { useSubmitQuiz } from './actions';

import { QuizStepType } from 'types';

const QuestionsForm: React.FC = () => {
  const sessionId = useSessionId();
  const { person } = useAuthState();
  const { data: currentStep, isLoading } = useCurrentStep<QuizStepType>();

  const [answers, setAnswers] = useState<{
    [question: number]: number | undefined;
  }>({});

  const formatAnswers = () => {
    const result = [] as Array<{ question: number; answer: number }>;
    Object.entries(answers).forEach(([question, answer]) => {
      if (answer !== undefined) {
        result.push({ question: parseInt(question), answer });
      }
    });
    return result;
  };

  const { mutate: submitQuiz } = useSubmitQuiz({
    session_id: sessionId,
    person: person.id,
    quiz: currentStep?.id || 0,
    answers: formatAnswers(),
  });

  if (!currentStep || isLoading) {
    return <Loading />;
  }

  return (
    <Space direction="vertical" size={32}>
      <Row gutter={[32, 32]}>
        {currentStep.questions.map((question) => {
          return (
            <Col span={12} key={question.id}>
              <QuestionCard
                question={question}
                onSelectAnswer={(answer_id: number) =>
                  setAnswers({ ...answers, [question.id]: answer_id })
                }
                selectedAnswerId={answers[question.id]}
              />
            </Col>
          );
        })}
      </Row>

      <Button type="primary" onClick={() => submitQuiz()}>
        <SendOutlined />
        Submit
      </Button>
    </Space>
  );
};

export default QuestionsForm;
