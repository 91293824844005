import { createGlobalStyle, DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    bg1: '#fff',
    bg2: '#f0f2f5',
    success: '#52c41a',
    warning: '#faad14',
    error: '#f5222d',
  },
};

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.colors.bg2}
  }
`;

export default theme;
