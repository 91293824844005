import React, { useState } from 'react';
import { Table, Button, Popconfirm, Modal, Form, Input } from 'antd';
import {
  useAddGlossaryEntry,
  useDeleteGlossaryEntry,
  useUpdateGlossaryEntry,
  //   useDeleteGlossaryEntry,
} from './actions';
import { GlossaryEntry, GlossaryForm } from 'types';
import useGlossary from './useGlossary';
import { Loading } from 'components';

const GlossaryTable: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingEntry, setEditingEntry] = useState<GlossaryEntry | null>(null);
  const { data: glossary, isLoading: glossaryIsLoading } = useGlossary();
  const addMutation = useAddGlossaryEntry();
  const updateMutation = useUpdateGlossaryEntry();
  const deleteMutation = useDeleteGlossaryEntry();

  const [form] = Form.useForm<GlossaryForm>();

  const handleAdd = (values: GlossaryForm) => {
    addMutation.mutate(values);
    setIsModalVisible(false);
  };

  const handleUpdate = (values: GlossaryForm) => {
    if (editingEntry) {
      updateMutation.mutate({ ...editingEntry, ...values });
      setIsModalVisible(false);
      setEditingEntry(null);
    }
  };
  if (!glossary) return <Loading />;
  const handleDelete = (values: GlossaryEntry) => {
    deleteMutation.mutate(values);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: GlossaryEntry) => (
        <>
          <Button
            onClick={() => {
              setEditingEntry(record);
              form.setFieldsValue(record);
              setIsModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record)}
          >
            <Button>Delete</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <Button
        onClick={() => {
          setEditingEntry(null);
          form.resetFields();
          setIsModalVisible(true);
        }}
      >
        Add New Entry
      </Button>
      <Table
        dataSource={glossary.sort(
          (a, b) =>
            new Date(a.updated).getTime() - new Date(b.updated).getTime()
        )}
        columns={columns}
        loading={glossaryIsLoading}
        rowKey="id"
      />
      <Modal
        title={editingEntry ? 'Edit Entry' : 'Add Entry'}
        visible={isModalVisible}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              if (editingEntry) handleUpdate(values);
              else handleAdd(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={() => {
          setIsModalVisible(false);
          setEditingEntry(null);
        }}
      >
        <Form form={form} layout="vertical" name="glossaryForm">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please input the title!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="text"
            label="Description"
            rules={[
              { required: true, message: 'Please input the description!' },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default GlossaryTable;
