import React from 'react';

import { Typography } from 'antd';

import { useCurrentStep } from 'hooks';
import { useSessionMember, useSessionPlayer } from 'hooks/traineeActions';
import { Loading } from 'components';
import { BUYER, SELLER, UNASSIGNED } from 'stateConstants';
import { Member, Offer, Player, ProposalStepType, SellerOffer } from 'types';
import BuyerProposal from './BuyerProposal';
import SellerProposal from './SellerProposal';

const { Paragraph } = Typography;

const ProposalStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
  proposal: Offer | SellerOffer;
}> = ({ ownPerspective, player, proposal }) => {
  // const { data: player, isLoading: isPlayerLoading } = useSessionPlayer();
  const {
    data: step,
    isLoading: isStepLoading,
  } = useCurrentStep<ProposalStepType>();
  const { data: member, isLoading: isMemberLoading } = useSessionMember();

  if (isStepLoading || isMemberLoading || !step) {
    return <Loading />;
  }

  if (!player || player.type === UNASSIGNED) {
    return <Paragraph>Player type cannot be unassigned</Paragraph>;
  }

  return (
    <>
      {player.type === BUYER && (
        <BuyerProposal
          proposal={proposal as Offer}
          step={step}
          member={member || ({} as Member)}
          ownPerspective={ownPerspective}
        />
      )}
      {player.type === SELLER && (
        <SellerProposal
          proposal={proposal as SellerOffer}
          member={member || ({} as Member)}
          ownPerspective={ownPerspective}
        />
      )}
    </>
  );
};

export default ProposalStep;
