import React from 'react';

import { Form, Select } from 'antd';

import type { FormInstance } from 'antd';

import { useScenarios } from 'hooks';
import { SessionFormData as Data } from '../types';

type Props = {
  onFinish: (data: Data) => void;
  form: FormInstance<Data>;
};

const SessionForm: React.FC<Props> = ({ form, onFinish }) => {
  const { data: scenarios, isLoading: loadingScenarios } = useScenarios();

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item label="Scenario" name="scenario" rules={[{ required: true }]}>
        <Select placeholder="Select scenario" loading={loadingScenarios}>
          {scenarios?.map((scenario) => (
            <Select.Option key={scenario.id} value={scenario.id}>
              {scenario.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default SessionForm;
