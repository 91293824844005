import { useState } from 'react';
import { Offer, OfferFields } from 'types';
import useOfferUpToDate from './useOfferUpToDate';

const useOfferFields = () => {
    const [offerFields, setOffer] = useState<OfferFields>({} as OfferFields);
    const disabled = useOfferUpToDate(offerFields as Offer)

    const getProps = (field: keyof OfferFields) => {
        const onChange = (value: number) => {
            setOffer((prevState) => ({ ...prevState, [field]: value }));
        };

        return {
            value: offerFields[field],
            onChange,
            disabled,
        };
    };

    const updateOffer = (savedOffer: Offer) => {
        setOffer(savedOffer);
    };

    return { offerFields, getProps, updateOffer, disabled };
};

export default useOfferFields;
