import React, { useState } from 'react';

import styled from 'styled-components';
import { Card, Input, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { data } from './data';

type Data = {
  title: string;
  text: string;
}[];

const Glossary: React.FC = () => {
  const [search, setSearch] = useState('');

  const searchFilter = (items: Data) => {
    if (!search) return items;

    return items.filter((item) => {
      const title = item.title.toLowerCase();
      const query = search.toLowerCase();

      return title.includes(query);
    });
  };

  return (
    <FixedCard title="Glossary">
      <CustomTabs
        tabPosition="left"
        tabBarExtraContent={{
          left: (
            <Input
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search by name"
              suffix={<SearchOutlined />}
            />
          ),
        }}
      >
        {searchFilter(data).map((item) => {
          return (
            <Tabs.TabPane key={item.title} tab={item.title}>
              {item.text}
            </Tabs.TabPane>
          );
        })}
      </CustomTabs>
    </FixedCard>
  );
};

const FixedCard = styled(Card)`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const CustomTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    padding-top: 16px;
  }

  .ant-tabs {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    height: calc(100% - 56px);
    padding: 24px;
  }

  .tablist {
    height: 100%;
    overflow: auto;
  }

  .ant-tabs-tab {
    margin: 0 !important;
  }

  .ant-tabs-content {
    width: 100%;
    font-size: 18px;
  }

  @media only screen and (max-width: 1200px) {
    .ant-tabs-content {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 767px) {
    .ant-tabs-content {
      font-size: 14px;
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
`;

export default Glossary;
