import React from 'react';

import { Divider, Table, Typography } from 'antd';

import { PLAYER_TYPES } from 'stateConstants';
import { Loading } from 'components';
import { useCurrentStep } from 'hooks';
import { useTransCompStep } from './actions';

const TradingComparablesStep: React.FC = () => {
  const {
    data: currentStep,
    isLoading: isCurrentStepLoading,
  } = useCurrentStep();
  const { data: players, isLoading: isPlayersLoading } = useTransCompStep(
    currentStep?.id || 0
  );

  if (isPlayersLoading || isCurrentStepLoading) {
    return <Loading />;
  }

  const dataSource = (players || []).sort((a, b) =>
    a.username.localeCompare(b.username)
  );
  return (
    <div>
      <Typography.Title level={4}>
        Lastly, the same concept applies here, the Transaction Comps screen, Participants would normally be required
        to enter their transaction Comps outputs but the data has already been inserted into the Simulation, due to
        time constraints.. They can just review it and click Submit to proceed.
      </Typography.Title>
      <Typography.Title level={4}>
        Once they hit Submit they will be able to access a Football Field showing the outputs of their Valuation exercise.
        Feel free to discuss the Football Field concept with participants unless they are already familiar with it.
      </Typography.Title>
      <Divider />
      <Table
        pagination={false}
        rowKey="id"
        columns={[
          { title: 'Team', dataIndex: 'username' },
          { title: 'Member count', dataIndex: 'person_count' },
          {
            title: 'Type',
            dataIndex: 'type',
            render(value: keyof typeof PLAYER_TYPES) {
              return PLAYER_TYPES[value];
            },
          },
          {
            title: 'Step Finalized',
            dataIndex: 'trans_comp_attempts_count',
            render(value: number) {
              return value !== 0 ? "Yes" : "No";
            }
          },
        ]}
        dataSource={dataSource}
      />
    </div>
  );
};

export default TradingComparablesStep;
