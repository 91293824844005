import React, { useEffect, useMemo, useState } from 'react';

import { Table, Typography, Checkbox, Button, Divider } from 'antd';
import styled from 'styled-components';

import { useSessionMember } from 'hooks/traineeActions';
import { Loading } from 'components';
import { Offer, Player } from 'types';
import { useRetrieveOffers } from '../ReviewStep/actions';
import { useSelectTeams } from '../EliminationStep/actions';
import { isOfferUpToDate } from 'hooks/useOfferUpToDate';
import useCurrentStep from 'hooks/useCurrentStep';
import useCurrentStage from 'hooks/useCurrentStage';

const { Title, Text } = Typography;

type Field = {
  text: string;
  field: keyof Offer;
};

const SellerFinalOffer: React.FC<{
  offers: Offer[];
  player: Player;
  ownPerspective: boolean;
}> = ({ offers, player, ownPerspective }) => {
  const { data: member, isLoading: isMemberLoading } = useSessionMember();
  const { data: step } = useCurrentStep();
  const { data: stage } = useCurrentStage();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedTeams, setSelectedTeams] = useState<
    { id: number; selected: boolean }[]
  >([]);
  const { mutate: selectTeams } = useSelectTeams();
  
  useEffect(() => {
    if (offers && Array.isArray(offers))
      setSelectedTeams(
        offers
          .filter((o) => o.is_in_game)
          .map((o) => {
            return { id: o.id, selected: o.selected as boolean };
          })
      );
  }, [offers]);

  const dataSource: Field[] = useMemo(
    () => [
      {
        text: 'Offer updated',
        field: 'tier',
      },
      {
        text: 'Cash in the amount of',
        field: 'cash_amount',
      },
      {
        text: 'Stock -for-stock exchange in the amount of',
        field: 'stock_exchange_rate',
      },
      {
        text: 'Deferred cash consideration in the amount of',
        field: 'deferred_cash',
      },
      {
        text: 'Assumption of Pension liabilities in the amount of',
        field: 'pension_liabilities',
      },
      {
        text: 'Assumption of existing Target debt in the amount of',
        field: 'target_dept',
      },
      {
        text: 'Break-Up fee in the amount of',
        field: 'breakup_fee',
      },
      {
        text: 'Seller Break-Up fee in the amount of',
        field: 'seller_breakup_fee',
      },
      // {
      //     text: 'Probability-adjusted realizable synergies valued at',
      //     field: 'synergies_total',
      // },
      // {
      //     text: 'Probability-adjusted potential risks valued at',
      //     field: 'risks_total',
      // },
      {
        text: 'Selected',
        field: 'selected',
      },
    ],
    []
  );

  if (isMemberLoading || !offers || !Array.isArray(offers)) {
    return <Loading />;
  }
  if (offers.filter((p) => p.passed_final !== 0).length !== 0)
    return (
      <>
        <Title level={4}>
          Dear Xilinx Team,
          <br />
        </Title>
        <Title level={4}>
          Congratulations for achieving such a milestone. You have been invited
          to sign the Transaction with AMD. There is still a long way to go
          before Closing, but we are certain that your team will get there.
        </Title>
        <Title level={4}>
          Thank you also for participating in the M&A simulation!
        </Title>
      </>
    );

  const createColumn = (offer: any, index: number) => {
    return {
      title: offer.username,
      render: (text: any, record: any) => {
        switch (record.field) {
          case 'tier':
            const upToDate = isOfferUpToDate(
              (offers as any)[index],
              step,
              stage
            );
            return upToDate ? (
              <p style={{ color: 'green' }}>Yes</p>
            ) : (
              <p style={{ color: 'red' }}>No</p>
            );
          case 'selected':
            return (
              <Checkbox
                disabled={!isOfferUpToDate((offers as any)[index], step, stage)}
                checked={selectedTeams[index]?.selected || false}
                onChange={(e) => {
                  if (
                    selectedTeams.filter((s) => s.selected).length >= 1 &&
                    e.target.checked
                  ) {
                    setErrorMessage('Only one team can be selected');
                  } else {
                    setSelectedTeams(
                      selectedTeams.map((st, idx) => {
                        if (idx === index) {
                          return { ...st, selected: e.target.checked };
                        }
                        return st;
                      })
                    );
                  }
                }}
              >
                Select
              </Checkbox>
            );
          default:
            return (offers as any)[index][record.field];
        }
      },
    };
  };

  const columns = [
    {
      title: '',
      dataIndex: 'text',
    },
  ];

  offers.forEach((offer, index) =>
    columns.push(createColumn(offer, index) as any)
  );

  return (
    <Wrapper>
      <Title level={4}>
        <ul>
          <li>
            You can now lower your expectations and accept an offer of 1.74 AMD
            shares for 1 Xilinx share.
          </li>
          <li>
            also, you can accept a lower break-up fee of 1.5bn for the deal not
            closing, for any reason other than Xilinx’s Board fault
          </li>
        </ul>
      </Title>
      <Title level={4}>
        Also, if unable to obtain the offer sought, you should accept the best
        possible offer, as long as it exceeds 1.72 AMD shares for one Xilinx
        share. If, for some reason, the Offers you receive end up being
        identical, and both are acceptable, you should make your decision based
        on the competence and capacity displayed by the Buyer’s team to deliver
        outstanding results for your shareholders far into the future.
      </Title>
      <Title level={4}>
        PLEASE MAKE SURE YOU RETAIN THE BOARD’s INSTRUCTIONS DOWN ON A PIECE OF
        PAPER FOR FUTURE REFERENCE.”
      </Title>
      <Divider />
      <Table
        rowKey="field"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      {ownPerspective && (
        <Container>
          <Button
            type="primary"
            onClick={() =>
              selectTeams({
                players: selectedTeams,
              })
            }
            disabled={offers.filter((p) => p.passed_final !== 0).length !== 0}
          >
            Submit
          </Button>
          <Text>{errorMessage}</Text>
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  place-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export default SellerFinalOffer;
