import React from 'react';

import { Card } from 'antd';

import { useSessionsOfUser } from 'modules/TraineeDashboard/actions';
import GenericSessionTable from 'components/GenericSessionTable';
import { useAuthState } from 'contexts/index';
import { Loading } from 'components/index';
import { useTraineeDashboardSocket } from 'hooks/dashboard';

const Sessions: React.FC = () => {
  const { userId } = useAuthState();
  useTraineeDashboardSocket();
  const { data: sessions, isLoading } = useSessionsOfUser(userId);

  if (sessions === undefined || isLoading) return <Loading />;

  return (
    <Card>
      <GenericSessionTable
        dataSource={sessions?.sort((a, b) => b.id - a.id)}
        loading={isLoading}
      />
    </Card>
  );
};

export default Sessions;
