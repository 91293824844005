import React from 'react';

import { useTrainerSessionSocket } from 'hooks';
import Space from 'components/Space';
import { RelativeSwitch } from 'components/Routing';
import PageLayout from 'components/PageLayout';
import Overview from 'components/Overview';

import routes from './routes';
import SessionControls from './SessionControls';

const TrainerSession: React.FC = () => {
  useTrainerSessionSocket();

  return (
    <PageLayout
      routes={routes}
      subHeader={<Overview right={<SessionControls />} />}
    >
      <Space direction="vertical">
        <RelativeSwitch routes={routes} />
      </Space>
    </PageLayout>
  );
};

export default TrainerSession;
