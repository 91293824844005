/* eslint-disable no-nested-ternary */
import React from 'react';
import { Table, Typography } from 'antd';
import { useOfferPlayers } from '../ReviewStep/actions';
import { usePlayersCurrentStep } from 'hooks/trainerActions';
import { isOfferUpToDate } from 'hooks/useOfferUpToDate';
import { useCurrentStage, useCurrentStep } from 'hooks';

const { Title } = Typography;
const colors: { [key: number]: string } = {
  0: 'black',
  1: 'red',
  2: 'green',
};
const FinalOfferStep: React.FC = () => {
  const {
    data: offerPlayers,
    isLoading: isOfferPlayersLoading,
  } = usePlayersCurrentStep();

  const { data: step } = useCurrentStep();
  const { data: stage } = useCurrentStage();

  return (
    <>
      <Title level={4}>
        The last two Buyer teams are now expected to update and Resubmit their
        offer. The last Buyer team negotiating with the Seller shall have 3
        minutes to submit its offer. Once the Seller has selected the winner,
        the game is complete.
      </Title>
      <Table
        title={() => 'Buyer Offers'}
        loading={isOfferPlayersLoading}
        pagination={false}
        rowKey="username"
        columns={[
          {
            title: 'Team',
            dataIndex: 'username',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_final],
                }}
              >
                {record.username}
              </div>
            ),
          },
          {
            title: 'Cash Amount',
            dataIndex: 'cash_amount',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_final],
                }}
              >
                {record.cash_amount}
              </div>
            ),
          },
          {
            title: 'Stock Exchange Rate',
            dataIndex: 'stock_exchange_rate',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_final],
                }}
              >
                {record.stock_exchange_rate}
              </div>
            ),
          },
          {
            title: 'Cash In Lieu',
            dataIndex: 'cash_in_lieu',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_final],
                }}
              >
                {record.cash_in_lieu}
              </div>
            ),
          },
          {
            title: 'Deferred Cash',
            dataIndex: 'deferred_cash',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_final],
                }}
              >
                {record.deferred_cash}
              </div>
            ),
          },
          {
            title: 'Pension Liabilities',
            dataIndex: 'pension_liabilities',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_final],
                }}
              >
                {record.pension_liabilities}
              </div>
            ),
          },
          {
            title: 'Target Debt',
            dataIndex: 'target_dept',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_final],
                }}
              >
                {record.target_dept}
              </div>
            ),
          },
          {
            title: 'Breakup Fee',
            dataIndex: 'breakup_fee',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_final],
                }}
              >
                {record.breakup_fee}
              </div>
            ),
          },
          {
            title: 'Seller Breakup Fee',
            dataIndex: 'seller_breakup_fee',
            render: (_, record) => (
              <div
                style={{
                  color: colors[record.passed_final],
                }}
              >
                {record.seller_breakup_fee}
              </div>
            ),
          },
          {
            title: 'Offer updated',
            dataIndex: 'tier',
            render: (_, record) => (
              <div
                style={{
                  color: isOfferUpToDate(record, step, stage) ? 'green' : 'red',
                }}
              >
                {isOfferUpToDate(record, step, stage) ? 'Yes' : 'No'}
              </div>
            ),
          },
        ]}
        dataSource={offerPlayers
          ?.filter((p: any) => p.offer)
          .map((p: any) => ({
            username: p.username,
            ...p.offer,
          }))}
      />
    </>
  );
};

export default FinalOfferStep;
