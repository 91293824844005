import { Button, Card, Space, Table, Typography } from 'antd';
import { Loading } from 'components';
import { useSession } from 'hooks';
import { usePlayersCurrentStep } from 'hooks/trainerActions';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const realTransaction = {
  username: 'Real deal',
  cash_amount: 100,
  stock_exchange_rate: 100,
  cash_in_lieu: 100,
  deferred_cash: 100,
  pension_liabilities: 100,
  target_dept: 100,
  breakup_fee: 100,
  seller_breakup_fee: 100,
};

const columns = [
  {
    title: 'Team',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Cash in the amount of',
    dataIndex: 'cash_amount',
    key: 'cash_amount',
  },
  {
    title: 'Stock -for-stock exchange in the amount of',
    dataIndex: 'stock_exchange_rate',
    key: 'stock_exchange_rate',
  },
  {
    title: 'Deferred cash consideration in the amount of',
    dataIndex: 'deferred_cash',
    key: 'deferred_cash',
  },
  {
    title: 'Assumption of Pension liabilities in the amount of',
    dataIndex: 'pension_liabilities',
    key: 'pension_liabilities',
  },
  {
    title: 'Assumption of existing Target debt in the amount of',
    dataIndex: 'target_dept',
    key: 'target_dept',
  },
  {
    title: 'Break-Up fee in the amount of',
    dataIndex: 'breakup_fee',
    key: 'breakup_fee',
  },
];

const SessionSummary: React.FC = () => {
  const { data: session } = useSession();
  const {
    data: offerPlayers,
    isLoading: isOfferPlayersLoading,
  } = usePlayersCurrentStep();
  const history = useHistory();

  const winner = offerPlayers.filter((p: any) => p.passed_final === 2)[0];

  if (!session || isOfferPlayersLoading) return <Loading />;
  return (
    <Card>
      <Wrapper>
        {winner ? (
          <>
            <Typography.Text>
              The {session.name} session has ended!
            </Typography.Text>
            <Typography.Text>
              You can compare the winner team deal with the actual deal bellow:
            </Typography.Text>

            <Table
              dataSource={[
                { username: winner.username, ...winner.offer },
                realTransaction,
              ]}
              columns={columns}
            />
          </>
        ) : (
          <div style={{display: "flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
            <Typography.Title>Session finished early!</Typography.Title>
            <Button onClick={()=>{history.push("/")}}>Go to Dashboard</Button>
            </div>
        )}
      </Wrapper>
    </Card>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
`;

export default SessionSummary;
