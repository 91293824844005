import React from 'react';
import styled from 'styled-components';

import { Card, Divider, Row, Space, Tag } from 'antd';

import { useSession } from 'hooks';
import SessionState from './SessionState';
import CurrentStage from './CurrentStage';
import Steps from './Steps';

type Props = {
  right?: JSX.Element;
};

const Overview: React.FC<Props> = ({ right }) => {
  const { data, isLoading } = useSession();

  if (isLoading) return <OverviewPlaceholder />;

  return (
    <StyledCard size="small">
      <Row align="middle">
        <AlignerLeft>
          <Space split={<Separator type="vertical" />}>
            <div>
              {/* <Typography.Text type="secondary">{'Scenario: '}</Typography.Text> */}
              <Tag color="blue">{data?.scenario.name}</Tag>
            </div>
            <SessionState />
            <CurrentStage />
            <Steps />
          </Space>
        </AlignerLeft>

        {right}
      </Row>
    </StyledCard>
  );
};

const OverviewPlaceholder = styled.div`
  height: 58px;
  background: #fff;
`;

const StyledCard = styled(Card)`
  padding: 0 56px; // Ant card body has 8px
`;

const Separator = styled(Divider)`
  background-color: black;
`;

const AlignerLeft = styled.div`
  flex: 1;
`;

export default Overview;
