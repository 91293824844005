import useSessionId from './useSessionId';
import useAuthQuery from './useAuthQuery';

import type { Player, Member } from 'types';
import { useAuthState } from 'contexts';
import { createPostRequest } from 'services';
import { useMutation, useQueryClient } from 'react-query';

export const useSessionPlayer = () => {
  const id = useSessionId();
  return useAuthQuery<Player>(['sessions', id, 'player'], { enabled: !!id });
};

export const useSessionMember = () => {
  const id = useSessionId();
  const { person } = useAuthState();
  return useAuthQuery<Member>(['sessions', id, 'member'], {
    enabled: !!(id && person?.id),
  });
};


export const submitCurrentStep = (id: number) =>
  createPostRequest(`sessions/${id}/current-step/attempt`, 'post', id);
export const editCurrentStep = (id: number) =>
  createPostRequest(`sessions/${id}/current-step/attempt`, 'put', id);

export const useSubmitCurrentStep = () => {
  const queryClient = useQueryClient();
  const sessionId = useSessionId();
  const submit = submitCurrentStep(sessionId);
  const { data: member } = useSessionMember();

  return useMutation<unknown, unknown, any>(
    (data) => submit(data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['sessions', sessionId, 'member']);
        queryClient.invalidateQueries(['sessions', sessionId, 'current-step']);
        queryClient.invalidateQueries(['member', member?.id || 0, 'documents']);
      },
    }
  );
};
export const useEditCurrentStep = () => {
  const queryClient = useQueryClient();
  const sessionId = useSessionId();
  const submit = editCurrentStep(sessionId);
  const { data: member } = useSessionMember();

  return useMutation<unknown, unknown, any>(
    (data) => submit(data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['sessions', sessionId, 'member']);
        queryClient.invalidateQueries(['sessions', sessionId, 'current-step']);
        queryClient.invalidateQueries(['member', member?.id || 0, 'documents']);
      },
    }
  );
};