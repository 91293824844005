import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Card, Space, Tabs, Typography } from 'antd';

import OnlinePlayers from './OnlinePlayers';
import CurrentStep from './CurrentStep';
import {
  usePlayersCurrentStep,
  useRetrieveCurrentStep,
} from 'hooks/trainerActions';
import { Player, StepDataMap } from 'types';
import styled from 'styled-components';
import TraineeCurrentStep from 'modules/TraineeSession/Simulation/CurrentStep';
import { useCurrentStep, useSession } from 'hooks';
import { useArePlayersAssigned } from './Steps/QuizStep/actions';
import AssignRolesTable from './AssignRolesTable';
import { SELLER, SESSION_FINISHED } from 'stateConstants';
import SessionSummary from './SessionSummary';

const { Title } = Typography;

const Simulation: React.FC = () => {
  const {
    data: players,
    isLoading,
    isError,
    refetch: refetchPlayer,
  } = usePlayersCurrentStep();
  const {
    data: step,
    isLoading: isStepLoading,
    refetch: refetchStep,
  } = useCurrentStep();
  const { data: session, refetch: refetchSession } = useSession();

  const arePlayersAssigned = useArePlayersAssigned();
  const [activeTab, setActiveTab] = useState('trainer');
  const { refetch: refetchCurrentStep } = useRetrieveCurrentStep(
    activeTab === 'trainer' ? 0 : Number(activeTab),
    step?.resourcetype as keyof StepDataMap
  );

  const sortedPlayers = useMemo(() => {
    return players ? [...players].sort((a, b) => a.id - b.id) : [];
  }, [players]);

  useEffect(() => {
    setActiveTab('trainer');
  }, [step?.id]);

  // useEffect(() => {
  //   refetchSession();
  // }, []);
  const handleTabClick = (tabKey: string) => {
    setActiveTab(tabKey);
    refetchStep();
    refetchPlayer();
    console.log("refetchCurrentStep");
    
    refetchCurrentStep();
  };

  if (session?.status === SESSION_FINISHED) {
    return <SessionSummary />;
  }

  if (!step) {
    return (
      <Card>
        <Wrapper>
          {arePlayersAssigned ? (
            <Title level={4}>
              Dear Trainer, once participants have logged in, ask them to click
              on the Simulation Tab at the top of their screen and then you can
              click on the START button.
            </Title>
          ) : (
            <Title level={4}>
              Dear Trainer, please assign roles in order to proceed.
            </Title>
          )}

          <br />
          <AssignRolesTable />
        </Wrapper>
      </Card>
    );
  }
  if (isLoading || isStepLoading) {
    return <div>Loading...</div>;
  }

  if (isError || !players) {
    return <div>Error loading players</div>;
  }

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Card>
        <Wrapper>
          <TabList>
            <MemoizedTab
              onClick={() => handleTabClick('trainer')}
              active={activeTab === 'trainer'}
            >
              Trainer
            </MemoizedTab>
            {sortedPlayers.length > 0 &&
              sortedPlayers
                .filter((player: Player) => player.is_in_game)
                .map((player: Player) => (
                  <MemoizedTab
                    key={player.id}
                    onClick={() => handleTabClick(player.id.toString())}
                    active={activeTab === player.id.toString()}
                  >
                    <div
                      style={{
                        color: player.type === SELLER ? 'green' : 'black',
                        fontWeight: player.type === SELLER ? 700 : 500,
                      }}
                    >
                      {player.username}{' '}
                      {player.type === SELLER ? '(seller)' : ''}
                    </div>
                  </MemoizedTab>
                ))}
          </TabList>

          <TabContent>
            {activeTab === 'trainer' && <CurrentStep step={step} />}
            {sortedPlayers.map((player: Player) => {
              if (activeTab === player.id.toString()) {
                return (
                  <div key={player.id}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography.Title>
                        Viewing from team {player.username}'s perspective
                      </Typography.Title>
                    </div>
                    <TraineeCurrentStep player={player} step={step} />
                  </div>
                );
              }
              return null;
            })}
          </TabContent>
        </Wrapper>
      </Card>
    </Space>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
`;

const TabList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-bottom: 2px solid #ccc;
`;

const Tab = styled(Button)<{ active: boolean }>`
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid #1890ff;
    font-weight: bold;
  `}
`;
const MemoizedTab = React.memo(Tab);

const TabContent = styled.div`
  padding: 16px;
`;
export default Simulation;
