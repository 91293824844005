import React, { useState } from 'react';

import { Button, Form, Input, Modal, Select } from 'antd';

import { Loading } from 'components';
import { useCreateKlass, useTrainers } from 'modules/AdminDashboard/actions';

type Props = {
  visible: boolean;
  hide: () => void;
};

const CreateClassModal: React.FC<Props> = ({ visible, hide }) => {
  const trainers = useTrainers();
  const { mutate: createKlass } = useCreateKlass();
  const [selectedTrainer, setSelectedTrainer] = useState<number | undefined>();
  const [form] = Form.useForm();

  if (trainers === undefined) {
    return <Loading />;
  }

  return (
    <Modal
      title="Create a new class"
      visible={visible}
      onCancel={hide}
      footer={[
        <Button
          type="primary"
          key="key"
          onClick={() => {
            createKlass({
              name: form.getFieldValue('name'),
              trainer_id: selectedTrainer,
            });
            hide();
          }}
        >
          Create
        </Button>,
        <Button
          onClick={() => {
            hide();
          }}
        >
          Cancel
        </Button>,
      ]}
    >
      <Form form={form} labelCol={{ span: 6 }}>
        <Form.Item required label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item required label="Traine" name="trainer">
          <Select
            showSearch
            allowClear
            style={{ width: 200 }}
            placeholder="Select trainer"
            optionFilterProp="children"
            onChange={(trainerId) => {
              setSelectedTrainer(trainerId);
            }}
          >
            {Object.values(trainers).map((trainer) => {
              const { id, username } = trainer;
              return (
                <Select.Option key={id} value={id}>
                  {username}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateClassModal;
