import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  CapitalInputs as CapitalInputsType,
  DCFStepType,
  PartialCapitalStructure,
  PartialYearlyStructure,
  Player,
  StepDataMap,
  YearlyInputs as YearlyInputsType,
} from 'types';
import { TV, YEAR1, YEAR2, YEAR3 } from 'stateConstants';
import * as fn from './functions';
import { useRetrieveDcff } from 'modules/TraineeSession/Simulation/Steps/DCFStep/actions';
import { useSessionMember, useSessionPlayer } from 'hooks/traineeActions';
import { useCurrentStep } from 'hooks';
import { useRetrieveCurrentStep } from 'hooks/trainerActions';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

type State = {
  capitalData: PartialCapitalStructure;
  setCapitalData: SetState<PartialCapitalStructure>;

  capitalInputs: CapitalInputsType;
  setCapitalInputs: SetState<CapitalInputsType>;

  yearlyData: PartialYearlyStructure;
  setYearlyData: SetState<PartialYearlyStructure>;

  yearlyInputs: YearlyInputsType;
  setYearlyInputs: SetState<YearlyInputsType>;
};

const DCFFContext = createContext<State | undefined>(undefined);

const DCFFProvider: React.FC<{ player: Player }> = ({ children, player }) => {
  const { data: step } = useCurrentStep<DCFStepType>();
  const { data: attempt } = useRetrieveCurrentStep(
    player.id,
    step?.resourcetype as keyof StepDataMap
  );

  const [capitalData, setCapitalData] = useState<PartialCapitalStructure>({});
  const [capitalInputs, setCapitalInputs] = useState<CapitalInputsType>({});
  const [yearlyData, setYearlyData] = useState<PartialYearlyStructure>({
    year1: { type: YEAR1 },
    year2: { type: YEAR2 },
    year3: { type: YEAR3 },
    tv: { type: TV },
  });
  const [yearlyInputs, setYearlyInputs] = useState<YearlyInputsType>({
    year1: { type: YEAR1 },
    year2: { type: YEAR2 },
    year3: { type: YEAR3 },
    tv: { type: TV },
  });

  const yearlyKeys = Object.keys(yearlyData) as (keyof YearlyInputsType)[];
  const isMounted = useRef(true);
  useEffect(() => {
    // When the component unmounts, the cleanup function will run
    // setting isMounted to false
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (isMounted.current) {
      if (
        !attempt ||
        !attempt.capital_structure ||
        !attempt.yearly_structures
      ) {
        setCapitalInputs({
          interest_bearing_dept: 1992,
          excess_cash: 1115,
          cost_of_dept: 0.0261,
          dept_per_total_capital_ratio: 0.326,
          risk_free_rate: 0.007,
          company_beta: 0.98,
          expected_market_return: 0.13,
          perpetuity_growth_rate: 0.025,
        });
        setYearlyInputs({
          year1: {
            type: YEAR1,
            mid_range_revenues: 3058,
            ebitda: 817,
            depreciation: 166,
            amortization: 0,
            effective_tax_rate: 0.096,
            net_wc_investment: -20,
            net_capex_investment: 81,
            net_gw_ai: 0,
            discount_period: 0.5,
          },
          year2: {
            type: YEAR2,
            mid_range_revenues: 3486,
            ebitda: 1022,
            depreciation: 114,
            amortization: 0,
            effective_tax_rate: 0.1,
            net_wc_investment: -262,
            net_capex_investment: 96,
            net_gw_ai: 0,
          },
          year3: {
            type: YEAR3,
            mid_range_revenues: 3758,
            ebitda: 1219,
            depreciation: 152,
            amortization: 0,
            effective_tax_rate: 0.095,
            net_wc_investment: 198,
            net_capex_investment: 110,
            net_gw_ai: 0,
          },
          tv: {
            type: TV,
            proforma_ebitda: 1243,
            depreciation: 155,
            amortization: 0,
            effective_tax_rate: 0.095,
            net_wc_investment: 224,
            net_capex_investment: 120,
            net_gw_ai: 0,
          },
        });
      } else {
        setCapitalInputs({ ...attempt.capital_structure });
        const sortedStructures = [...attempt.yearly_structures].sort(
          (a, b) => a.type - b.type
        );

        const partialYearlyStructure: PartialYearlyStructure = {
          year1: { ...sortedStructures[0] },
          year2: { ...sortedStructures[1] },
          year3: { ...sortedStructures[2] },
          tv: {
            ...sortedStructures[3],
          },
        };

        setYearlyData({ ...partialYearlyStructure });
        setYearlyInputs({ ...partialYearlyStructure });
      }
    }
  }, [attempt, player]);

  useEffect(() => {
    if (isMounted.current) {
      if (attempt && attempt.capital_structure && attempt.yearly_structures) {
        const partialYearlyStructure: PartialYearlyStructure = {
          year1: { ...attempt.yearly_structures[0] },
          year2: { ...attempt.yearly_structures[1] },
          year3: { ...attempt.yearly_structures[2] },
          tv: {
            ...attempt.yearly_structures[3],
          },
        };

        // setYearlyInputs({ ...partialYearlyStructure });
        setYearlyData({ ...partialYearlyStructure });
        return;
      }
      const {
        cost_of_dept,
        dept_per_total_capital_ratio,
        risk_free_rate,
        company_beta,
        expected_market_return,
        perpetuity_growth_rate,
      } = capitalInputs;

      const {
        year1: { effective_tax_rate: eTaxRateY1 },
        year2: { effective_tax_rate: eTaxRateY2 },
        year3: { effective_tax_rate: eTaxRateY3 },
      } = yearlyInputs;

      const afterTaxCostOfDept = fn.afterTaxCostOfDept(
        cost_of_dept,
        eTaxRateY1,
        eTaxRateY2,
        eTaxRateY3
      );

      const weightedCostOfDept = fn.weightedCostOfDept(
        dept_per_total_capital_ratio,
        afterTaxCostOfDept
      );

      const marketRiskPremium = fn.marketRiskPremium(
        expected_market_return,
        risk_free_rate
      );

      const costOfEquity = fn.costOfEquity(
        risk_free_rate,
        company_beta,
        marketRiskPremium
      );

      const equityPerTotalCapitalRatio = fn.equityPerTotalCapitalRatio(
        dept_per_total_capital_ratio
      );

      const weightedCostOfEquity = fn.weightedCostOfEquity(
        equityPerTotalCapitalRatio,
        costOfEquity
      );
      const weightedCostOfCapital = fn.weightedCostOfCapital(
        weightedCostOfEquity,
        weightedCostOfDept
      );

      setCapitalData({
        ...capitalInputs,
        after_tax_cost_of_dept: afterTaxCostOfDept,
        weighted_cost_of_dept: weightedCostOfDept,
        market_risk_premium: marketRiskPremium,
        cost_of_equity: costOfEquity,
        equity_per_total_capital_ratio: equityPerTotalCapitalRatio,
        weighted_cost_of_equity: weightedCostOfEquity,
        weighted_cost_of_capital: weightedCostOfCapital,
      });

      const newYearlyData = {} as PartialYearlyStructure;
      yearlyKeys.forEach((key) => {
        newYearlyData[key] = { ...yearlyData[key], ...yearlyInputs[key] };
      });

      yearlyKeys.forEach((col) => {
        if (col !== 'tv') {
          const ebitdaMargin = fn.ebitdaMargin(
            yearlyInputs[col].ebitda,
            yearlyInputs[col].mid_range_revenues
          );
          newYearlyData[col].ebitda_margin = ebitdaMargin;

          newYearlyData[col].proforma_ebitda = fn.proformaEbitda(
            yearlyInputs[col].ebitda
          );
        }

        newYearlyData[col].noplat = fn.noplat(
          newYearlyData[col].proforma_ebitda,
          newYearlyData[col].depreciation,
          newYearlyData[col].effective_tax_rate
        );

        newYearlyData[col].unlevered_fcff = fn.unleveredFcff(
          newYearlyData[col].noplat,
          newYearlyData[col].depreciation,
          newYearlyData[col].net_capex_investment,
          newYearlyData[col].net_wc_investment,
          newYearlyData[col].net_gw_ai
        );
      });

      const discountY1 = newYearlyData.year1.discount_period;
      newYearlyData.year2.discount_period = fn.discountPeriodYear2(discountY1);
      newYearlyData.year3.discount_period = fn.discountPeriodYear3(discountY1);
      newYearlyData.tv.discount_period = fn.discountPeriodTv(discountY1);

      yearlyKeys.forEach((col) => {
        newYearlyData[col].discount_factor = fn.discountFactor(
          weightedCostOfCapital,
          newYearlyData[col].discount_period
        );

        if (col === 'tv') {
          newYearlyData[col].dcff = fn.dcffTv(
            newYearlyData[col].unlevered_fcff,
            perpetuity_growth_rate,
            weightedCostOfCapital,
            newYearlyData[col].discount_factor
          );
        } else {
          newYearlyData[col].dcff = fn.dcff(
            newYearlyData[col].unlevered_fcff,
            newYearlyData[col].discount_factor
          );
        }
      });

      setYearlyData(newYearlyData);
    }
  }, [capitalInputs, yearlyInputs]);

  const state = {
    capitalData,
    setCapitalData,
    capitalInputs,
    setCapitalInputs,
    yearlyData,
    setYearlyData,
    yearlyInputs,
    setYearlyInputs,
  };
  return <DCFFContext.Provider value={state}>{children}</DCFFContext.Provider>;
};

const useDCFFProvider = () => {
  const context = useContext(DCFFContext);
  if (context === undefined) {
    throw new Error('useDataProvider cannot be used outside DataProvider!');
  }
  return context;
};

export { DCFFProvider, useDCFFProvider };
