import React from 'react';
import { useLocation } from 'react-router-dom';

type State = {
  currentPath: string;
};

type Actions = undefined;

const LayoutStateContext = React.createContext<State | undefined>(undefined);
const LayoutActionsContext = React.createContext<Actions | undefined>(
  undefined
);

const LayoutProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <LayoutStateContext.Provider value={{ currentPath }}>
      <LayoutActionsContext.Provider value={undefined}>
        {children}
      </LayoutActionsContext.Provider>
    </LayoutStateContext.Provider>
  );
};

const useLayoutState = () => {
  const context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }

  return context;
};

const useLayoutActions = () => {
  const context = React.useContext(LayoutActionsContext);
  if (context === undefined) {
    throw new Error('useLayoutActions must be used within a LayoutProvider');
  }

  return context;
};

export { useLayoutState, useLayoutActions, LayoutProvider };
