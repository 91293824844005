import React from 'react';

import { Card, Typography } from 'antd';
import Answer from './Answer';

import type { Question, Answer as AnswerType } from 'types';
import styled from 'styled-components';

type Props = {
  question: Question;
  className?: string;
  selectedAnswerId?: number;
  onSelectAnswer: (id: number) => void;
  showFeedback?: boolean;
};

const QuestionCard: React.FC<Props> = ({
  question,
  className,
  selectedAnswerId,
  onSelectAnswer,
  showFeedback,
}) => {
  const renderAnswer = (answer: AnswerType, index: number) => {
    const isSelected = answer.id === selectedAnswerId;

    return (
      <Answer
        answer={answer}
        selected={isSelected}
        onSelect={onSelectAnswer}
        showFeedback={showFeedback}
        key={index}
      />
    );
  };

  return (
    <Card className={className}>
      <Typography.Title level={4}>{question.text}</Typography.Title>
      {question.answers.map(renderAnswer)}
    </Card>
  );
};

export default styled(QuestionCard)`
  border: none;

  & .ant-card-body {
    padding: 0;
  }
`;
