import React from 'react';

import { Button, Form, Input, Modal } from 'antd';

import { useCreateTrainer } from 'modules/AdminDashboard/actions';
import { generatePassword } from 'services/passwordGenerator';

type Props = {
  visible: boolean;
  hide: () => void;
};

const CreateTrainerModal: React.FC<Props> = ({ visible, hide }) => {
  const { mutate: createTrainer } = useCreateTrainer();
  const [form] = Form.useForm();

  const handleGeneratePassword = () => {
    const random_pass = generatePassword();
    form.setFieldsValue({ password: random_pass });
  };

  return (
    <Modal
      title="Create a new trainer"
      visible={visible}
      onCancel={hide}
      footer={[
        <Button
          type="primary"
          key="key"
          onClick={() => {
            createTrainer({
              username: form.getFieldValue('username'),
              password: form.getFieldValue('password'),
            });
            hide();
          }}
        >
          Create
        </Button>,
        <Button onClick={() => handleGeneratePassword()}>
          Generate password
        </Button>,
      ]}
    >
      <Form form={form} labelCol={{ span: 6 }}>
        <Form.Item required label="Username" name="username">
          <Input />
        </Form.Item>
        <Form.Item required label="Password" name="password">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTrainerModal;
