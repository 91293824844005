import React from 'react';

import { Table, Typography, Divider } from 'antd';

import { PLAYER_TYPES, SELLER } from 'stateConstants';
import { Loading } from 'components';
import { useCurrentStep } from 'hooks';
import { useNCStep } from './actions';
import { usePlayersCurrentStep } from 'hooks/trainerActions';

const { Title } = Typography;

const NegotiationChoicesStep: React.FC = () => {
  const {
    data: currentStep,
    isLoading: isCurrentStepLoading,
  } = useCurrentStep();
  const {
    data: players,
    isLoading: isPlayersLoading,
  } = usePlayersCurrentStep();

  if (isPlayersLoading || isCurrentStepLoading) {
    return <Loading />;
  }

  const dataSource = (players || []).sort((a: any, b: any) =>
    a.username.localeCompare(b.username)
  );
  return (
    <div>
      <Title level={4}>
        While Seller Team is reviewing the offers received, the Buyers teams are
        getting some feedback already and they need to make some easy choices.
        Once Buyers have made their choices, you can click Next.
      </Title>
      <Divider />
      <Table
        pagination={false}
        rowKey="id"
        columns={[
          { title: 'Team', dataIndex: 'username' },
          { title: 'Member count', dataIndex: 'person_count' },
          {
            title: 'Submissions',
            dataIndex: 'negotiation_choices_attempts_count',
          },
        ]}
        dataSource={dataSource.filter((p: any) => p.type !== SELLER)}
      />
    </div>
  );
};

export default NegotiationChoicesStep;
