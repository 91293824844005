import { useAuthQuery } from 'hooks';
import type { BilateralDocsPlayer } from 'types';

export const useBilateralDocsPlayers = (stageId: number) => {
  return useAuthQuery<BilateralDocsPlayer[]>([
    'bilateral-docs',
    stageId,
    'players',
  ]);
};
