import React from 'react';

import { Table, Typography, Divider } from 'antd';

import { PLAYER_TYPES } from 'stateConstants';
import { Loading } from 'components';
import { useCurrentStep } from 'hooks';
import { useTCStep } from './actions';

const TradingComparablesStep: React.FC = () => {
  const {
    data: currentStep,
    isLoading: isCurrentStepLoading,
  } = useCurrentStep();
  const { data: players, isLoading: isPlayersLoading } = useTCStep(
    currentStep?.id || 0
  );

  if (isPlayersLoading || isCurrentStepLoading) {
    return <Loading />;
  }

  const dataSource = (players || []).sort((a, b) =>
    a.username.localeCompare(b.username)
  );
  return (
    <div>
      <Typography.Title level={4}>
        Here, similarly to the previous Step, Participants would normally be required to enter their Trading Comps outputs.
        However, due to time constraints, the trading comp data has already been inserted into the Simulation. They can just
        review it and click Submit to proceed.
      </Typography.Title>

      <Table
        pagination={false}
        rowKey="id"
        columns={[
          { title: 'Team', dataIndex: 'username' },
          { title: 'Member count', dataIndex: 'person_count' },
          {
            title: 'Type',
            dataIndex: 'type',
            render(value: keyof typeof PLAYER_TYPES) {
              return PLAYER_TYPES[value];
            },
          },
          {
            title: 'Step Finalized',
            dataIndex: 'tc_attempts_count',
            render(value: number) {
              return value !== 0 ? "Yes" : "No";
            }
          },
        ]}
        dataSource={dataSource}
      />
    </div>
  );
};

export default TradingComparablesStep;
