import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

export const BuyerDescription = () => (
  <StepDescription>
    <Typography.Paragraph>
      As part of your preparation for the Transaction and the ensuing
      negotiations, you will need to{' '}
      <strong>perform a valuation of the Target</strong>, on a
      stand-alone basis, as it was seen by an independent observer, PRIOR TO THE
      TRANSACTION ANNOUNCEMENT.
    </Typography.Paragraph>
    <Typography.Paragraph>
      For this purpose, you will be offered access to several documents which
      you can acquire in exchange for coins. In order to simulate a real-world
      environment where various constraints (such as time, cost, access, etc.)
      somehow always limit your access to a complete picture of a certain
      situation, the amount of coins you will be gen does not allow you to
      acquire all documents being offered. Therefore, you must make a careful
      selection of the documents you acquire, some of which are required, others
      which are optional - while maximizing the use of coins. Please scroll down
      to review the available documents, make your selection and click{' '}
      <strong>Submit</strong>.
    </Typography.Paragraph>
  </StepDescription>
);

export const SellerDescription = () => (
  <StepDescription>
    <Typography.Paragraph>
      As part of your preparation for the Transaction and the ensuing
      negotiations, you will need to{' '}
      <strong>perform a valuation of your client</strong>, the Seller, on a
      stand-alone basis, as it was seen by an independent observer, PRIOR TO THE
      TRANSACTION ANNOUNCEMENT.
    </Typography.Paragraph>
    <Typography.Paragraph>
      For this purpose, you will be offered access to several
      documents which you can acquire in exchange for coins. In order to
      simulate a real-world environment where various constraints (such as time,
      cost, access, etc.) somehow always limit your access to a complete picture
      of a certain situation, the amount of coins you will be given does not
      allow you to acquire all documents being offered. Therefore, you must make
      a careful selection of the documents you acquire, some of which are
      required, others which are optional - while maximizing the use of coins.
      Please scroll down to review the available documents, make your selection
      and click <strong>Submit</strong>.
    </Typography.Paragraph>
  </StepDescription>
);

const StepDescription = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`;
