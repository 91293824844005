import React from 'react';

import { InputNumber, Table, Typography } from 'antd';

import { Synergy, SynergyField } from 'types';
import { useListSynergies } from './actions';
import { useSessionMember } from 'hooks/traineeActions';
import { Loading } from 'components';

const { Title, Text } = Typography;

type Props = {
  title: string;
  dataSource: SynergyField[];
  state: { [k: number]: Synergy };
  updateState: (
    id: number,
    field: 'probability' | 'value'
  ) => (value: number) => void;
  allowDisabled?: boolean;
};

const SynergyTable: React.FC<Props> = ({
  title,
  dataSource,
  state,
  updateState,
  allowDisabled,
}) => {
  const { data: member, isLoading: isMemberLoading } = useSessionMember();
  const { data: synergies, isLoading: isSynergiesLoading } = useListSynergies(
    member?.id || 0
  );

  if (isMemberLoading || isSynergiesLoading || !synergies || !member) {
    return <Loading />;
  }

  return (
    <Table
      rowKey="id"
      columns={[
        {
          title,
          render: (text, record) => {
            return (
              <div>
                {/* <Title level={5}>{record.title}</Title> */}
                <Text>{record.title}</Text>
              </div>
            );
          },
        },
        {
          title: 'Probability (%)',
          render: (text, record) => {
            const index = synergies.findIndex(
              (s) => s.synergy_field === record.id
            );
            const disabled = index !== -1;
            const value =
              allowDisabled && disabled
                ? synergies[index].probability * 100
                : state[record.id]?.probability;

            return (
              <InputNumber
                min={0}
                max={100}
                value={value}
                disabled={allowDisabled && disabled}
                onChange={updateState(record.id, 'probability')}
              />
            );
          },
        },
        {
          title: 'Value (Mil$)',
          render: (text, record) => {
            const index = synergies.findIndex(
              (s) => s.synergy_field === record.id
            );
            const disabled = index !== -1;
            const value =
              allowDisabled && disabled
                ? synergies[index].value
                : state[record.id]?.value;

            return (
              <InputNumber
                min={0}
                value={value}
                disabled={allowDisabled && disabled}
                onChange={updateState(record.id, 'value')}
              />
            );
          },
        },
      ]}
      dataSource={dataSource}
      pagination={false}
      bordered
    />
  );
};

export default SynergyTable;
