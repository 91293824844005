import { useQueryClient } from "react-query";
import useQuerySubscription from "./useQuerySubscription";
import { invalidateIfIncludes } from 'services';
import { useEffect, useState } from "react";
import useAuthQuery from "./useAuthQuery";
import { useAuthState } from "contexts";

const usePageFocus = () => {
    const [isFocused, setIsFocused] = useState(document.hasFocus());

    useEffect(() => {
        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            setIsFocused(false);
        };

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);

    return isFocused;
}

export const useTraineeDashboardSocket = () => {
    const queryClient = useQueryClient();
    const isVisible = usePageFocus();
    const { userId } = useAuthState();
    useEffect(() => {
        if (isVisible) {
            // console.log("Should invalidate");
            queryClient.invalidateQueries({
                predicate: invalidateIfIncludes(['sessions']),
            });
        }
    }, [isVisible, queryClient]);
    return useQuerySubscription(`user/${userId}/`, (data) => {
        switch (data.type) {
            case 'submit_received':
                queryClient.invalidateQueries({
                    predicate: invalidateIfIncludes(['sessions']),
                });
                queryClient.invalidateQueries({
                    predicate: invalidateIfIncludes(['current-step']),
                });
                break;
            case 'session_created':
                queryClient.invalidateQueries({
                    predicate: invalidateIfIncludes(['sessions']),
                });
                break;
            case 'session_started':
                queryClient.invalidateQueries({
                    predicate: invalidateIfIncludes(['sessions']),
                });
                break;
            case 'session_paused':
                queryClient.invalidateQueries({
                    predicate: invalidateIfIncludes(['sessions']),
                });
                break;
            case 'session_resumed':
                queryClient.invalidateQueries({
                    predicate: invalidateIfIncludes(['sessions']),
                });
                break;
            case 'session_finished':
                queryClient.invalidateQueries({
                    predicate: invalidateIfIncludes(['sessions']),
                });
                break;
        }
    });
}