import { computeFunction } from 'services';

export const getImpliedMultiples = computeFunction(
  (equityValue, netDebt, ebitda) => {
    return (equityValue + netDebt) / ebitda;
  }
);

// Football field

export const getYearlyValue = computeFunction(
  (wacc, discountPeriod, unleveredFcff) => {
    return (1 + wacc) ** -discountPeriod * unleveredFcff;
  }
);

export const getYearlyValueTV = computeFunction(
  (equityPerTCR, pGrowthRate, wacc, wcoc) => {
    return (equityPerTCR * (1 + pGrowthRate)) / (wacc * wcoc);
  }
);
