import React from 'react';
import { Typography } from 'antd';
import Counter from 'modules/Counter';

const ReadingsBilateralDocsStep: React.FC = () => {
  return (
    <>
      <Counter totalTime={180} />
      <Typography.Title level={4}>
        Please instruct participants to review the finalised NDA and Process Letter.
      </Typography.Title>
      <Typography.Title level={4}>
        The reading should be done in about 3 minutes, after which you can proceed with the Next button.
      </Typography.Title>
    </>
  );
};

export default ReadingsBilateralDocsStep;
