import { useEffect, useState } from 'react';

import { useAuthState } from 'contexts';
import settings from '../settings';
import axios from 'axios';
import AuthHeader from '../services/AuthHeader';
import useSessionId from './useSessionId';

export type EventType = {
  type: string;
  data: unknown;
};

const useQuerySubscription = (
  endpoint: string,
  eventReducer: (data: EventType) => void,
  deps: unknown[] = []
) => {
  const sessionId = useSessionId(); // Dynamically obtain sessionId
  const { token, person } = useAuthState();

  const [connected, setConnected] = useState(false);
  const [websocket, setWebSocket] = useState<WebSocket | null>(null); // Store the WebSocket instance

  const connectWebSocket = () => {
    const url = endpoint.includes("sessions") ? `${settings.SOCKET_URL}/sessions/${sessionId}/?token=${token}` : `${settings.SOCKET_URL}/${endpoint}?token=${token}`;
    const ws = new WebSocket(url);
    setWebSocket(ws);

    ws.onopen = () => {
      setConnected(true);
      ws.send('PING');
    };

    ws.onclose = () => {
      setConnected(false);
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        eventReducer(data);
      } catch (e) {
        // data is a string, most likely "PONG"
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (websocket) websocket.close();
    };
  }, [sessionId, ...deps]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Page is hidden
        if (websocket) websocket.close();
      } else {
        // Page is visible
        // eslint-disable-next-line no-lonely-if
        if (!connected) connectWebSocket();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [connected, websocket]);

  return [connected];
};

export default useQuerySubscription;
