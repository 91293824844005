import React from 'react';

import { Row, Col, Button, Typography, Divider } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { DCFFProvider, useDCFFProvider } from 'contexts';
import { useCurrentStep, useSessionId } from 'hooks';
import { useSessionMember, useSubmitCurrentStep } from 'hooks/traineeActions';
import {
  CapitalStructure,
  DCFFAttempt,
  DCFStepType,
  Player,
  YearlyStructureDef,
} from 'types';
import CapitalStructureTable from './CapitalStructureTable';
import YearlyTable from './YearlyTable';
import { useRetrieveDcff, useSubmitDcff } from './actions';
import GlossaryWrappedText from 'modules/TraineeSession/Glossary/GlossaryWrappedText';

const DCFStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
  attempt: DCFFAttempt;
}> = ({ player, ownPerspective, attempt }) => {
  const { data: step } = useCurrentStep<DCFStepType>();
  const { data: member } = useSessionMember();
  const { capitalData, yearlyData } = useDCFFProvider();
  // const { mutate: submitDcff } = useSubmitDcff(step?.id || 0);
  const { mutate: submitDcff } = useSubmitCurrentStep();
  const session_id = useSessionId();

  const getSubmitStatus = () => {
    return !!attempt;
  };

  return (
    <div>
      <GlossaryWrappedText
        text={`
        All non percentage figures should be expressed in millions and as
        positive values unless specifically indicated otherwise.
      `}
        WrapperComponent={Typography.Paragraph}
      />
      <GlossaryWrappedText
        text={`
        DUE TO LIMITED TIME FOR YOUR SESSION, the correct data on this page has
        already been filled in. Feel free to change any of it as you see fit or
        accept it entirely.
        `}
        WrapperComponent={Typography.Paragraph}
      />
      {attempt && (
        <>
          <Divider />
          <Typography.Title level={4}>
            Submission from {attempt?.last_updated_by?.name}
          </Typography.Title>
          <Divider />
        </>
      )}
      <Row gutter={50}>
        <Col span={8} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}>
          <CapitalStructureTable submitted={!!attempt} />
        </Col>
        <Col span={16}>
          <YearlyTable submitted={!!attempt} />
        </Col>
      </Row>
      {ownPerspective && (
        <Footer>
          <Button
            style={{ display: 'flex', alignItems: 'center' }}
            type="primary"
            onClick={() => {
              const yearlyStructuresArray = Object.values(
                yearlyData
              ) as YearlyStructureDef[];

              // Sort the array based on the .type property
              yearlyStructuresArray.sort((a, b) => a.type - b.type);

              submitDcff({
                capital_structure: capitalData as CapitalStructure,
                yearly_structures: yearlyStructuresArray,
                member: member?.id || 0,
                player: member?.player || 0,
                step: step?.id || 0,
                session_id,
              });
            }}
            disabled={getSubmitStatus()}
          >
            <ArrowRightOutlined /> Submit
          </Button>
        </Footer>
      )}
    </div>
  );
};

const Footer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: right;
`;

export default DCFStep;
