import React from 'react';

import { Card, Tabs } from 'antd';
import SessionTable from './SessionTable';

import { useKlasses } from '../actions';
import { Loading } from 'components';

const Dashboard: React.FC = () => {
  const { data: klasses, isLoading } = useKlasses();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Card loading={isLoading}>
      <Tabs tabPosition="left">
        {klasses?.map((klass) => (
          <Tabs.TabPane tab={klass.name} key={klass.id}>
            <SessionTable klassId={klass.id} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Card>
  );
};

export default Dashboard;
