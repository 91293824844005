import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import settings from 'settings';

type Props = {
  file: string;
};

const PdfViewer: React.FC<Props> = ({ file }) => {
  const [nbPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  let url;
  const goal = process.env.REACT_APP_GOAL;
  if (['production', 'stage'].includes(goal ?? '')) {
    url = file; //.split('s3').join('s3.us-west-2');
  } else {
    url = settings.API_URL.split('/api')[0] + file;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>
            Page {pageNumber || (nbPages ? 1 : '--')} of {nbPages || '--'}
          </p>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={() =>
                setPageNumber((prevPageNumber) => prevPageNumber - 1)
              }
            >
              Previous
            </button>
            <button
              type="button"
              disabled={pageNumber >= nbPages}
              onClick={() =>
                setPageNumber((prevPageNumber) => prevPageNumber + 1)
              }
            >
              Next
            </button>
          </div>
        </div>
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

export default PdfViewer;
