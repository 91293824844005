import { Button, InputNumber, List, Typography } from 'antd';
import { ListItem, Loading } from 'components';
import { useSessionPlayer } from 'hooks/traineeActions';
import React, { useEffect } from 'react';
import { BUYER, SELLER } from 'stateConstants';
import styled from 'styled-components';
import BuyerElimination from './BuyerElimination';
import SellerElimination from './SellerElimination';
import { Offer, Player, SellerEliminationOffers } from 'types';

const { Paragraph, Text } = Typography;

const EliminationStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
  data: Offer | SellerEliminationOffers;
  offersAreLoading: boolean;
}> = ({ player, ownPerspective, data, offersAreLoading }) => {
  if (!player) return <Text>Player not found</Text>;
  if (offersAreLoading) return <Loading />;
  return (
    <>
      {player.type === BUYER && (
        <BuyerElimination
          offer={data as Offer}
          player={player}
          ownPerspective={ownPerspective}
        />
      )}
      {player.type === SELLER && (
        <SellerElimination
          player={player}
          apiOffers={data as SellerEliminationOffers}
          ownPerspective={ownPerspective}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: right;
`;

export default EliminationStep;
