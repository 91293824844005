import useSessionId from './useSessionId';
import useAuthQuery from './useAuthQuery';
import type { Step } from 'types';

const useCurrentStep = <StepType = Step>() => {
  const id = useSessionId();

  return useAuthQuery<StepType>(['sessions', id, 'current-step']);
};

export default useCurrentStep;
