import React from 'react';

import styled from 'styled-components';
import { Radio, Typography } from 'antd';

import type { Answer as AnswerType } from 'types';

type Props = {
  className?: string;
  answer: AnswerType;
  selected: boolean;
  onSelect: (id: number) => void;
  showFeedback?: boolean;
};

const answerIsCorrect = (answer: AnswerType) => answer.points > 0;

const Answer: React.FC<Props> = ({
  className,
  answer,
  selected,
  onSelect,
  showFeedback,
}) => {
  const renderFeedbackMessage = () => {
    const type = answerIsCorrect(answer) ? 'success' : 'danger';

    return <FeedbackMessage type={type}> ({answer.message})</FeedbackMessage>;
  };

  return (
    <RadioBlock
      className={className}
      checked={selected}
      onClick={() => onSelect(answer.id)}
    >
      {answer.text}
      {selected && showFeedback && renderFeedbackMessage()}
    </RadioBlock>
  );
};

const RadioBlock = styled(Radio)`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const FeedbackMessage = styled(Typography.Text)`
  display: block;
`;

export default styled(Answer)`
  ${(props) => {
    if (!props.selected) return ``;
    if (!props.showFeedback) return ``;

    const { colors } = props.theme;
    const color = answerIsCorrect(props.answer) ? colors.success : colors.error;

    return `
      .ant-radio-checked .ant-radio-inner {
        border-color: ${color};
      }
      
      .ant-radio-checked .ant-radio-inner:after {
        background-color: ${color};
      }
      
      .ant-radio:hover .ant-radio-inner {
        border-color: ${color};
      }
    `;
  }}
`;
