import React from 'react';

import { Table, Typography, Divider } from 'antd';

import { PLAYER_TYPES } from 'stateConstants';
import { Loading } from 'components';
import { useCurrentStep } from 'hooks';
import { useDAStep } from './actions';

const DocAcquisitionStep: React.FC = () => {
  const {
    data: currentStep,
    isLoading: isCurrentStepLoading,
  } = useCurrentStep();
  const { data: players, isLoading: isPlayersLoading } = useDAStep(
    currentStep?.id || 0
  );

  if (isPlayersLoading || isCurrentStepLoading) {
    return <Loading />;
  }

  const dataSource = (players || []).sort((a, b) =>
    a.username.localeCompare(b.username)
  );
  return (
    <div>
      <Typography.Title level={4}>
        All participants are currently involved in a competitive process of
        acquiring several documents that would assist them in the valuation of
        the Target.
      </Typography.Title>
      <Typography.Title level={4}>
        These documents include broker reports, peer group listings and
        precedent transactions.
      </Typography.Title>
      <Typography.Title level={4}>
        When needed please highlight to Participants that there is one Required document
        which they must select in order to finalize their Submission.
      </Typography.Title>
      <Typography.Title level={4}>
        When the number in the Member count column equals the number in the
        Submissions column for all teams, it implies that all participants have
        acquired their documents and you can click the Next button to allow
        everyone to review what they have acquired.
      </Typography.Title>
      <Divider />
      <Table
        pagination={false}
        rowKey="id"
        columns={[
          { title: 'Team', dataIndex: 'username' },
          { title: 'Member count', dataIndex: 'person_count' },
          {
            title: 'Type',
            dataIndex: 'type',
            render(value: keyof typeof PLAYER_TYPES) {
              return PLAYER_TYPES[value];
            },
          },
          {
            title: 'Submissions',
            dataIndex: 'da_attempts_count',
          },
        ]}
        dataSource={dataSource}
      />
    </div>
  );
};

export default DocAcquisitionStep;
