import React from 'react';

import styled from 'styled-components';
import { Button, Card, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

type Props = {
  message: string;
};

const NotPlayingMessage: React.FC<Props> = ({ message }) => {
  const history = useHistory();

  return (
    <Break>
      {
        message === "Finished" ? <div style={{display: "flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <Typography.Title>Session finished early!</Typography.Title>
        <Button onClick={()=>{history.push("/")}}>Go to Dashboard</Button>
        </div> :       <Typography.Title level={1}>{message}</Typography.Title>

      }
    </Break>
  );
};

const Break = styled(Card)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default NotPlayingMessage;
