import { useAuthQuery } from 'hooks/index';
import { KlassAdminView } from 'modules/AdminDashboard/types';

// export const useKlassById = (klassId: number) => {
//   const { data: klass } = useAuthQuery<KlassAdminView>(['klasses', klassId]);
//   return klass;
// };

export const useKlassById2 = (klassId: number) => {
  const { data: klass } = useAuthQuery<KlassAdminView>([
    'klasses',
    klassId,
    'get_klass_by_id',
  ]);
  return klass;
};
