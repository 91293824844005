import React, { useEffect } from 'react';

import { Table, Typography, Divider } from 'antd';

import { PLAYER_TYPES } from 'stateConstants';
import { Loading } from 'components';
import { useCurrentStep } from 'hooks';
import { useBuyerProposalPlayers, useSellerProposalPlayers } from './actions';
import styled from 'styled-components';
import {
  usePlayersCurrentStep,
  useRetrieveCurrentStep,
} from 'hooks/trainerActions';

const { Title } = Typography;

type Proposals = {
  username: string;
  person_count: number;
  type: number;
  proposals: boolean;
};

const NegotiationChoicesStep: React.FC = () => {
  const {
    data: currentStep,
    isLoading: isCurrentStepLoading,
  } = useCurrentStep();
  const {
    data: proposals,
    isLoading: areProposalsLoading,
  } = usePlayersCurrentStep();

  // useEffect(() => {
  //   if (proposals && sellerPlayers && buyerPlayers)
  //     sellerSource.forEach((s, idx) => {
  //       proposals.push({
  //         username: s.username,
  //         person_count: s.person_count,
  //         type: s.type,
  //         proposals:
  //           s.seller_proposals_count !== 0 ||
  //           buyerSource[idx].buyer_proposals_count !== 0,
  //       });
  //     });
  // }, [sellerPlayers, buyerPlayers, proposals]);

  if (areProposalsLoading || isCurrentStepLoading || !proposals) {
    return <Loading />;
  }
  return (
    <Wrapper>
      <Title level={4}>
        Buyers are now expected to enter the details of their Preliminary
        Offers, which will be reviewed by their Company’s Board before being
        submitted to the Seller.
      </Title>
      <Divider />
      <Table
        // title={() => 'Sellers'}
        pagination={false}
        rowKey="id"
        columns={[
          { title: 'Team', dataIndex: 'username' },
          { title: 'Member count', dataIndex: 'person_count' },
          {
            title: 'Type',
            dataIndex: 'type',
            render(value: keyof typeof PLAYER_TYPES) {
              return PLAYER_TYPES[value];
            },
          },
          {
            title: 'Step Finalized',
            dataIndex: 'offer',
            render(value) {
              return value ? 'Yes' : 'No';
            },
          },
        ]}
        dataSource={proposals}
      />
      <Table
        title={() => 'Buyer Proposals'}
        pagination={false}
        rowKey="username"
        columns={[
          { title: 'Team', dataIndex: 'username' },
          { title: 'Cash Amount', dataIndex: 'cash_amount' },
          { title: 'Stock Exchange Rate', dataIndex: 'stock_exchange_rate' },
          { title: 'Cash In Lieu', dataIndex: 'cash_in_lieu' },
          { title: 'Deferred Cash', dataIndex: 'deferred_cash' },
          { title: 'Pension Liabilities', dataIndex: 'pension_liabilities' },
          { title: 'Target Debt', dataIndex: 'target_dept' },
          { title: 'Breakup Fee', dataIndex: 'breakup_fee' },
        ]}
        dataSource={proposals
          ?.filter((p: any) => p.offer)
          .map((p: any) => ({
            username: p.username,
            ...p.offer,
          }))}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export default NegotiationChoicesStep;
