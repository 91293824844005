import { useAuthQuery } from 'hooks';

import { Session, Person } from 'types';
import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { createPostRequest } from 'services/index';

export const useSessions = () => useAuthQuery<Session[]>('sessions');

export const useSessionsOfUser = (userId: number) =>
  useAuthQuery<Session[]>(`users/${userId}/get_sessions_of_user`);

export const usePersons = () => useAuthQuery<Person[]>('users/me/persons');

const createPerson = createPostRequest<
  { name: string; user_id: number },
  unknown
>(`users/create_person/`, 'post');

export const useCreatePerson = () => {
  const queryClient = useQueryClient();

  return useMutation(createPerson, {
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data || 'An unexpected error occurred';
      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(`users/me/persons`);
    },
  });
};
