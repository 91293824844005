import React, { useEffect, useState } from 'react';

import {
  Button,
  Input,
  InputNumber,
  Switch,
  Table,
  Typography,
  Divider,
} from 'antd';
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  SendOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import { Space } from 'components';
import { useCurrentStep, useSessionId } from 'hooks';
import { useSessionMember, useSubmitCurrentStep } from 'hooks/traineeActions';
import { formatters } from 'services';
import type {
  Player,
  TradingComparableAttempt,
  TradingComparableData,
  TradingComparableInputs,
} from 'types';
import * as fn from './functions';
import { useRetrieveTradingAttempt, useSubmitTradingComps } from './actinos';
import GlossaryWrappedText from 'modules/TraineeSession/Glossary/GlossaryWrappedText';

type StateComparable<T> = Partial<T> & {
  key: number;
  ignore: boolean;
};

const TradingComparablesStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
  attempt: TradingComparableAttempt;
}> = ({ player, ownPerspective, attempt }) => {
  const { data: step } = useCurrentStep();
  const { data: member } = useSessionMember();

  const { mutate: submitComparables } = useSubmitCurrentStep();

  const [comparableInputs, setComparableInputs] = useState<
    StateComparable<TradingComparableInputs>[]
  >([]);
  const [comparableData, setComparableData] = useState<
    StateComparable<TradingComparableData>[]
  >([]);
  const [lastKey, setLastKey] = useState(1000000);
  const sessionId = useSessionId();

  const addComparable = () => {
    setComparableInputs([...comparableInputs, { key: lastKey, ignore: false }]);
    setLastKey(lastKey + 1);
  };

  const xilinx: StateComparable<TradingComparableInputs> = {
    name: 'Xilinx, Inc.',
    ticker: 'NASDAQGS:XLNS',
    ltm_ebitda: 1016.2,
    market_cap: 27681.15,
    wk52_low: 68.12,
    wk52_high: 123.85,
    net_dept: 952,
    key: 0,
    ignore: true,
  };

  const initialState: StateComparable<TradingComparableInputs>[] = [
    {
      name: 'Marvell Technology, Inc.',
      ticker: 'NASDAQGS:MRVL',
      ltm_ebitda: 457.53,
      market_cap: 27074.47,
      net_dept: 761,
      key: 1,
      ignore: false,
    },
    {
      name: 'Maxim Integrated Products, Inc.',
      ticker: 'NASDAQGS:MXIM',
      ltm_ebitda: 823.94,
      market_cap: 17474.69,
      net_dept: -561,
      key: 2,
      ignore: false,
    },
    {
      name: 'Microchip Technology Incorporated',
      ticker: 'NASDAQGS:MCHP',
      ltm_ebitda: 3644.29,
      market_cap: 55781.69,
      net_dept: 8996,
      key: 3,
      ignore: false,
    },
    {
      name: 'Monolithic Power Systems, Inc.',
      ticker: 'NASDAQGS:MPWR',
      ltm_ebitda: 195.26,
      market_cap: 13976.03,
      net_dept: -509,
      key: 4,
      ignore: false,
    },
    {
      name: 'NXP Semiconductors N.V.',
      ticker: 'NASDAQGS:MXIM',
      ltm_ebitda: 2398.44,
      market_cap: 37935.4,
      net_dept: 6527,
      key: 5,
      ignore: false,
    },
    {
      name: 'Nordic Semiconductor ASA',
      ticker: 'OTCPK:NDCV.F',
      ltm_ebitda: 48.24,
      market_cap: 2119.01,
      net_dept: -63,
      key: 6,
      ignore: false,
    },
    {
      name: 'Qorvo, Inc.',
      ticker: 'NASDAQGS:QRVO',
      ltm_ebitda: 1020.17,
      market_cap: 15451.62,
      net_dept: 740,
      key: 7,
      ignore: false,
    },
    {
      name: 'Renesas Electronics Corporation',
      ticker: 'OTCPK:RNEC.Y',
      ltm_ebitda: 1882.15,
      market_cap: 14648.49,
      net_dept: 5769,
      key: 8,
      ignore: false,
    },
    {
      name: 'STMicroelectronics N.V.',
      ticker: 'NYSE:STM',
      ltm_ebitda: 2255.19,
      market_cap: 30444.14,
      net_dept: -330,
      key: 9,
      ignore: false,
    },
    {
      name: 'Skyworks Solutions, Inc.',
      ticker: 'NASDAQGS:SWKS',
      ltm_ebitda: 1275.87,
      market_cap: 25120.56,
      net_dept: -983,
      key: 10,
      ignore: false,
    },
  ];

  useEffect(() => {
    const data: typeof comparableData = comparableInputs.map((input) => {
      const { market_cap, net_dept, ltm_ebitda, ntm_ebitda } = input;

      const ev = fn.getEv(market_cap, net_dept);
      const ltm_multiples = fn.getLtmMultiples(ev, ltm_ebitda);
      // const ntm_multiples = fn.getNtmMultiples(ev, ntm_ebitda);

      return {
        ...input,
        ev,
        ltm_multiples,
        // ntm_multiples,
      };
    });

    setComparableData(data);
  }, [comparableInputs]);

  useEffect(() => {
    if (!attempt || !attempt.trading_comparables) {
      // TODO: check this
      return;
    }

    const comparables = [
      xilinx,
      ...attempt.trading_comparables.map(({ id, ...comparable }) => {
        return { ...comparable, key: id, ignore: false };
      }),
    ];

    setComparableInputs(comparables);
    const data: typeof comparableData = comparables.map((input) => {
      const { market_cap, net_dept, ltm_ebitda, ntm_ebitda } = input;

      const ev = fn.getEv(market_cap, net_dept);
      const ltm_multiples = fn.getLtmMultiples(ev, ltm_ebitda);
      // const ntm_multiples = fn.getNtmMultiples(ev, ntm_ebitda);

      return {
        ...input,
        ev,
        ltm_multiples,
        // ntm_multiples,
      };
    });

    setComparableData(data);
    // setLastKey(Math.max(...attempt.trading_comparables.map((t) => t.id)) + 1);
  }, [attempt]);

  useEffect(() => {
    if(attempt && attempt.trading_comparables){
      const comparables = [
        xilinx,
        ...attempt.trading_comparables.map(({ id, ...comparable }) => {
          return { ...comparable, key: id, ignore: false };
        }),
      ];
  
      setComparableInputs(comparables);
      const data: typeof comparableData = comparables.map((input) => {
        const { market_cap, net_dept, ltm_ebitda, ntm_ebitda } = input;
  
        const ev = fn.getEv(market_cap, net_dept);
        const ltm_multiples = fn.getLtmMultiples(ev, ltm_ebitda);
        // const ntm_multiples = fn.getNtmMultiples(ev, ntm_ebitda);
  
        return {
          ...input,
          ev,
          ltm_multiples,
          // ntm_multiples,
        };
      });
    } else {
      setComparableInputs([xilinx, ...initialState]);
    }
  }, []);

  const isDisabled = (index: number) => {
    return index === 0 || !!attempt;
  };

  const onSubmit = () => {
    const comparables = comparableData
      .filter((comparable) => !comparable.ignore)
      .map(({ key, ...comparable }) => comparable) as TradingComparableData[];

    submitComparables({
      trading_comparables: comparables,
      member: member?.id || 0,
      player: member?.player || 0,
      step: step?.id || 0,
      session_id: sessionId,
    });
  };

  return (
    <Wrapper>
      <GlossaryWrappedText
        WrapperComponent={Typography.Title}
        wrapperProps={{ level: 4 }}
        text={`Based on the information that has already been provided to your team,
        you should be able to enter the data in the table below. If you didn’t
        acquire the Trading Comparables document, someone else on your team has. If
        you believe some comparables to be outliers, feel free to ignore them
        and do not enter their data. If you require additional rows, please add
        them as needed. This Step SHOULD NOT TAKE YOU MORE THAN 10 MINUTES.`}
      />
      <Typography.Title level={4}>
        ONLY ONE PERSON PER TEAM SHOULD ENTER THE DATA.
      </Typography.Title>
      <Divider />
      <Space direction="vertical">
        <Table
          rowKey="key"
          dataSource={comparableData}
          columns={[
            {
              title: () => <GlossaryWrappedText text="Company Name" />,
              dataIndex: 'name',
              render(value, record, index) {
                return (
                  <Input
                    type="text"
                    disabled={isDisabled(index)}
                    value={value}
                    onChange={(e) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].name = e.target.value;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: () => <GlossaryWrappedText text="Ticker" />,
              dataIndex: 'ticker',
              render(value, record, index) {
                return (
                  <Input
                    type="text"
                    disabled={isDisabled(index)}
                    value={value}
                    onChange={(e) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].ticker = e.target.value;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: () => <GlossaryWrappedText text="Market Cap $M" />,
              dataIndex: 'market_cap',
              render(value, record, index) {
                return (
                  <InputNumber
                    disabled={isDisabled(index)}
                    controls={false}
                    value={value}
                    onChange={(marketCap) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].market_cap = marketCap;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            // {
            //   title: () => <GlossaryWrappedText text='Last Price'/>,
            //   dataIndex: 'last_price',
            //   render(value, record, index) {
            //     return (
            //       <InputNumber
            //         value={value}
            //         onChange={(lastPrice) => {
            //           const newInputs = [...comparableInputs];
            //           newInputs[index].last_price = lastPrice;
            //           setComparableInputs(newInputs);
            //         }}
            //       />
            //     );
            //   },
            // },
            {
              title: () => <GlossaryWrappedText text="WK 52 Low" />,
              dataIndex: 'wk52_low',
              render(value, record, index) {
                if (index !== 0) {
                  return null;
                }
                return (
                  <InputNumber
                    disabled={isDisabled(index)}
                    controls={false}
                    value={value}
                    onChange={(wk52Low) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].wk52_low = wk52Low;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: () => <GlossaryWrappedText text="WK 52 High" />,
              dataIndex: 'wk52_high',
              render(value, record, index) {
                if (index !== 0) {
                  return null;
                }
                return (
                  <InputNumber
                    disabled={isDisabled(index)}
                    controls={false}
                    value={value}
                    onChange={(wk52High) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].wk52_high = wk52High;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: () => <GlossaryWrappedText text="Net Debt $M" />,
              dataIndex: 'net_dept',
              render(value, record, index) {
                return (
                  <InputNumber
                    disabled={isDisabled(index)}
                    controls={false}
                    value={value}
                    onChange={(netDept) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].net_dept = netDept;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            {
              title: () => <GlossaryWrappedText text="EV $M" />,
              dataIndex: 'ev',
              render: formatters.commas2Digits,
            },
            {
              title: () => <GlossaryWrappedText text="LTM EBITDA $M" />,
              dataIndex: 'ltm_ebitda',
              render(value, record, index) {
                return (
                  <InputNumber
                    disabled={isDisabled(index)}
                    controls={false}
                    value={value}
                    onChange={(ltmEbitda) => {
                      const newInputs = [...comparableInputs];
                      newInputs[index].ltm_ebitda =
                        ltmEbitda !== 0 ? ltmEbitda : undefined;
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
            // {
            //   title: () => <GlossaryWrappedText text='NTM EBITDA'/>,
            //   dataIndex: 'ntm_ebitda',
            //   render(value, record, index) {
            //     return (
            //       <InputNumber
            //         value={value}
            //         onChange={(ntmEbitda) => {
            //           const newInputs = [...comparableInputs];
            //           newInputs[index].ntm_ebitda =
            //             ntmEbitda !== 0 ? ntmEbitda : undefined;
            //           setComparableInputs(newInputs);
            //         }}
            //       />
            //     );
            //   },
            // },
            {
              title: () => <GlossaryWrappedText text="EV/EBITDA Multiples" />,
              children: [
                {
                  title: () => <GlossaryWrappedText text="LTM" />,
                  dataIndex: 'ltm_multiples',
                  render: formatters.commas2Digits,
                },
                // {
                //   title: () => <GlossaryWrappedText text='NTM'/>,
                //   dataIndex: 'ntm_multiples',
                //   render: formatters.commas2Digits,
                // },
              ],
            },
            // {
            //   title: () => <GlossaryWrappedText text='Ignore'/>,
            //   dataIndex: 'ignore',
            //   render(value, record, index) {
            //     return (
            //       <RedSwitch
            //         checkedChildren="Yes"
            //         unCheckedChildren="No"
            //         checked={value}
            //         onChange={(checked) => {
            //           const newInputs = [...comparableInputs];
            //           newInputs[index].ignore = checked;
            //           setComparableInputs(newInputs);
            //         }}
            //       />
            //     );
            //   },
            // },
            {
              title: () => <GlossaryWrappedText text="Actions" />,
              render(value, record, index) {
                return (
                  <DeleteOutlined
                    style={{ fontSize: 20, color: '#ff4d4f' }}
                    disabled={!!attempt}
                    onClick={() => {
                      if (attempt) {
                        return;
                      }
                      const newInputs = [...comparableInputs];
                      newInputs.splice(index, 1);
                      setComparableInputs(newInputs);
                    }}
                  />
                );
              },
            },
          ]}
          bordered
          pagination={false}
        />
        {ownPerspective && (
          <JustifySpaceBetween>
            <Button type="dashed" onClick={addComparable} disabled={!!attempt}>
              <PlusOutlined /> Add
            </Button>

            <Button type="primary" onClick={onSubmit} disabled={!!attempt}>
              <SendOutlined /> Submit
            </Button>
          </JustifySpaceBetween>
        )}
      </Space>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .ant-table-cell {
    padding: 20px 10px;
  }

  & tr .ant-table-cell {
    padding: 10px;
    text-align: center;
  }
`;

const JustifySpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RedSwitch = styled(Switch)`
  &.ant-switch-checked {
    background: #ff4d4f;
  }
  & .ant-click-animating-node {
    box-shadow: none !important;
  }
`;

export default TradingComparablesStep;
