import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Tabs, Card, message } from 'antd';
import styled from 'styled-components';

import { Loading, PdfViewer } from 'components';
import { useCurrentStep, useSession, useSessionId } from 'hooks';
import { useSessionMember } from 'hooks/traineeActions';
import type { DocumentType } from 'types';
import { SESSION_CREATED } from 'stateConstants';
import NotPlayingMessage from '../Simulation/NotPlayingMessage';
import { useMemberDocuments } from './actions';

const { TabPane } = Tabs;

const Readings: React.FC = () => {
  const { data: member, isLoading: isMemberLoading } = useSessionMember();
  const {
    data: documents,
    isLoading: isDocumentsLoading,
    refetch,
  } = useMemberDocuments(member?.id);
  const { data } = useSession();
  const { data: currentStep } = useCurrentStep();

  const [hasMessageBeenDisplayed, setHasMessageBeenDisplayed] = useState(false);

  useEffect(() => {
    refetch();
  }, []);

  if (currentStep?.type === 0 && !hasMessageBeenDisplayed) {
    message.info('You should be in the Simulation tab at this step!');
    setHasMessageBeenDisplayed(true);
  }

  if (isMemberLoading || isDocumentsLoading) {
    return <Loading />;
  }

  const displayDocument = (doc: DocumentType) => {
    if (doc.resourcetype === 'GeneratedDocument') {
      return <div dangerouslySetInnerHTML={{ __html: doc.content }} />;
    }
    if (doc.resourcetype === 'ValuationDocument') {
      return <PdfViewer file={doc.file} />;
    }

    return <div>Document type unknown</div>;
  };

  if (data?.status === SESSION_CREATED) {
    return (
      <NotPlayingMessage message="Readings will become visible once they are made available in the Simulation." />
    );
  }

  return (
    <Wrapper>
      <Tabs tabPosition="left">
        {Object.values(documents || []).map((doc) => {
          return (
            <TabPane tab={doc.name} key={doc.id}>
              {displayDocument(doc)}
            </TabPane>
          );
        })}
      </Tabs>
    </Wrapper>
  );
};

const Wrapper = styled(Card)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export default Readings;
