import React from 'react';

import { Loading } from 'components';
import { useCurrentStep } from 'hooks';
import { useSessionMember } from 'hooks/traineeActions';
import ComparablesTable from './ComparablesTable';
import FootballField from './FootballField';
import { useRetrieveTransactionAttempt } from './actions';
import { Player, TransactionComparableAttempt } from 'types';

const TransactionComparablesStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
  attempt: TransactionComparableAttempt;
}> = ({ player, ownPerspective, attempt }) => {
  return (
    <>
      <ComparablesTable
        attempt={attempt.transactionCompsAttempt}
        ownPerspective={ownPerspective}
      />
      {!!attempt && (
        <FootballField
          tradingAttempt={attempt.tradingCompsAttempt}
          transactionAttempt={attempt.transactionCompsAttempt}
          dcffAttempt={attempt.dcffAttempt}
        />
      )}
    </>
  );
};

export default TransactionComparablesStep;
