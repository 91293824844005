import React from 'react';

import { Breadcrumb, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { useCurrentStage, useSession } from 'hooks';

const { Text } = Typography;

const Steps: React.FC = () => {
  const { data: currentStage, isLoading: isStageLoading } = useCurrentStage();
  const { data: session, isLoading: isSessionLoading } = useSession();

  if (isStageLoading || isSessionLoading || !currentStage || !session) {
    return null;
  }

  const steps = currentStage.steps.sort((a, b) => a.rank - b.rank);

  return (
    <Breadcrumb separator={<ArrowRightOutlined />}>
      {steps.map((step) => {
        return (
          <Breadcrumb.Item key={step.name}>
            <Text
              type={
                !(step.id === session.current_step) ? 'secondary' : undefined
              }
            >
              {step.name}
            </Text>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default Steps;
