import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components';

import { Layout } from 'antd';

import { LayoutProvider } from './LayoutContext';
import type { Route } from 'types';
import Navigation from './Navigation';

type Props = {
  routes: Route[];
  subHeader?: JSX.Element;
};

const PageLayout: React.FC<Props> = ({ routes, children, subHeader }) => {
  const [offset, setOffset] = useState(122);
  const header = useRef() as React.MutableRefObject<HTMLDivElement>;

  const listener = () => setOffset(header.current.clientHeight);

  useLayoutEffect(() => {
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [header, header.current, offset]);

  useEffect(listener);

  return (
    <LayoutProvider>
      <LayoutWrapper>
        <PageHeader ref={header}>
          <Navigation routes={routes} />
          {!subHeader ? null : subHeader}
        </PageHeader>
        <ContentWrapper style={{ marginTop: offset }}>
          <Content>{children}</Content>
        </ContentWrapper>
      </LayoutWrapper>
    </LayoutProvider>
  );
};

const PageHeader = styled.div`
  position: fixed;
  width: 100%;
  z-index: 999;
`;

const LayoutWrapper = styled(Layout)`
  width: 100%;
  min-height: 100vh;
`;

const ContentWrapper = styled(Layout.Content)`
  margin: 0 50px;
  height: 100%;
  display: flex;
  align-items: stretch;
`;

const Content = styled(Layout)`
  padding: 8px;
  flex: 1;
`;

export default PageLayout;
