import React from 'react';
import styled from 'styled-components';

import { Typography } from 'antd';

import LoginForm from './LoginForm';

const Login: React.FC = () => {
  return (
    <CenteredTextDiv>
      <Typography.Title>Welcome to Traderion</Typography.Title>
      <Typography.Title level={3}>
        Please enter your credentials
      </Typography.Title>
      <br />
      <LoginForm />
    </CenteredTextDiv>
  );
};

const CenteredTextDiv = styled.div`
  text-align: center;
`;

export default Login;
