import React, { useEffect } from 'react';
import { Offer, Player } from 'types';
import { useRetrieveBuyerProposal } from '../ProposalStep/actions';
import { Button, InputNumber, List, Typography, Divider } from 'antd';
import { ListItem, Loading } from 'components';
import styled from 'styled-components';
import {
  // useCreateEliminationOffer,
  useOfferElimination,
  useRetrieveOffer,
} from './actions';
import {
  useSessionMember,
  useSessionPlayer,
  useSubmitCurrentStep,
} from 'hooks/traineeActions';
import { ArrowRightOutlined } from '@ant-design/icons';
import useOfferFields from 'hooks/useOfferFields';
import GlossaryWrappedText from 'modules/TraineeSession/Glossary/GlossaryWrappedText';
import useOfferUpToDate from 'hooks/useOfferUpToDate';

const { Paragraph, Title } = Typography;

const offerStatusMessage = (offer: Offer, player: Player) => {
  if (player.passed_final === 0) {
    return (
      <>
        <Title level={4}>
          The Seller is now assessing your Offer and will reply swiftly.
        </Title>
        <Title level={4}>Please stand-by</Title>
      </>
    );
  }

  if (player.passed_final === 1) {
    return (
      <>
        <Title level={4}>
          Unfortunately, your Team has been removed from the Transaction by the
          Seller for having an insufficiently appealing offer.
        </Title>
        <Title level={4}>
          This judgment may be superficial, but it is nonetheless final.
        </Title>
        <Title level={4}>
          All M&A transactions processes that involve multiple bidders have such
          an outcome for all but Buyer per transaction.
        </Title>
        <Title level={4}>
          We thank you for your participation, and we wish you good luck in your
          career!
        </Title>
      </>
    );
  }
  if (player.passed_final === 2) {
    return (
      <>
        <Title style={{ color: 'green' }} level={4}>
          Congratulations! Your offer was accepted by the Seller!
        </Title>
      </>
    );
  }
};

const BuyerElimination: React.FC<{
  offer: Offer;
  player: Player;
  ownPerspective: boolean;
}> = ({ offer, player, ownPerspective }) => {
  const {
    getProps,
    offerFields,
    updateOffer: updateOfferFields,
  } = useOfferFields();
  const offerUpToDate = useOfferUpToDate(offer);
  const { mutate: createOffer } = useSubmitCurrentStep();

  useEffect(() => {
    if (offer) {
      updateOfferFields(offer);
    }
  }, [offer]);

  if (!offer) {
    return <Loading />;
  }
  if (offerUpToDate && player.passed_final)
    return (
      <>
        <Divider />
        {offerStatusMessage(offer, player)}
        <Divider />
      </>
    );

  return (
    <Wrapper>
      <Title level={4}>
        You are now recieving updated guidance from the Board.
      </Title>

      <Title level={4}>
        As such the best offer that you can advance is:
        <ul>
          <li>1.7234 AMD shares for 1 Xilinx share.</li>
          <li>AMD pays 1.5bn break-up fee for its Board walking away.</li>
          <li>AMD pays 1bn break-up fee for regulatory rejection.</li>
        </ul>
        However, you are prohibited from making the offer known verbally to the
        Seller and should still aim for the best possible outcome, not the road
        of least resistance.
      </Title>
      <Title level={4}>
        The purpose of the face-to-face Negotiation is now to convince the
        Seller that you are a competent and strong team which the Seller’s
        shareholders can rely on to generate greater returns in the future.
      </Title>
      <Title level={4}>
        PLEASE MAKE SURE YOU RETAIN THE BOARD’s INSTRUCTIONS DOWN ON A PIECE OF
        PAPER FOR FUTURE REFERENCE.
      </Title>
      <Divider />
      <Paragraph>
        After the last negotiation, you shall submit your Best and Final Offer.
        Take the time and discuss with your team. For the last 5 minutes, you
        will see a countdown on the screen.
      </Paragraph>
      <List>
        {offer && offerUpToDate && (
          <Title level={2}>
            Offer submitted by {offer.last_updated_by?.name}:
          </Title>
        )}
        <ListItem>
          Cash in the amount of <InputNumber {...getProps('cash_amount')} />{' '}
          million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Stock-for-stock exchange at a rate of
            "
          />
          <InputNumber {...getProps('stock_exchange_rate')} />
          <GlossaryWrappedText
            text="
            shares of the
          Buyer for every outstanding share of the Target upon closing and cash
          in lieu of fractional shares for a total stock consideration in the
          amount of 
            "
          />
          <InputNumber {...getProps('cash_in_lieu')} /> million USD
        </ListItem>
        §
        <ListItem>
          <GlossaryWrappedText
            text="
          Deferred cash consideration in the amount
            "
          />
          <InputNumber {...getProps('deferred_cash')} /> of million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Assumption of Pension liabilities in the amount of
            "
          />
          <InputNumber {...getProps('pension_liabilities')} /> million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Assumption of existing Target debt in the amount of
            "
          />
          <InputNumber {...getProps('target_dept')} /> million USD
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          Also, the Buyer offers a definitive amount of
            "
          />
          <InputNumber {...getProps('breakup_fee')} />
          <GlossaryWrappedText
            text="
            million USD as a Break-Up fee if proposed transaction fails
            "
          />
        </ListItem>
        <ListItem>
          <GlossaryWrappedText
            text="
          At this point, you may also require the Seller to commit to a Break-Up
          Fee in the event of its Board deciding to walk awav from the
          Transaction between Signing and Closing. If you decide to do so, the
          amount you desire to receive as a Break-Up fee is
            "
          />
          <InputNumber {...getProps('seller_breakup_fee')} /> million USD
        </ListItem>
      </List>

      {ownPerspective && (
        <Footer>
          <Button
            type="primary"
            onClick={() => {
              createOffer({
                ...offerFields,
                tier: 3,
              });
              // alert('not implemented');
            }}
            disabled={offerUpToDate}
          >
            <ArrowRightOutlined />
            Submit
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: right;
`;

export default BuyerElimination;
