import React, { useState, useMemo, useEffect } from 'react';

import { Typography, Table, Switch, Button, Divider } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
  Member,
  ProposalStepType,
  SellerOfferFields,
  SellerOffer,
  Player,
} from 'types';
import { useCreateSellerProposal, useRetrieveSellerProposal } from './actions';
import { useSubmitCurrentStep } from 'hooks/traineeActions';
import { useSession } from 'hooks';
import GlossaryWrappedText from 'modules/TraineeSession/Glossary/GlossaryWrappedText';

const { Paragraph, Text, Title } = Typography;

type Field = {
  text: string;
  field: keyof SellerOfferFields;
};

type Props = {
  member: Member;
  proposal: SellerOffer;
  ownPerspective: boolean;
};

const SellerProposal: React.FC<Props> = ({
  member,
  proposal: sellerProposal,
  ownPerspective,
}) => {
  const [proposal, setProposal] = useState({} as SellerOfferFields);
  const { data: session } = useSession();
  const { mutate: createSellerProposal } = useSubmitCurrentStep();

  useEffect(() => {
    if (sellerProposal) {
      setProposal({ ...sellerProposal });
    }
  }, [sellerProposal]);

  const dataSource: Field[] = useMemo(
    () => [
      {
        text:
          'If the offer will include a share exchange, the exchange ratio ' +
          'for every outstanding share of your own firm shall be fully expressed',
        field: 'share_exchange',
      },
      {
        text:
          'If the offer will include any cash component, any contingency issues ' +
          'shall be explained in the offer',
        field: 'cash_component',
      },
      {
        text:
          'The Offer shall highlight whether it includes any deferred cash ' +
          'consideration',
        field: 'deferred_cash',
      },
      {
        text:
          'The Offer shall highlight whether it includes the assumption of any ' +
          'Pension Liabilities',
        field: 'pension_liabilities',
      },
      {
        text:
          'The Offer shall highlight whether it includes the assumption of any ' +
          'Financial Debt',
        field: 'target_dept',
      },
      {
        text:
          'The Offer shall specify the amount of a break-up fee, if applicable',
        field: 'breakup_fee',
      },
      {
        text:
          'All financial terms of the Offer shall be expressed in million USD',
        field: 'terms_in_usd',
      },
    ],
    []
  );

  return (
    <Wrapper>
      <BlueTitle level={4}>
        ONLY ONE PERSON PER TEAM SHOULD ENTER THE DATA.
      </BlueTitle>
      <GlossaryWrappedText
        WrapperComponent={Title}
        wrapperProps={{ level: 4 }}
        text={`You are getting closer to receiving some offers. As such, please review
        and make your selection with regards to some aspects which should be
        followed by potential Buyers when submitting their offers.`}
      />

      <Divider />
      <Table
        rowKey="field"
        dataSource={dataSource}
        columns={[
          {
            title: 'Option',
            dataIndex: 'text',
            render: (text) => <Text>{text}</Text>,
          },
          {
            title: 'Action',
            render: (_, record) => {
              return (
                <Switch
                  checked={proposal[record.field]}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onClick={() => {
                    const { field } = record;
                    const value = !proposal[field];
                    setProposal({ ...proposal, [field]: value });
                  }}
                  disabled={!!sellerProposal}
                />
              );
            },
          },
        ]}
        pagination={false}
      />
      {ownPerspective && (
        <Footer>
          <Button
            type="primary"
            onClick={() => {
              createSellerProposal({
                ...proposal,
                scenario: session?.scenario,
                member: member.id,
                player: member.player,
              });
            }}
            disabled={!!sellerProposal}
          >
            <ArrowRightOutlined />
            Submit
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: right;
`;

const BlueTitle = styled(Title)`
  &.ant-typography {
    color: #0d3fe0;
  }
`;

export default SellerProposal;
