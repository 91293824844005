import React from 'react';

import { Table, Typography, Divider } from 'antd';

import { PLAYER_TYPES } from 'stateConstants';
import { Loading } from 'components';
import { useCurrentStep } from 'hooks';
import { useDCFStep } from './actions';

const DCFStep: React.FC = () => {
  const {
    data: currentStep,
    isLoading: isCurrentStepLoading,
  } = useCurrentStep();
  const { data: players, isLoading: isPlayersLoading } = useDCFStep(
    currentStep?.id || 0
  );

  if (isPlayersLoading || isCurrentStepLoading) {
    return <Loading />;
  }

  const dataSource = (players || []).sort((a, b) =>
    a.username.localeCompare(b.username)
  );

  return (
    <div>
      <Typography.Title level={4}>
        Please inform the Participants that, at this Step, they would normally be expected to proceed and build
        their DCF model in an Excel spreadsheet. After finalizing it, they should come back and introduce outputs
        from their Excel model into the Simulation. Because of time constraints, those calculations have already
        been entered for them. They can just review them  and click Submit to proceed.
      </Typography.Title>
      <Divider />
      <Table
        pagination={false}
        rowKey="id"
        columns={[
          { title: 'Team', dataIndex: 'username' },
          { title: 'Member count', dataIndex: 'person_count' },
          {
            title: 'Type',
            dataIndex: 'type',
            render(value: keyof typeof PLAYER_TYPES) {
              return PLAYER_TYPES[value];
            },
          },
          {
            title: 'Submissions',
            dataIndex: 'dcf_attempts_count',
          },
        ]}
        dataSource={dataSource}
      />
    </div>
  );
};

export default DCFStep;
