type SNU = string | number | undefined;

const nr = (val: SNU) => {
  if (typeof val === 'number') return val;
  if (typeof val === 'string') {
    const parsed = parseFloat(val);

    if (Number.isNaN(parsed)) return 0;

    return parsed;
  }
  return 0;
};

export const commasNoDigits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

export const commas2Digits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

export const commas4Digits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 4,
    minimumFractionDigits: 0,
  });
export const commas5Digits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 5,
    minimumFractionDigits: 0,
  });

export const percent2Digits = (n: SNU) =>
  (nr(n) * 100).toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }) + ' %';

export const million = (n: SNU) => {
  n = parseFloat(`${n}`);
  return `${commas2Digits(n / 1000000)}M`;
};

export const millionDollars = (n: SNU) => '$' + million(n);

export const dateWithTime = (d: string) => {
  return d.replace(
    /(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)\.(\d+)Z/,
    '$1-$2-$3 $4:$5'
  );
};
