import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import {
  CloseSquareOutlined,
  PlayCircleOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
} from '@ant-design/icons/lib';

import Space from 'components/Space';
import {
  SESSION_CREATED,
  SESSION_PAUSED,
  SESSION_PLAYING,
  UNASSIGNED,
} from 'stateConstants';
import {
  useAdvanceSession,
  useFinishSession,
  usePreviousStep,
  useResumeSession,
  useStartSession,
} from 'modules/TrainerSession/SessionControls/actions';
import { ConfirmButton } from 'components';
import { useSession, useSessionId } from 'hooks';
import {
  useArePlayersAssigned,
  usePlayersAssignTable,
  useQuizPlayers,
} from 'modules/TrainerSession/Simulation/Steps/QuizStep/actions';
import { usePlayers } from 'hooks/trainerActions';

const SessionControls: React.FC = () => {
  const session_id = useSessionId();
  const { data } = useSession();
  const { data: quizPlayers } = useQuizPlayers();
  const { data: players } = usePlayersAssignTable();
  const [nextDisabled, setNextDisabled] = useState(true);

  const { status } = data || {};
  const { mutate: finishSession } = useFinishSession();
  const { mutate: resumeSession } = useResumeSession();
  const { mutate: startSession } = useStartSession();
  const { mutate: advanceSession } = useAdvanceSession();
  const { mutate: previousStep } = usePreviousStep();
  const arePlayersAssigned = useArePlayersAssigned();

  const startDisabled = !(status === SESSION_CREATED) || !arePlayersAssigned;
  const playDisabled = !(status === SESSION_PAUSED);
  const pauseDisabled = !(status === SESSION_PLAYING);
  const finishDisabled = !(
    status === SESSION_PAUSED || status === SESSION_PLAYING
  );
  const [warningMessage, setWarningMessage] = useState<string>('');

  const getOfflinePlayersWarning = () => {
    if (!players) return '';

    const allOfflinePlayerNames = players
      .filter((player) => {
        return !player.members.some((member) => member.is_online);
      })
      .map((player) => player.username);

    if (allOfflinePlayerNames.length === 0) return '';

    const playersString = allOfflinePlayerNames.join(', ');
    if (!allOfflinePlayerNames.length)
      return 'Are you sure you want to start the session?';
    return `Warning: The following Teams have no online members: ${playersString}`;
  };

  useEffect(() => {
    setWarningMessage(getOfflinePlayersWarning());
  }, [players]);

  useEffect(() => {
    switch (data?.current_step) {
      case 1:
        if (
          pauseDisabled ||
          (quizPlayers && quizPlayers[0]?.type === UNASSIGNED)
        )
          setNextDisabled(true);
        else setNextDisabled(false);
        break;
      default:
        setNextDisabled(pauseDisabled);
    }
  }, [quizPlayers, pauseDisabled]);

  return (
    <Space wrap>
      {status === SESSION_CREATED && (
        <ConfirmButton
          name="START"
          type="primary"
          disabled={startDisabled}
          warning={warningMessage}
          onConfirm={() => {
            // startSession();
            startSession(session_id);
          }}
          okText='Continue'
          cancelText='Cancel'
        />
      )}
      {status !== SESSION_CREATED && (
        <>
          <Button
            disabled={playDisabled}
            onClick={() => resumeSession()}
            icon={<PlayCircleOutlined />}
          >
            PLAY
          </Button>
          <Button
            disabled={nextDisabled}
            onClick={() => previousStep()}
            icon={<DoubleLeftOutlined />}
          >
            PREV
          </Button>
          <Button
            disabled={nextDisabled}
            onClick={() => advanceSession()}
            icon={<DoubleRightOutlined />}
          >
            NEXT
          </Button>
          {/* <Button
            disabled={pauseDisabled}
            onClick={() => pauseSession()}
            icon={<PauseCircleOutlined />}
          >
            PAUSE
          </Button> */}
          <ConfirmButton
            name="END"
            warning="Are you sure?"
            disabled={finishDisabled}
            danger
            type="primary"
            onConfirm={finishSession}
            icon={<CloseSquareOutlined />}
          />
        </>
      )}
    </Space>
  );
};

export default SessionControls;
