import React from 'react';

import { Tag, Typography } from 'antd';

import { useCurrentStage } from 'hooks';

const CurrentStage: React.FC = () => {
  const { data, isLoading } = useCurrentStage();

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <Typography.Text type="secondary">{'Stage: '}</Typography.Text>
      {data?.name ? (
        <Tag color="blue">{data.name}</Tag>
      ) : (
        <Tag color="orange">None</Tag>
      )}
    </div>
  );
};

export default CurrentStage;
