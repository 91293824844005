import React, { ReactNode } from 'react';

import { Col, Row } from 'antd';

const Item: React.FC<{ children: [ReactNode, ReactNode] }> = ({ children }) => {
  return (
    <Row style={{ minHeight: '32px' }}>
      <Col flex={1} style={{ display: 'flex', alignItems: 'center' }}>
        {children[0]}
      </Col>
      <Col style={{ display: 'flex', alignItems: 'center' }}>{children[1]}</Col>
    </Row>
  );
};

export default Item;
