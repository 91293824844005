import React from 'react';

import { Loading } from 'components';
import { useSession } from 'hooks';
import {
  SESSION_CREATED,
  SESSION_FINISHED,
  SESSION_PAUSED,
  SESSION_PLAYING,
} from 'stateConstants';
import NotPlayingMessage from './NotPlayingMessage';
import CurrentStage from './CurrentStage';

const Simulation: React.FC = () => {
  const { data } = useSession();

  if (!data) return <Loading />;

  switch (data.status) {
    case SESSION_CREATED:
      return (
        <NotPlayingMessage message="Please stand-by. The simulation will start momentarily and will become visible on this screen." />
      );
    case SESSION_PAUSED:
      return <NotPlayingMessage message="Break" />;
    case SESSION_PLAYING:
      return <CurrentStage />;
    case SESSION_FINISHED:
      return <NotPlayingMessage message="Finished" />;
  }
};

export default Simulation;
