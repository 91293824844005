import { useQueryClient } from 'react-query';

import { invalidateIfIncludes } from 'services';
import useCurrentStep from './useCurrentStep';
import useAuthQuery from './useAuthQuery';
import useQuerySubscription from './useQuerySubscription';
import useSessionId from './useSessionId';
import { useSessionMember, useSessionPlayer } from './traineeActions';

import { Session } from 'types';
import { useEffect, useState } from 'react';

export const useSession = () => {
  const id = useSessionId();
  return useAuthQuery<Session>(['sessions', id]);
};

const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(!document.hidden);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isVisible;
};

export const usePageFocus = () => {
  const [isFocused, setIsFocused] = useState(document.hasFocus());

  useEffect(() => {
    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return isFocused;
};

export const useTraineeSessionSocket = () => {
  const id = useSessionId();
  const queryClient = useQueryClient();
  const { data: member } = useSessionMember();
  const isVisible = usePageFocus();

  useEffect(() => {
    if (isVisible) {
      // console.log('Should invalidate');
      queryClient.invalidateQueries({
        predicate: invalidateIfIncludes(['sessions']),
      });
    }
  }, [isVisible, queryClient]);

  return useQuerySubscription(`sessions/${id}/`, (data) => {
    switch (data.type) {
      case 'submit_received':
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['sessions']),
        });
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['current-step']),
        });
        break;
      case 'session_started':
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['sessions']),
        });
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['current-step']),
        });
        break;
      case 'session_step':
        queryClient.invalidateQueries(['sessions', id]);
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['current-step']),
        });
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['sessions']),
        });
        break;
      case 'session_status_changed':
        queryClient.invalidateQueries(['sessions', id]);
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['current-step']),
        });
        break;
      case 'assign_players':
        queryClient.invalidateQueries(['sessions', id, 'player']);
        break;
      case 'tc_attempt':
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['trading-comparables']),
        });
        break;
      case 'trans_comp_attempt':
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['transaction-comparables']),
        });
        break;

      case 'dcf_attempt':
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['dcf']),
        });
        break;
      case 'da_attempt':
        queryClient.invalidateQueries(['doc-acquisition', id, 'players']);
        // if (member?.id === parseInt((data.data as any)?.member_id)) {
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['documents']),
        });
        // }
        queryClient.invalidateQueries(['members', member?.id, 'documents']);

        break;
      case 'bilateral_docs_submit':
        queryClient.invalidateQueries({
          predicate: invalidateIfIncludes(['documents', 'bilateral-docs']),
        });
        break;
      case 'offer_received':
        queryClient.invalidateQueries(['offers/retrieve_visible/']);
        queryClient.invalidateQueries(['offers/retrieve_offer/']);
        break;
      case 'buyer_proposal_updated':
        queryClient.invalidateQueries(['buyer_proposals/retrieve/']);
        queryClient.invalidateQueries(['offers/retrieve_offer/']);
        // queryClient.invalidateQueries(['buyer_proposals/retrieve']);

        // queryClient.invalidateQueries({
        //   predicate: invalidateIfIncludes(['buyer_proposals']),
        // });
        break;
      case 'offer_created':
        queryClient.invalidateQueries(['buyer_proposals/retrieve/']);
        queryClient.invalidateQueries(['offers/retrieve_visible/']);
        queryClient.invalidateQueries(['offers/retrieve_offer/']);
        break;
      case 'offer_updated':
        queryClient.invalidateQueries(['buyer_proposals/retrieve/']);
        queryClient.invalidateQueries(['offers/retrieve_visible/']);
        queryClient.invalidateQueries(['offers/retrieve_offer/']);
        break;
      case 'players_updated':
        queryClient.invalidateQueries(['buyer_proposals/retrieve/']);
        queryClient.invalidateQueries(['offers/retrieve_offer/']);
        queryClient.invalidateQueries(['offers/retrieve_visible/']);
        queryClient.invalidateQueries(['sessions', id, 'player']);
        break;
    }
  });
};

export const useTrainerSessionSocket = () => {
  const id = useSessionId();
  const queryClient = useQueryClient();
  const { data: currentStep } = useCurrentStep();
  const isVisible = usePageFocus();

  useEffect(() => {
    if (isVisible) {
      // console.log('Should invalidate');
      queryClient.invalidateQueries({
        predicate: invalidateIfIncludes(['sessions']),
      });
    }
  }, [isVisible, queryClient]);

  return useQuerySubscription(
    `sessions/${id}/`,
    (data) => {
      switch (data.type) {
        case 'submit_received':
          queryClient.invalidateQueries({
            predicate: invalidateIfIncludes(['sessions']),
          });
          queryClient.invalidateQueries({
            predicate: invalidateIfIncludes(['current-step']),
          });
          break;
        case 'session_started':
          queryClient.invalidateQueries({
            predicate: invalidateIfIncludes(['sessions']),
          });
          queryClient.invalidateQueries({
            predicate: invalidateIfIncludes(['current-step']),
          });
          break;
        case 'session_step':
          queryClient.invalidateQueries(['sessions', id]);
          queryClient.invalidateQueries({
            predicate: invalidateIfIncludes(['current-step']),
          });
          break;
        case 'session_status_changed':
          queryClient.invalidateQueries(['sessions', id]);
          queryClient.invalidateQueries({
            predicate: invalidateIfIncludes(['current-step']),
          });
          break;
        case 'quiz_submit':
          queryClient.invalidateQueries(['sessions', id, 'quiz-players']);
          break;
        case 'da_attempt':
          queryClient.invalidateQueries([
            'doc-acquisition',
            currentStep?.id || 0,
            'players',
          ]);
          break;
        case 'dcf_attempt':
          queryClient.invalidateQueries([
            'dcf',
            currentStep?.id || 0,
            'players',
          ]);
          break;
        case 'tc_attempt':
          queryClient.invalidateQueries([
            'trading-comparables',
            currentStep?.id || 0,
            'players',
          ]);
          break;
        case 'trans_comp_attempt':
          queryClient.invalidateQueries([
            'transaction-comparables',
            currentStep?.id || 0,
            'players',
          ]);
          break;
        case 'bilateral_docs_submit':
          if (!currentStep?.id) break;
          queryClient.invalidateQueries([
            'bilateral-docs',
            currentStep.id,
            'players',
          ]);
          break;
        case 'negotiation_choice_attempt':
          if (!currentStep?.id) break;
          queryClient.invalidateQueries([
            'negotiation-choices',
            currentStep.id,
            'players',
          ]);
          break;
        case 'proposal_sent':
          queryClient.invalidateQueries([
            'seller_proposals',
            currentStep?.id || 0,
            'players',
          ]);
          queryClient.invalidateQueries([
            'buyer_proposals',
            currentStep?.id || 0,
            'players',
          ]);
          break;
        case 'offer_players_updated':
          queryClient.invalidateQueries(['offers', id, 'players']);
          break;
        case 'players_updated':
          queryClient.invalidateQueries(['offers', id, 'players']);
          break;
        case 'member_status_change':
          queryClient.invalidateQueries(['sessions', id, 'assign-players']);
          break;
      }
    },
    [currentStep]
  );
};
