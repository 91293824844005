import React from 'react';
import { Button, Form, Modal } from 'antd';

import SessionForm from './SessionForm';
import { useCreateSession } from '../actions';

type Props = {
  hide: () => void;
  klassId: number;
};

const CreateSessionModal: React.FC<Props> = ({ hide, klassId }) => {
  const [form] = Form.useForm();

  const { mutate } = useCreateSession(klassId);

  return (
    <Modal
      title="Create Session"
      visible
      onCancel={hide}
      footer={
        <Button type="primary" onClick={form.submit}>
          Create
        </Button>
      }
    >
      <SessionForm
        form={form}
        onFinish={(data) => {
          mutate({ ...data, klass: klassId });
          hide();
        }}
      />
    </Modal>
  );
};

export default CreateSessionModal;
