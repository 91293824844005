import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from './theme';
import 'antd/dist/antd.css';

import Router from './Router';

import ReactQueryProvider from './ReactQueryProvider';
import { AuthProvider } from 'contexts';

ReactDOM.render(
  <ReactQueryProvider>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router />
      </ThemeProvider>
    </AuthProvider>
  </ReactQueryProvider>,
  document.getElementById('root')
);
