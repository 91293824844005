import React from 'react';
import { Link } from 'react-router-dom';

import { Typography, List, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { useCurrentStep } from 'hooks';
import { useSessionMember } from 'hooks/traineeActions';
import { Loading } from 'components';
import { useDocAcquisitionAttempt } from './actions';

import type { DocAcquisitionStepType, Player } from 'types';
import { useRetrieveCurrentStep } from 'hooks/trainerActions';
import GlossaryWrappedText from 'modules/TraineeSession/Glossary/GlossaryWrappedText';

const DisplayAttempt: React.FC<{ player: Player }> = ({ player }) => {
  const { data: attempt, isLoading: isAttemptLoading } = useRetrieveCurrentStep(
    player.id,
    'DocAcquisitionStepType'
  );

  if (isAttemptLoading || !attempt) {
    return <Loading />;
  }

  return (
    <Text>
      <GlossaryWrappedText
        text={`
        You have now succesfully acquired the following Valuation documents:`}
        WrapperComponent={Typography.Paragraph}
      />
      <List
        size="large"
        dataSource={attempt?.documents || []}
        renderItem={(doc) => <List.Item>{(doc as any).name}</List.Item>}
      />
      <GlossaryWrappedText
        text={`
        Once you leave this page, you will be able to access all of these
        documents. In the previous step, each of your team members has made
        different selections. It may be worth exploring what documents your team
        members have selected and how they may all assist your team in the near
        future.`}
        WrapperComponent={Typography.Paragraph}
      />
      <GlossaryWrappedText
        text={`
        The valuation documents in by the Simulation provide you with just about
        enough information so that you can complete the valuation of Xilinx as
        it was performed by advisors prior to the announcement. This exercise
        will be performed outside of the Traderion simulation. Upon completion
        you will be expected to enter the resulting data into the simulation.
        Good luck!`}
        WrapperComponent={Typography.Paragraph}
      />
    </Text>
  );
};

const Text = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`;

export default DisplayAttempt;
