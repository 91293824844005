import { useAuthQuery } from 'hooks';
import { TCPlayer } from 'types';

export const useTransCompStep = (stageId: number) => {
  return useAuthQuery<TCPlayer[]>([
    'transaction-comparables',
    stageId,
    'players',
  ]);
};
