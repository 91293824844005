import { useAuthState } from 'contexts';
import useSessionId from './useSessionId';

const useHeaders = () => {
  const { token, person } = useAuthState();
  const sessionId = useSessionId();

  return {
    Authorization: `JWT ${token}`,
    'Person-Id': person?.id || 0,
    'Session-Id': sessionId || 0,
  };
};

export default useHeaders;
