import type { Route } from 'types';
import Dashboard from 'modules/AdminDashboard/Dashboard/index';
import GlossaryTable from 'modules/TraineeSession/Glossary/GlossaryTable';

const routes: Route[] = [
  { name: 'Dashboard', path: '/dashboard', component: Dashboard },
  { name: 'Glossary', path: '/glossary', component: GlossaryTable },
];

export default routes;
