import React, { useState, useEffect } from 'react';
import { Offer, Stage, Step } from 'types';
import useCurrentStage from './useCurrentStage';
import { useCurrentStep } from 'hooks';

export const isOfferUpToDate = (offer: Offer, step: Step | undefined, stage: Stage | undefined) => {

    if (offer && step && stage) {
        // Construct the expected version string
        const expectedVersion = `${stage.name}-${step.name}`;
        console.log((offer as any).username, expectedVersion, offer.version, offer.version === expectedVersion);
        // Update the state based on whether the offer's version matches the expected version
        return offer.version === expectedVersion
    }
    // If any of the dependencies are undefined, set offerUpToDate to false
    return false;

}

const useOfferUpToDate = (offer: Offer) => {
    const [offerUpToDate, setOfferUpToDate] = useState(false);
    const { data: step } = useCurrentStep();
    const { data: stage } = useCurrentStage();

    useEffect(() => {
        setOfferUpToDate(isOfferUpToDate(offer, step, stage));
    }, [offer, step, stage]); // Dependencies for useEffect

    return offerUpToDate;
};

export default useOfferUpToDate;
