import React from 'react';
import styled from 'styled-components';

import { RelativeSwitch } from 'components/Routing';

import routes from './routes';

const Auth: React.FC = () => {
  return (
    <CenteredDiv>
      <RelativeSwitch routes={routes} />
    </CenteredDiv>
  );
};

const CenteredDiv = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
`;

export default Auth;
