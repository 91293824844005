import React from 'react';
import { useModal } from 'hooks';

import { Button } from 'antd';
import GenericSessionTable from 'components/GenericSessionTable';
import CreateSessionModal from './CreateSessionModal';

import { useKlassSessions } from '../actions';

type Props = {
  klassId: number;
};

const SessionTable: React.FC<Props> = ({ klassId }) => {
  const { data, isLoading } = useKlassSessions(klassId);

  const [Modal, show] = useModal(CreateSessionModal);

  return (
    <GenericSessionTable
      footer={() => (
        <>
          <Button type="primary" onClick={() => show({ klassId })}>
            Create Session
          </Button>
          {Modal}
        </>
      )}
      dataSource={data?.sort((a, b) => b.id - a.id)}
      loading={isLoading}
    />
  );
};

export default SessionTable;
