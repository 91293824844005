import React from 'react';

import Chart from 'react-apexcharts';
import { Typography, InputNumber } from 'antd';

import { Loading } from 'components';

import useParseAttempts from './useParseAttempts';
import {
  DCFFAttempt,
  TradingComparableAttempt,
  TransactionComparableFields,
} from 'types';

const { Text } = Typography;

const FootballField: React.FC<{
  transactionAttempt: TransactionComparableFields;
  tradingAttempt: TradingComparableAttempt;
  dcffAttempt: DCFFAttempt;
}> = ({ transactionAttempt, tradingAttempt, dcffAttempt }) => {
  const { wacc, setWacc, dcff, trading, transactions, wk52 } = useParseAttempts(
    transactionAttempt,
    tradingAttempt,
    dcffAttempt
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2rem',
      }}
    >
      <Text>WACC step from mean</Text>{' '}
      <InputNumber value={wacc} onChange={setWacc} step="0.1" />
      <br />
      <Chart
        type="rangeBar"
        options={{
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        }}
        series={[
          {
            data: [
              {
                x: 'Comparable Transactions',
                y: transactions,
              },
              {
                x: 'Comparable Listed Companies',
                y: trading,
              },
              {
                x: 'Target 52-week range',
                y: wk52,
              },
              {
                x: 'DCFF/100',
                y: dcff.map((d) => (d || 0) / 100),
              },
            ],
          },
        ]}
        width="900"
      />
    </div>
  );
};

export default FootballField;
