import { useMutation, useQueryClient } from 'react-query';
import { createPostRequest } from 'services';
import { useAuthQuery } from 'hooks';

import type { CreateSessionPayload } from 'modules/TrainerDashboard/types';
import type { Klass, Session } from 'types';

export const useKlasses = () => useAuthQuery<Klass[]>(['klasses']);
export const useKlassSessions = (klassId: number) =>
  useAuthQuery<Session[]>(['klasses', klassId, 'sessions']);

const createSession = createPostRequest<CreateSessionPayload, Session>(
  'sessions/create/'
);
export const useCreateSession = (klassId: number) => {
  const queryClient = useQueryClient();
  return useMutation(createSession, {
    onSettled: () =>
      queryClient.invalidateQueries(['klasses', klassId, 'sessions']),
  });
};
