import React, { useEffect, useState } from 'react';
import useGlossary from './useGlossary';
import { Skeleton, Tooltip, Typography } from 'antd';

const { Title } = Typography;

type WrapperProps = {
  level?: number; // If using Ant Design's Typography.Title, for example
  [key: string]: any; // For additional props
};

type GlossaryWrappedTextProps = {
  text: string;
  WrapperComponent?: React.ElementType;
  wrapperProps?: WrapperProps;
};

const GlossaryWrappedText = ({
  text,
  WrapperComponent = 'div', // Default to 'div' if no wrapper is specified
  wrapperProps = {},
}: GlossaryWrappedTextProps) => {
  const { data: glossary, isLoading: glossaryIsLoading } = useGlossary();
  const [processedElements, setProcessedElements] = useState<React.ReactNode[]>(
    []
  );

  useEffect(() => {
    if (glossary && !glossaryIsLoading) {
      // Create an array to hold the JSX elements
      const elements: React.ReactNode[] = [];

      // Keep track of the last index processed
      let lastIndex = 0;

      // Sort glossary by length of title descending to prioritize longer matches
      const sortedGlossary = [...glossary].sort(
        (a, b) => b.title.length - a.title.length
      );

      for (const entry of sortedGlossary) {
        const { title, text: definition } = entry;
        const matchIndex = text.indexOf(title, lastIndex);

        // If the term is found in the text, process it
        if (matchIndex !== -1) {
          // Add the text before the term as a normal text node
          elements.push(text.slice(lastIndex, matchIndex));

          // Create the tooltip element for the term
          const tooltipElement = (
            <Tooltip key={title} title={definition}>
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {title}
              </span>
            </Tooltip>
          );
          elements.push(tooltipElement);

          // Update the last index processed
          lastIndex = matchIndex + title.length;
        }
      }

      // Add any remaining text after the last glossary term
      elements.push(text.slice(lastIndex));

      // Update the state with the new elements
      setProcessedElements(elements);
    }
  }, [glossary, glossaryIsLoading, text]);

  if (glossaryIsLoading) {
    return <Skeleton active />;
  }

  return (
    <WrapperComponent {...wrapperProps}>{processedElements}</WrapperComponent>
  );
};

GlossaryWrappedText.defaultProps = {
  WrapperComponent: React.Fragment,
  wrapperProps: {},
};

export default GlossaryWrappedText;
