import { Table, Typography, Divider } from 'antd';
import { Loading } from 'components';
import React from 'react';
import { useOfferPlayers } from './actions';
import { usePlayersCurrentStep } from 'hooks/trainerActions';
import { Offer, PlayerWithOffer, SellerEliminationOffers } from 'types';
import { BUYER } from 'stateConstants';
import { useCurrentStage, useCurrentStep } from 'hooks';

const { Title } = Typography;

const ReviewStep: React.FC = () => {
  const {
    data: offerPlayers,
    isLoading: isOfferPlayersLoading,
  } = usePlayersCurrentStep();
  const { data: step } = useCurrentStep();
  const { data: stage } = useCurrentStage();
  if (isOfferPlayersLoading || !offerPlayers) {
    return <Loading />;
  }

  const isOfferUpToDate = (offer: Offer) => {
    if (offer && step && stage) {
      const expectedVersion = `${stage.name}-${step.name}`;
      return offer.version === expectedVersion;
    }
    return false;
  };

  const processedOffers = offerPlayers.map((p: PlayerWithOffer) => {
    return {
      ...p,
      ...p.offer,
      username: p.username,
      isUpToDate: isOfferUpToDate(p.offer),
    };
  });

  return (
    <>
      <Title level={4}>
        Buyer teams are currently reviewing and submitting their offers.
      </Title>
      <Title level={4}>
        Seller team is reviewing some of the real-life data that shows this
        Transaction already is a resounding success.
      </Title>
      <Title level={4}>
        Once all Buyers have finalized their Step, you can click Next.
      </Title>
      <Divider />
      <Table
        title={() => 'Teams'}
        pagination={false}
        rowKey="id"
        columns={[
          { title: 'Team', dataIndex: 'username' },
          {
            title: 'Step Finalized',
            dataIndex: 'offer',
            render(value, record) {
              return value && record.isUpToDate ? 'Yes' : 'No';
            },
          },
        ]}
        dataSource={processedOffers.filter(
          (p: PlayerWithOffer) => p.type === BUYER
        )}
      />
      <Table
        title={() => 'Buyer Offers'}
        pagination={false}
        rowKey="username"
        columns={[
          {
            title: 'Team',
            dataIndex: 'username',
            render: (value, record) => (
              <div style={{ color: record.isUpToDate ? 'green' : 'red' }}>
                {value}
              </div>
            ),
          },
          {
            title: 'Cash Amount',
            dataIndex: 'cash_amount',
            render: (value, record) => (
              <div style={{ color: record.isUpToDate ? 'green' : 'red' }}>
                {value}
              </div>
            ),
          },
          {
            title: 'Stock Exchange Rate',
            dataIndex: 'stock_exchange_rate',
            render: (value, record) => (
              <div style={{ color: record.isUpToDate ? 'green' : 'red' }}>
                {value}
              </div>
            ),
          },
          {
            title: 'Cash In Lieu',
            dataIndex: 'cash_in_lieu',
            render: (value, record) => (
              <div style={{ color: record.isUpToDate ? 'green' : 'red' }}>
                {value}
              </div>
            ),
          },
          {
            title: 'Deferred Cash',
            dataIndex: 'deferred_cash',
            render: (value, record) => (
              <div style={{ color: record.isUpToDate ? 'green' : 'red' }}>
                {value}
              </div>
            ),
          },
          {
            title: 'Pension Liabilities',
            dataIndex: 'pension_liabilities',
            render: (value, record) => (
              <div style={{ color: record.isUpToDate ? 'green' : 'red' }}>
                {value}
              </div>
            ),
          },
          {
            title: 'Target Debt',
            dataIndex: 'target_dept',
            render: (value, record) => (
              <div style={{ color: record.isUpToDate ? 'green' : 'red' }}>
                {value}
              </div>
            ),
          },
          {
            title: 'Breakup Fee',
            dataIndex: 'breakup_fee',
            render: (value, record) => (
              <div style={{ color: record.isUpToDate ? 'green' : 'red' }}>
                {value}
              </div>
            ),
          },
        ]}
        dataSource={processedOffers.filter((p: PlayerWithOffer) => p.offer)}
      />
    </>
  );
};

export default ReviewStep;
