import axios from 'axios';

import settings from 'settings';
import AuthHeader from 'services/AuthHeader';
import { useSessionId } from 'hooks';

export const createPostRequest = <RequestPayload, ResponseData>(
  endpoint: string | ((payload: RequestPayload) => string),
  method: 'post' | 'put' | 'patch' = 'post',
  session_id?: number
) => {
  return async (data: RequestPayload) => {
    if (typeof endpoint !== 'string') endpoint = endpoint(data);

    const response = await axios[method](
      `${settings.API_URL}/${endpoint}`,
      session_id !== undefined ? { ...data, session_id } : data,
      {
        headers: AuthHeader(),
      }
    );
    return response.data as ResponseData;
  };
};

export const createDynamicPostRequest = <RequestPayload, ResponseData>(
  getEndpoint: string | ((sessionId: number) => string),
  method: 'post' | 'put' | 'patch' = 'post'
) => {
  return async (data: RequestPayload, sessionId: number) => {
    const endpoint = typeof getEndpoint === 'function' ? getEndpoint(sessionId) : getEndpoint;

    const response = await axios[method](
      `${settings.API_URL}/${endpoint}`,
      data,
      {
        headers: AuthHeader(),
      }
    );
    return response.data as ResponseData;
  };
};


export const createPostDetailRequest = <RequestPayload, ResponseData>(
  view: string,
  action: string
) => {
  return async (id: RequestPayload) => {
    const response = await axios.post(
      `${settings.API_URL}/${view}/${id}/${action}/`,
      {},
      {
        headers: AuthHeader(),
      }
    );
    return response.data as ResponseData;
  };
};

export const createDeleteRequest = <RequestPayload, ResponseData>(
  view: string,
  action: string
) => {
  return async (id: RequestPayload) => {
    const response = await axios.delete(
      `${settings.API_URL}/${view}/${id}/${action}/`,
      {
        headers: AuthHeader(),
      }
    );
    return response.data as ResponseData;
  };
};
