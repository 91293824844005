import React, { useState } from 'react';
import { Typography } from 'antd';
import Counter from 'modules/Counter';

const ReadingsValuationInstructionsStep: React.FC = () => {
  return (
    <>
      <Counter totalTime={480} />
      <Typography.Title level={4}>
        Participants can now  review the documents which have been previously acquired for the valuation of Xilinx. They include Trading and Transaction Comps,
        as well as well as Broker reports and Xilinx Results Press Releases.
      </Typography.Title>
      <Typography.Title level={4}>
        Kindly encourage them to split the burden of reading the documents among several members of the team.
      </Typography.Title>
      <Typography.Title level={2}>
        PLEASE DO NOT REFRESH THE PAGE.
      </Typography.Title>
    </>
  );
};

export default ReadingsValuationInstructionsStep;
