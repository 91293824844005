import React, { useState } from 'react';

import styled from 'styled-components';
import { Row, Col, Card, Statistic, Button, Typography, Divider } from 'antd';
import { SendOutlined, WalletOutlined, TagsOutlined } from '@ant-design/icons';

import { useCurrentStep, useSessionId } from 'hooks';
import {
  useSessionMember,
  useSessionPlayer,
  useSubmitCurrentStep,
} from 'hooks/traineeActions';
import { Loading, Space } from 'components';
import { BUYER, SELLER } from 'stateConstants';
import { BuyerDescription, SellerDescription } from './stepDescriptions';
import DocumentCard from './DocumentCard';
import DisplayAttempt from './DisplayAttempt';
import {
  DocAcquisitionAttemptType,
  useDocAcquisitionAttempt,
  useSubmitDocuments,
} from './actions';
// import { Typography } from 'antd';
import type { DocAcquisitionStepType, Player, ValuationDocument } from 'types';

const DocAcquisitionStep: React.FC<{
  player: Player;
  ownPerspective: boolean;
  attempt: DocAcquisitionAttemptType;
}> = ({ player, ownPerspective, attempt }) => {
  // const { data: player, isLoading: isPlayerLoading } = useSessionPlayer();
  const { data: member, isLoading: isMemberLoading } = useSessionMember();
  const {
    data: currentStep,
    isLoading: isCurrentStepLoading,
  } = useCurrentStep<DocAcquisitionStepType>();
  const { mutate: submitDocuments } = useSubmitCurrentStep();

  const [selectedDocs, setSelectedDocs] = useState<Array<number>>([]);
  const [total, setTotal] = useState(0);

  const docIndex = (id: number) => selectedDocs.findIndex((doc) => doc === id);
  const session_id = useSessionId();

  if (isCurrentStepLoading || isMemberLoading) {
    return <Loading />;
  }

  if (!currentStep) {
    return (
      <Typography.Paragraph>
        There is an issue connecting to the server, please refresh.
      </Typography.Paragraph>
    );
  }

  if (attempt) {
    return <DisplayAttempt player={player} />;
  }

  const toggleDoc = (id: number) => {
    const index = docIndex(id);

    const { price } = currentStep.documents.find(
      (doc) => doc.id === id
    ) as ValuationDocument;

    if (index === -1) {
      setSelectedDocs([...selectedDocs, id]);
      setTotal(total + price);
    } else {
      const newDocs = [...selectedDocs];
      newDocs.splice(index, 1);
      setSelectedDocs(newDocs);
      setTotal(total - price);
    }
  };

  const isSubmitDisabled = () => {
    if (!member) return true;
    if (total > member.coins) {
      return true;
    }

    const unselected = currentStep.documents.filter(
      (doc) => !selectedDocs.includes(doc.id)
    );
    return unselected.reduce(
      (disabled, doc) => disabled || doc.is_mandatory,
      false
    );
  };

  return (
    <div>
      {/* Typography.Title */}
      <Description>
        {player?.type === BUYER && <BuyerDescription />}
        {player?.type === SELLER && <SellerDescription />}
      </Description>
      <Divider />
      <Row gutter={[10, 10]}>
        <Col span={20}>
          <Row gutter={[10, 10]}>
            {currentStep?.documents.map((doc) => {
              return (
                <Col span={6} key={doc.id} onClick={() => toggleDoc(doc.id)}>
                  <DocumentCard doc={doc} selected={docIndex(doc.id) !== -1} />
                </Col>
              );
            })}
          </Row>
        </Col>

        <Col span={4}>
          <BigCard>
            {member && (
              <CustomSpace size={10} direction="vertical">
                <Statistic
                  title="Available coins"
                  value={member.coins}
                  prefix={<WalletOutlined />}
                />
                <Statistic
                  title="Used coins"
                  value={total}
                  prefix={<TagsOutlined />}
                />
                {ownPerspective && (
                  <Button
                    type="primary"
                    disabled={isSubmitDisabled()}
                    onClick={() =>
                      submitDocuments({
                        member_id: member.id,
                        documents: selectedDocs,
                        session_id,
                      })
                    }
                  >
                    <SendOutlined />
                    Submit
                  </Button>
                )}
              </CustomSpace>
            )}
          </BigCard>
        </Col>
      </Row>
    </div>
  );
};

const Description = styled.div`
  padding-bottom: 10px;
`;

const BigCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex: 1;
  }
`;

const CustomSpace = styled(Space)`
  height: 100%;

  .ant-space-item {
    flex: unset !important;
  }

  .ant-space-item:nth-last-child(2) {
    flex: 1 !important;
  }
`;

export default DocAcquisitionStep;
